// import React from 'react'

import { dotmatrix, printList, printPos, wsClient } from "helper/dotmatrix";
// import { getUrl } from "libs/pdf";
// import modalReceipt from 'helper/modalReceipt'
// import {toJpeg} from 'libs/converter'
import { fnumber } from "helper/Helper";
import receiptToArray from "./receiptToArray";
const EscPosEncoder = require("esc-pos-encoder");
const encoder = new EscPosEncoder();
const secureStorage = require("libs/secureStorage");

const printThermal = (payload) => {
  //let receipt = encoder.initialize()
  const user = secureStorage.getItem("account");
  const info = secureStorage.getItem("domainInfo");
  // console.log({ user, info });
  const title = payload.title;
  const body = !Array.isArray(payload.body)
    ? receiptToArray(payload.body)
    : payload.body;
  body.push([`Loket : ${user.name}`]);
  body.push([`Powered by ${info ? info.appName : ""}`]);
  // const spaceLength = spaceNum(payload.body)
  let wholeLine = "";
  for (const item of body) {
    const line = Array.isArray(item)
      ? item.length > 1
        ? item[0].toString().padEnd(15, " ") +
          ": " +
          (typeof item[1] !== "number"
            ? item[0] === "TGL TRANSAKSI"
              ? item[1]
              : item[1]
            : "Rp. " + fnumber(item[1]).padStart(9, " "))
        : item[0]
      : item;
    wholeLine += line + "\n";
    //receipt.line(line)
  }
  // console.log(wholeLine)
  const receipt = encoder
    .initialize()
    .newline("center")
    .bold(true)
    .text(title)
    .bold(false)
    .align("left")
    .newline()
    .newline()
    .line(wholeLine)
    .newline()
    .newline()
    .cut("partial")
    .encode();
  if (navigator.bluetooth) {
    // navigator.bluetooth
    //   .requestDevice({
    //     acceptAllDevices: true,
    //     // optionalServices: ["000018f0-0000-1000-8000-00805f9b34fb"],
    //   })
    //   .then((device) => {
    //     return device.gatt.connect();
    //   })
    //   .then((server) => {
    //     return server.getPrimaryServices();
    //   })
    //   .then((services) => {
    //     if (services.length > 0) {
    //       return services[0].getCharacteristics();
    //     } else throw Error("Service not support");
    //   })
    //   .then((characteristics) => {
    //     const chara = characteristics.find((char) => char.properties.write);
    //     if (!chara) throw Error("Device not support");
    //     return chara;
    //   })
    //   .then(async (chara) => {
    //     const maxSize = 20;
    //     let chunkCount = Math.ceil(receipt.byteLength / maxSize);
    //     let index = 0;
    //     const sendChunk = () => {
    //       if (!chunkCount) {
    //         return;
    //       }
    //       const chunk = receipt.slice(index, index + maxSize);
    //       index += maxSize;
    //       chunkCount--;
    //       chara.writeValue(chunk);
    //       setTimeout(() => {
    //         sendChunk();
    //       }, 200);
    //     };
    //     sendChunk();
    //   })
    //   .catch((err) => {
    //     alert(err.message);
    //     return false;
    //   });
    // BACK
    navigator.bluetooth
      .requestDevice({
        acceptAllDevices: true,
        optionalServices: ["000018f0-0000-1000-8000-00805f9b34fb"],
      })
      .then((device) => {
        return device.gatt.connect();
      })
      .then((server) => {
        return server.getPrimaryService("000018f0-0000-1000-8000-00805f9b34fb");
      })
      .then((service) => {
        return service.getCharacteristic(
          "00002af1-0000-1000-8000-00805f9b34fb"
        );
      })
      .then(async (chara) => {
        const maxSize = 20;
        let chunkCount = Math.ceil(receipt.byteLength / maxSize);
        let index = 0;
        const sendChunk = () => {
          if (!chunkCount) {
            return;
          }
          const chunk = receipt.slice(index, index + maxSize);
          index += maxSize;
          chunkCount--;
          chara.writeValue(chunk);
          setTimeout(() => {
            sendChunk();
          }, 200);
        };
        sendChunk();
      })
      .catch(() => {
        return false;
      });
  } else {
    throw Error("Perangkat ini tidak mendukung bluetooth");
  }
};
const printDotMatrix = (payload, collective) => {
  dotmatrix(payload, collective);
};

// const printDeskjet = (payload)=>{
//   // const title = payload.title
//   // const body = modalReceipt(payload.body)
//   // console.log(payload)
//   // console.log(payload.ref)
//   const w = window.open();
//   // console.log(payload.ref.current.myRef.current.innerHTML)
//   w && w.document.write(payload.ref.current.myRef.current.innerHTML);
//   w && w.document.close()
//   // w && w.focus()
//   w && w.print();
//   setTimeout(()=>{
//     w && w.close();
//   },100)
// }
const printDeskjet = async (payload) => {
  const { getUrl } = require("libs/pdf");
  const url = await getUrl(payload, "PdfReceipt");
  if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1) {
    wsClient.send("deskjet", { url });
  } else {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = url;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }
};
const printDeskjetList = async (payload) => {
  // const {title,body} = payload
  const url = payload.url;
  if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1) {
    wsClient.send("deskjet", { url });
  } else {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = url;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }
};
const printDeskjetCollective = async (payload) => {
  // console.log(payload)
  const { getUrl } = require("libs/pdf");
  const data = payload;
  const url = await getUrl({ data }, "PdfCollective");
  if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1) {
    wsClient.send("deskjet", { url });
  } else {
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    iframe.src = url;
    document.body.appendChild(iframe);
    iframe.contentWindow.print();
  }
};

const print = (payload, collective) => {
  const printer = secureStorage.getItem("printer");
  if (printer === "thermal") printThermal(payload);
  else if (printer === "dotmatrix") printDotMatrix(payload, collective);
  else if (printer === "pos") printPos(payload);
  else {
    if (!collective) printDeskjet(payload);
    else printDeskjetCollective(payload);
  }
};
print.list = (payload) => {
  if (payload.printer === "dotmatrix") printList(payload);
  else printDeskjetList(payload);
};

export default print;
