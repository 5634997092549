import { container, title,
  // whiteColor,
  grayColor,
  // infoColor 
} from "assets/jss/material-kit-pro-react.js";

import imagesStyle from "assets/jss/material-kit-pro-react/imagesStyles.js";

const mainMenuStyle = {
  container : {
    ...container,
    paddingRight: "0px",
     paddingLeft: "0px",
    "@media (min-width: 576px)": {
      maxWidth: "800px"
    },
    "@media (min-width: 768px)": {
      maxWidth: "720px"
    },
    "@media (min-width: 992px)": {
      maxWidth: "960px"
    },
    "@media (min-width: 1200px)": {
      maxWidth: "1140px"
    }
  },
  profile: {
    textAlign: "center",
    "& img": {
      maxWidth: "160px",
      width: "100%",
      margin: "0 auto",
      transform: "translate3d(0, -50%, 0)"
    }
  },
  description: {
    margin: "1.071rem auto 0",
    maxWidth: "600px",
    color: "#999",
    textAlign: "center !important"
  },
  name: {
    marginTop: "-80px"
  },
  ...imagesStyle,
  main: {
    background: grayColor[11],
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    margin: "-50px 10px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    textDecoration: "none"
  },
  navWrapper: {
    margin: "20px auto 50px auto",
    textAlign: "center",
    minHeight: "650px",
  },
  gridWrapper : {
    margin: 0,
    width: '100%'
  }
};

export default mainMenuStyle;