/*eslint-disable*/
import React, { isValidElement } from "react";
// @material-ui/core components

import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Phone from "@material-ui/icons/PhoneAndroid";
import Address from "@material-ui/icons/ContactMail";
import ProvinceIcon from "@material-ui/icons/Map";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import Close from "@material-ui/icons/Close";
import Clearfix from "components/Clearfix/Clearfix.js";

// core components
import Footer from "components/GFooter/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Input from "components/GCustomInput/CustomInput.js";

/**
 * Register style
 */
import styles from "assets/jss/material-kit-pro-react/views/gRegisterStyle.js";

import image from "assets/img/newbg1.jpg";

import { withRouter } from "react-router-dom";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";
import { RepeatOneSharp } from "@material-ui/icons";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Provinces from "helper/Provinces";
/**
 * Alert Snackbar
 */
import SnackbarContent from "components/GSnackbar/SnackbarContent.js";

//libs
import { Redirect } from "react-router-dom";
import axios from "axios";
import Config from "config";
import * as EmailValidator from "email-validator";

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Resource from "libs/resource";
/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: [0],
      fbData: null,
      isFbDisabled: false,

      invitationId: "",
      username: "",
      fullname: "",
      email: "",
      phone: "",
      address: "",
      province: "",
      password: "",
      repassword: "",
      alert: false,
      error: [],
      success: [],
      isInviationValid: null,

      redirect: false,

      showModal: false,
      modalTitle: "",
      modalMessage: "",

      usernameTimeout: "",
      emailTimeout: "",
      phoneTimeout: "",

      usernameErr: "",
      emailErr: "",
      phoneErr: "",

      appName: "",
    };
  }
  componentDidMount() {
    //initFb();

    if (this.props.match.params.invitationId) {
      this.setState({ invitationId: this.props.match.params.invitationId });
      this.isInvitationValid();
    }

    window.scrollTo(0, 0);
    document.body.scrollTop = 0;

    window.location.hostname !== "payfren.id" &&
      Resource.getInfo()
        .then((info) => this.setState({ appName: info.appName }))
        .catch((err) => console.log(err));
  }

  isInvitationValid() {
    // let result=false
    if (!this.state.invitationId) {
      axios
        .post(Config.backendUrl + "user/invitation/isvalid", {
          invitationId: this.props.match.params.invitationId,
        })
        .then((res) => {
          //console.log(res)
          this.setState({ isInviationValid: res.data });
          // result = res.data
        })
        .catch((error) => {
          console.log(error);
        });
    }
    //console.log(result)
    // return result
  }
  register() {
    const payload = {
      username: this.state.username,
      fullname: this.state.fullname,
      phone: this.state.phone,
      email: this.state.email,
      address: this.state.address,
      province: Provinces[this.state.province],
      password: this.state.password,
      invitationId: this.state.invitationId,
    };
    //console.log(payload)
    axios
      .post(Config.backendUrl + "user/register", payload)
      .then((res) => {
        //console.log(res.data)

        this.setState({
          showModal: true,
          modalTitle: "Sukses",
          modalMessage: res.data,
        });
      })
      .catch((err) => {
        if (err.response) {
          this.setState({
            showModal: true,
            modalTitle: "Gagal",
            modalMessage: err.response.data,
          });
        } else {
          this.setState({
            showModal: true,
            modalTitle: "Gagal",
            modalMessage: "Koneksi jaringan terputus",
          });
        }
      });
  }

  //#region Event Handler

  handleRedirect = (e) => {
    if (this.state.modalTitle === "Sukses") this.setState({ redirect: true });
    else this.setState({ showModal: false });
  };
  handleClose = (e) => {
    this.setState({ showModal: false });
  };

  handleToggle = (value) => {
    //console.log(this.state.checked)
    const currentIndex = this.state.checked.indexOf(value);
    const newChecked = [...this.state.checked];
    if (currentIndex === -1) {
      newChecked.push(value);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    this.setState({ checked: newChecked });
  };

  checkAvailability = (params) => {
    const payload = {
      username: params.username && params.username,
      email: params.email && params.email,
      phone: params.phone && params.phone,
    };
    axios
      .post(Config.backendUrl + "user/availability", payload)
      .then((res) => {
        const data = res.data;
        const target = Object.keys(data)[0];
        this.setState({
          [target + "Err"]: "",
          success: { ...this.state.success, [target]: true },
          error: { ...this.state.error, [target]: false },
        });
      })
      .catch((err) => {
        console.log(err);
        if (err.response) {
          const data = err.response.data;
          const target = Object.keys(data)[0];
          this.setState({
            [target + "Err"]: data[target],
            success: { ...this.state.success, [target]: false },
            error: { ...this.state.error, [target]: true },
          });
        }
      });
  };

  handleInput = (e) => {
    //alert(e.id)
    switch (e.target.id) {
      case "username":
        const username = e.target.value;
        this.state.usernameTimeout && clearTimeout(this.state.usernameTimeout);
        this.state.usernameTimeout = setTimeout(() => {
          username && this.checkAvailability({ username });
        }, 2000);
        this.setState({
          username,
          success: { ...this.state.success, [e.target.id]: false },
          error: { ...this.state.error, [e.target.id]: false },
        });
        break;
      case "fullname":
        const fullname = e.target.value;
        this.setState({
          fullname: e.target.value,
          error: {
            ...this.state.error,
            [e.target.id]: fullname ? false : true,
          },
          success: {
            ...this.state.success,
            [e.target.id]: fullname ? true : false,
          },
        });
        break;
      case "phone":
        const phone = e.target.value;
        this.state.phoneTimeout && clearTimeout(this.state.phoneTimeout);
        this.state.phoneTimeout = setTimeout(() => {
          phone && this.checkAvailability({ phone });
        }, 2000);
        this.setState({
          phone: e.target.value,
          success: { ...this.state.success, [e.target.id]: false },
          error: { ...this.state.error, [e.target.id]: false },
        });
        break;
      case "email":
        const email = e.target.value;
        this.state.emailTimeout && clearTimeout(this.state.emailTimeout);
        this.state.emailTimeout = setTimeout(() => {
          email && this.checkAvailability({ email });
        }, 2000);
        this.setState({
          success: { ...this.state.success, [e.target.id]: false },
          error: { ...this.state.error, [e.target.id]: false },
          email: e.target.value,
        });
        break;
      case "address":
        this.setState({ address: e.target.value });
        break;
      case "password":
        this.setState({ password: e.target.value });
        break;
      case "repassword":
        this.setState({ repassword: e.target.value });
        if (e.target.value === this.state.password) {
          this.setState({
            success: { ...this.state.success, [e.target.id]: true },
            error: { ...this.state.error, [e.target.id]: false },
          });
        } else if (e.target.value !== this.state.password)
          this.setState({
            error: { ...this.state.error, [e.target.id]: true },
          });
        else
          this.setState({
            success: { ...this.state.success, [e.target.id]: false },
          });
        break;
      default:
    }
    // if(e.target.value.length<this.state.password.length || e.target.id !== "repassword")
    // 	this.setState({error:{...this.state.error,[e.target.id]: false}})
  };
  handleBlur = (e) => {
    const { id, value } = e.target;
    // console.log(id,value.indexOf(' '))
    if (value) {
      if (
        (id === "username" &&
          (value.indexOf(" ") !== -1 || this.state.usernameErr)) ||
        (id === "email" &&
          (!EmailValidator.validate(e.target.value) || this.state.emailErr)) ||
        (id === "phone" && (isNaN(e.target.value) || this.state.phoneErr))
      ) {
        this.setState({
          error: { ...this.state.error, [e.target.id]: true },
          success: { ...this.state.success, [e.target.id]: false },
        });
      } else {
        this.setState({
          success: { ...this.state.success, [e.target.id]: true },
          error: { ...this.state.error, [e.target.id]: false },
        });
      }
    } else {
      this.setState({
        success: { ...this.state.success, [e.target.id]: false },
        error: { ...this.state.error, [e.target.id]: true },
      });
    }
    //console.log(this.state.error)
  };
  handleSignUp = (e) => {
    //this.setState({showModal:true})
    let error = this.state.error;

    if (!EmailValidator.validate(this.state.email)) error.email = true;

    if (!this.state.repassword) error.repassword = true;
    this.setState({ error: { ...error } });
    //console.log(this.state.error)
    //console.log(this.state.fullname, this.state.phone, this.state.email, this.state.address, this.state.province, this.state.password, this.state.repassword, this.state.checked.length)
    if (
      !error.username &&
      !error.email &&
      !error.phone &&
      !this.state.usernameErr &&
      !this.state.emailErr &&
      !this.state.phoneErr &&
      !error.fullname &&
      !error.address &&
      !error.password &&
      !error.repassword &&
      this.state.province &&
      this.state.checked.length > 1
    ) {
      // console.log('register',this.state.province)
      this.register();
    } else {
      this.setState({ alert: true });
    }
  };

  handleSelect = (event) => {
    this.setState({ province: event.target.value });
  };
  handleAlertExit = () => {
    this.setState({ alert: false });
  };
  //#endregion

  //#region facebook API
  responseFacebook = (response) => {
    secureStorage.setItem("fbData", response);
    this.setState({ fbData: response });
    this.setState({
      fullname: response.name,
      email: response.email,
    });
  };
  initFb = () => {
    const script = document.createElement("script");
    script.src = "https://connect.facebook.net/en_US/sdk.js";
    script.async = true;
    document.body.appendChild(script);
    window.fbAsyncInit = () => {
      FB.init({
        appId: "990345451382575",
        autoLogAppEvents: true,
        xfbml: true,
        version: "v7.0",
      });
    };
  };
  loginFb = () => {
    /*FB.logout((response)=>{
			alert("logged out");
		});*/
    FB.login(
      (response) => {
        if (response.authResponse) {
          FB.api("/me", "GET", { fields: "id,name,email" }, function(response) {
            this.setState({ fbData: response });
            alert("test");
            //setLog('Good to see you, ' + JSON.stringify(response) + '.');
            this.setState({ isFbDisabled: true });
          });
        } else {
          this.setState({
            log: "User cancelled login or did not fully authorize.",
          });
        }
      },
      { scope: "email" }
    );
  };
  deleteFb = () => {
    /*FB.getLoginStatus((response)=>{
			setLog2(JSON.stringify(response))
		})*/
    /*FB.logout((response)=>{
			setLog2('logged out '+JSON.stringify(response))
		})*/
    /*FB.api('/'+fbData.id+'/permission', function(response) {
			setLog2('Logged out, ' + JSON.stringify(response) + '.');
		});*/
    FB.api("/me/permissions", "DELETE", function(res) {
      if (res) {
        //setLog2('app deauthorized '+JSON.stringify(res));
      } else if (res.error) {
        //setLog2(res.error.type + ': ' + res.error.message);
      } else {
        //setLog2(JSON.stringify(res));
      }
    });
  };
  //#endregion

  render() {
    if (this.state.isInviationValid === false || this.state.redirect) {
      return <Redirect to="/" />;
    }
    const { classes } = this.props;
    const hostname = window.location.hostname;

    return (
      <div>
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={10} md={10}>
                <Card className={classes.cardSignup}>
                  <h2 className={classes.cardTitle}>
                    Registrasi{" "}
                    {hostname !== "payfren.id" ? this.state.appName : "PayFren"}
                  </h2>
                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={12}>
                        {/**
                         * Registration Form
                         */}
                        <form className={classes.form}>
                          <Input
                            error={
                              this.state.error
                                ? this.state.error.username
                                : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.username
                                : false
                            }
                            id="username"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <AccountCircleIcon
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: "contoh : johnsnow ",
                              value: this.state.username,
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="Username"
                          />
                          <div style={{ color: "red" }}>
                            {this.state.usernameErr}
                          </div>
                          <Input
                            error={
                              this.state.error ? this.state.error.email : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.email
                                : false
                            }
                            id="email"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <Email
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: "contoh : john@payfren.id ",
                              value: this.state.email,
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="Email"
                          />
                          <div style={{ color: "red" }}>
                            {this.state.emailErr}
                          </div>
                          <Input
                            error={
                              this.state.error ? this.state.error.phone : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.phone
                                : false
                            }
                            id="phone"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <Phone
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: "contoh : 081123321456",
                              value: this.state.phone,
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="No Handphone"
                          />
                          <div style={{ color: "red" }}>
                            {this.state.phoneErr}
                          </div>
                          <Input
                            error={
                              this.state.error
                                ? this.state.error.fullname
                                : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.fullname
                                : false
                            }
                            id="fullname"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <Face
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder: "contoh : Eren Jaeger",
                              value: this.state.fullname,
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="Nama lengkap"
                          />
                          <Input
                            error={
                              this.state.error
                                ? this.state.error.address
                                : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.address
                                : false
                            }
                            id="address"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <Address
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              ),
                              placeholder:
                                "contoh : Jl. Jenderal Sudirman No. 99 Jagakarsa",
                              value: this.state.address,
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="Alamat lengkap"
                          />
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="province-select"
                              className={classes.selectLabel}
                            >
                              Provinsi
                            </InputLabel>
                            <Select
                              endAdornment={
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <ProvinceIcon
                                    className={classes.inputAdornmentIcon}
                                  />
                                </InputAdornment>
                              }
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.province}
                              onChange={this.handleSelect}
                              inputProps={{
                                name: "province",
                                id: "province",
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                                value={-1}
                              >
                                Provinsi
                              </MenuItem>
                              {Provinces.map((prov, index) => (
                                <MenuItem
                                  key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={index}
                                >
                                  {prov.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                          <Input
                            error={
                              this.state.error
                                ? this.state.error.password
                                : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.password
                                : false
                            }
                            id="password"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                  </Icon>
                                </InputAdornment>
                              ),
                              placeholder: "Password...",
                              type: "password",
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="Password"
                          />
                          <Input
                            error={
                              this.state.error
                                ? this.state.error.repassword
                                : false
                            }
                            success={
                              this.state.success
                                ? this.state.success.repassword
                                : false
                            }
                            id="repassword"
                            formControlProps={{
                              fullWidth: true,
                              className: classes.customFormControlClasses,
                            }}
                            inputProps={{
                              endAdornment: (
                                <InputAdornment
                                  position="end"
                                  className={classes.inputAdornment}
                                >
                                  <Icon className={classes.inputAdornmentIcon}>
                                    lock_outline
                                  </Icon>
                                </InputAdornment>
                              ),
                              placeholder: "Re-type Password...",
                              type: "password",
                              onChange: this.handleInput,
                              onBlur: this.handleBlur,
                            }}
                            labelText="Re-Password"
                          />
                          <FormControlLabel
                            classes={{
                              label: classes.label,
                            }}
                            control={
                              <Checkbox
                                tabIndex={-1}
                                onClick={() => this.handleToggle(1)}
                                checkedIcon={
                                  <Check className={classes.checkedIcon} />
                                }
                                icon={
                                  <Check className={classes.uncheckedIcon} />
                                }
                                classes={{
                                  checked: classes.checked,
                                  root: classes.checkRoot,
                                }}
                                checked={
                                  this.state.checked.indexOf(1) !== -1
                                    ? true
                                    : false
                                }
                              />
                            }
                            label={
                              <span>
                                Saya setuju dengan{" "}
                                <a href="#term">syarat dan ketentuan</a>.
                              </span>
                            }
                          />
                          {this.state.alert ? (
                            <SnackbarContent
                              message={<span>Semua kolom harus diisi</span>}
                              onExited={this.handleAlertExit}
                              close
                              color="danger"
                              icon="info_outline"
                            />
                          ) : null}
                          <div>
                            <div className={classes.textCenter}>
                              <Button
                                round
                                color="instagram"
                                onClick={this.handleSignUp}
                                disabled={
                                  !this.state.error.username &&
                                  !this.state.error.phone &&
                                  !this.state.error.email &&
                                  !this.state.usernameErr &&
                                  !this.state.emailErr &&
                                  !this.state.phoneErr &&
                                  !this.state.error.fullname &&
                                  !this.state.error.address &&
                                  !this.state.error.password &&
                                  !this.state.error.repassword &&
                                  this.state.province &&
                                  this.state.checked.length > 1
                                    ? false
                                    : true
                                }
                              >
                                Daftar
                              </Button>
                            </div>
                            <Dialog
                              classes={{
                                root: classes.modalRoot,
                                paper: classes.modal,
                              }}
                              open={this.state.showModal}
                              TransitionComponent={Transition}
                              keepMounted
                              //onClose={() => this.setState({showModal: false})}
                              aria-labelledby="classic-modal-slide-title"
                              aria-describedby="classic-modal-slide-description"
                            >
                              <DialogTitle
                                id="classic-modal-slide-title"
                                disableTypography
                                className={classes.modalHeader}
                              >
                                <h4 className={classes.modalTitle}>
                                  {this.state.modalTitle}
                                </h4>
                              </DialogTitle>
                              <DialogContent
                                id="classic-modal-slide-description"
                                className={classes.modalBody}
                              >
                                <p>{this.state.modalMessage}</p>
                              </DialogContent>
                              <DialogActions className={classes.modalFooter}>
                                <Button
                                  round
                                  onClick={this.handleRedirect}
                                  color="success"
                                >
                                  OK
                                </Button>
                              </DialogActions>
                            </Dialog>
                          </div>
                        </form>
                      </GridItem>
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}
Register.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(styles)(Register));
