import { container, title,grayColor } from "assets/jss/material-kit-pro-react.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/gCustomSelectStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/gModalStyle.js";

const landingPageStyle = theme =>({
  ...modalStyle(theme),
  ...customSelectStyle,
  container: {
    zIndex: "12",
    color: "#FFFFFF",
    ...container
  },
  title: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "30px",
    minHeight: "32px",
    color: "#FFFFFF",
    textDecoration: "none"
  },
  subtitle: {
    fontSize: "1.313rem",
    maxWidth: "500px",
    margin: "10px auto 0"
  },
  main: {
    background: grayColor[11],
    position: "relative",
    zIndex: "3"
  },
  mainRaised: {
    minHeight:"800px",
    margin: "-50px 10px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)"
  },
  section: {
    //padding: "70px 0",
    textAlign: "center"
  },
  description: {
    color: "#999"
  },
  backButton :{
    margin:"20px auto auto 10px"
  },
  grid : {
    marginTop : "20px"
  },
  textCenter: {
    textAlign: "center"
  },
  textRight: {
    textAlign: "right"
  },
  floatRight: {
    float: "right"
  },
});


export default landingPageStyle;
