import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// @material-ui/icons

// core components
import Button from "components/CustomButtons/Button.js";

import checkboxStyles from "assets/jss/material-kit-pro-react/gCustomCheckboxRadioSwitchStyle.js";
import settingsStyles from "assets/jss/material-kit-pro-react/views/gSettingsStyle.js";

// @material-ui/icons
import SettingsIcon from "@material-ui/icons/Settings";
import GetAppIcon from "@material-ui/icons/GetApp";
// core components
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

import Slide from "@material-ui/core/Slide";

import Accordion from "components/GAccordion/Accordion.js";
import Clearfix from "components/Clearfix/Clearfix";

import secureStorage from "libs/secureStorage";
import Printer from "./Printer";
import ChangePassword from "./ChangePassword";
import InvitationLink from "./InvitationLink";
import Pin from "./Pin";
import AdminNominal from "./AdminNominal";
// import AuthenticationCode from "./AuthenticationCode";

import axios from "axios";
import Config from "config";
import Resource from "libs/resource";

import FileDownload from "js-file-download";

const styles = {
  ...settingsStyles,
  ...checkboxStyles,
};

class Settings extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeAccordion: 0,
      checked: false,
      direction: "right",
      expanded: "panel1",
      settingsMenu: [],
      downloadLink: "",
    };
  }

  componentDidMount() {
    Resource.getInfo()
      .then((info) => this.setState({ info }))
      .catch((err) => console.log(err));
    // this.loadDownloadLink()
    this.setState({ checked: true });
    const user = secureStorage.getItem("account");
    if (
      ![1, 2, 3, 6, 9, 10, 14, 15, 16, 17, 19, 20, 21, 22, 26, 27, 28].includes(
        user.userTypeId
      )
    )
      this.setState({ activeAccordion: 1 });
    if (secureStorage.getItem("settings")) {
      this.setState({ activeAccordion: 1 });
      secureStorage.removeItem("settings");
    }
  }

  initSettingsMenu(settingsMenu) {
    let user = secureStorage.getItem("account");
    let settings = settingsMenu.map((row, index) => {
      if (index === 0 && user.UserType.name === "SALESDM") {
        return row;
      } else {
        return row;
      }
    });
    return settings;
  }
  handleBack = () => {
    this.setState({ checked: false, direction: "right" });
  };
  handleExited = () => {
    this.props.history.push("/MainMenu"); //this.props.onChangePage("MainMenu");
  };

  handleChangeExpansion = (e) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? e : false });
  };
  render() {
    const { classes } = this.props;
    const user = secureStorage.getItem("account");

    return (
      <Slide
        direction={this.state.direction}
        in={this.state.checked}
        mountOnEnter
        unmountOnExit
        onExited={this.handleExited}
      >
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.titleContainer}>
            <Button
              color="google"
              round
              className={classes.backButton}
              onClick={this.handleBack}
            >
              <ArrowBackIos /> Back
            </Button>
            <div className={classes.pageTitle}>
              Settings <SettingsIcon />
            </div>
            <Clearfix />
          </div>
          <div className={classes.container}>
            <Accordion
              activeColor="behance"
              active={this.state.activeAccordion}
              collapses={[
                [
                  1,
                  2,
                  3,
                  6,
                  9,
                  10,
                  14,
                  15,
                  16,
                  17,
                  19,
                  20,
                  21,
                  22,
                  26,
                  27,
                  28,
                ].includes(user.userTypeId)
                  ? {
                      title: "Invitation",
                      content: <InvitationLink />,
                    }
                  : null,
                {
                  title: "PIN",
                  content: <Pin />,
                },
                {
                  title: "Ganti Password",
                  content: <ChangePassword />,
                },
                // {
                // 	title: "Kode Autentikasi",
                // 	content:
                // 		<AuthenticationCode />
                // },
                [4, 7, 10, 11, 18, 23, 29].includes(user.userTypeId)
                  ? {
                      title: "Admin Nominal",
                      content: <AdminNominal />,
                    }
                  : null,
                {
                  title: "Printer",
                  content: <Printer />,
                },
                {
                  title: "Download For Windows",
                  content: (
                    // <a href={this.state.downloadLink} target="_blank" rel="noopener noreferrer"><Button round color="linkedin"
                    // ><GetAppIcon />{this.state.downloadLink && this.state.downloadLink.split('.')[0]}</Button></a>
                    <Button
                      round
                      color="linkedin"
                      onClick={this.loadDownloadLink}
                    >
                      <GetAppIcon />
                      Download {this.state.info && this.state.info.appName}
                      -win-ia32
                    </Button>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </Slide>
    );
  }
  loadDownloadLink = (e) => {
    axios
      .get(`${Config.Resource}download`, { responseType: "blob" })
      .then((res) => {
        // const url = window.URL.createObjectURL(new Blob([res.data]))
        // const link = document.createElement('a')
        // link.href = url

        // link.setAttribute('download',`${this.state.info.appName.toLowerCase()}-win-ia32.rar`)
        // document.body.appendChild(link)
        // link.click()
        FileDownload(
          res.data,
          `${this.state.info.appName.toLowerCase()}-win-ia32.rar`
        );
        // console.log(res.data)
        // this.setState({downloadLink:res.data})
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Settings);
