import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
// @material-ui/icons
import Clear from "@material-ui/icons/Clear";
import Check from "@material-ui/icons/Check";
// core components

import styles from "assets/jss/material-kit-pro-react/components/customInputStyle.js";

import Datetime from "react-datetime";

const style = {
  ...styles,
  container: {
    marginTop: "-24px",
  },
};

const useStyles = makeStyles(style);

export default function GDatePicker(props) {
  const {
    // id,
    formControlProps,
    labelText,
    labelProps,
    inputProps,
    dateTimeProps,
    defaultValue,
    error,
    white,
    inputRootCustomClasses,
    success,
  } = props;
  const classes = useStyles();
  const labelClasses = classNames({
    [" " + classes.labelRootError]: error,
    [" " + classes.labelRootSuccess]: success && !error,
  });
  const underlineClasses = classNames({
    [classes.underlineError]: error,
    [classes.underlineSuccess]: success && !error,
    [classes.underline]: true,
    [classes.whiteUnderline]: white,
  });
  const marginTop = classNames({
    [inputRootCustomClasses]: inputRootCustomClasses !== undefined,
  });
  const inputClasses = classNames({
    [classes.input]: true,
    [classes.whiteInput]: white,
  });
  var formControlClasses;
  if (formControlProps !== undefined) {
    formControlClasses = classNames(
      formControlProps.className,
      classes.formControl
    );
  } else {
    formControlClasses = classes.formControl;
  }
  // let newInputProps = {
  //   maxLength: inputProps ? inputProps.maxLength:undefined,
  //   minLength: inputProps ? inputProps.minLength:undefined
  // };
  return (
    <div className={classes.container}>
      <FormControl
        {...formControlProps}
        className={formControlClasses}
        fullWidth
      >
        {labelText !== undefined ? (
          <InputLabel
            className={classes.labelRoot + " " + labelClasses}
            {...labelProps}
          >
            <span style={{ color: "#000" }}>{labelText}</span>
          </InputLabel>
        ) : null}
        <br />
        <Datetime
          classes={{
            input: inputClasses,
            root: marginTop,
            disabled: classes.disabled,
            underline: underlineClasses,
          }}
          dateFormat={"YYYY"}
          {...dateTimeProps}
          defaultValue={defaultValue}
          inputProps={{ ...inputProps }}
        />
        {error ? (
          <Clear className={classes.feedback + " " + classes.labelRootError} />
        ) : success ? (
          <Check
            className={classes.feedback + " " + classes.labelRootSuccess}
          />
        ) : null}
      </FormControl>
    </div>
  );
}

GDatePicker.propTypes = {
  id: PropTypes.string,
  labelText: PropTypes.node,
  labelProps: PropTypes.object,
  inputProps: PropTypes.object,
  dateTimeProps: PropTypes.object,
  formControlProps: PropTypes.object,
  inputRootCustomClasses: PropTypes.string,
  error: PropTypes.bool,
  success: PropTypes.bool,
  white: PropTypes.bool,
  defaultValue: PropTypes.object,
};
