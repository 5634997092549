import React from "react";

// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// @material-ui/icons

// core components

import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/gTransactionStyle.js";

// @material-ui/icons
import Print from "@material-ui/icons/Print";
import Share from "@material-ui/icons/Share";
// core components
import Pay from "@material-ui/icons/Payment";

import Input from "components/GCustomInput/CustomInput.js";

// Modal
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import { fnumber } from "helper/Helper";

// Custom select
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import axios from "axios";
import Config from "config";

import modalReceipt from "helper/modalReceipt";
import print from "helper/printer";

import share from "libs/share";
//import { savePdf} from "libs/pdf"

import moment from "moment";

import { Close, CloudDownload as Download } from "@material-ui/icons";

/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class Denom extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      confirmationModal: false,

      filename: "",

      billers: [],
      selectedBiller: "",

      noHp: "",
      customerCode: "",

      selectedProduct: "",
      products: [],

      price: "",

      payload: "",

      statusPrint: false,
    };
  }

  componentDidMount() {
    this.setState({ checked: true });
    //this.loadProduct()
    this.loadBiller();
  }

  loadBiller = () => {
    axios
      .post(Config.backendUrl + "product/item/biller", {
        productCategoryId: 10,
      })
      .then((res) => {
        //console.log(res.data)
        this.setState({ billers: res.data });
      })
      .catch((err) => {});
  };

  handleDropdown = (e) => {
    //this.setState({log:e});
  };

  handleInquiry = (target) => {
    if (target === "pulsa") {
      if (this.state.noHp !== "" && this.state.selectedProduct !== "") {
        const payload = {
          customerCode: this.state.noHp,
          price: this.state.price,
          productId: this.state.selectedProduct.id, //prepaid
        };

        this.setState({
          buttonDisabled: true,
          payload,
          confirmationModal: true,
          isInquiry: true,
          modalTitle: "PEMBELIAN E-WALLET",
          modalBody: [
            ["NO HP", payload.customerCode],
            ["ITEM", this.state.selectedProduct.name],
            ["DENOM", this.state.selectedProduct.denom],
            ["RP BAYAR", this.state.price],
          ],
        });
        //this.setState({confirmationModal:true})
        //console.log(payload)
        // this.request(payload,'purchase')
      }
    }
  };

  setModal(data, isError) {
    if (!isError) {
      this.setState({
        statusPrint: data.statusId === 4 ? true : false,
        modalTitle: data.receiptTitle,
        modalBody: data.receiptBody,
        confirmationModal: true,
        filename: `${data.productName}_${data.customerCode}_${moment(
          data.receiptBody.date.value
        ).format("YYMMDDHHmm")}.pdf`,
      });
    } else {
      this.setState({
        statusPrint: data.statusId === 4 ? true : false,
        modalTitle: data.receiptTitle,
        modalBody: data.receiptBody,
        confirmationModal: true,
      });
    }
  }

  handleModal = (e) => {
    if (e === "cancel" || e === "close" || e === "error") {
      this.setState({
        statusPrint: false,
        confirmationModal: false,
        isRequestError: false,
        isInquiry: false,
        selectedBiller: "",
        noHp: "",
        selectedProduct: "",
        price: "",
        payload: "",
        buttonDisabled: false,
      });
    } else if (e === "print") {
      const title = this.state.modalTitle;
      const body = this.state.modalBody;
      const filename = this.state.filename;
      print({
        title,
        body,
        filename,
        ...this.state.products[this.state.productDropdown],
      });
      this.setState({
        statusPrint: false,
        confirmationModal: false,
        isRequestError: false,
        isInquiry: false,
        selectedBiller: "",
        noHp: "",
        selectedProduct: "",
        price: "",
        payload: "",
        buttonDisabled: false,
      });
    } else {
      this.request(this.state.payload, "purchase");
    }
  };

  request(payload, urlQuery) {
    this.setState({ confirmationModal: false });
    axios
      .post(Config.backendUrl + "request/" + urlQuery, payload)
      .then((res) => {
        if (res.data) {
          //console.log(res.data)
          this.setModal(res.data, false);
          if (urlQuery === "purchase") {
            this.setState({
              isInquiry: false,
              selectedBiller: "",
              noHp: "",
              selectedProduct: "",
              price: "",
              payload: "",
              buttonDisabled: false,
            });
          }
        }
        //alert('test1')
      })
      .catch((err) => {
        this.setState({ isRequestError: true });
        console.log(err.response);
        if (err.response) {
          if (!err.response.data.receiptTitle)
            this.setModal(
              {
                receiptTitle: err.response.statusText,
                receiptBody: [err.response.data],
              },
              true
            );
          else this.setModal(err.response.data, true);
        } else
          this.setModal(
            { receiptTitle: "Error", receiptBody: ["Network Error"] },
            true
          );
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={6} className={classes.grid}>
            <Input
              labelText="NO PELANGGAN"
              id="noHpPre"
              inputProps={{
                placeholder: "Masukkan nomor HP",
                value: this.state.noHp,
                onChange: (e) => {
                  this.setState({ noHp: e.target.value });
                },
                type: "tel",
              }}
              formControlProps={{
                fullWidth: true,
              }}
            />
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6} className={classes.grid}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="biller-select"
                  className={classes.selectLabel}
                >
                  ITEM
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.selectedBiller}
                  onChange={(e) =>
                    this.setState({
                      selectedBiller: e.target.value,
                      selectedProduct: "",
                    })
                  }
                  inputProps={{
                    name: "billerPre",
                    id: "billerPre",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value={""}
                  >
                    {this.state.billers.length > 0 ? (
                      "Operator"
                    ) : (
                      <CircularProgress size={30} />
                    )}
                  </MenuItem>
                  {this.state.billers.map((biller, index) => (
                    <MenuItem
                      key={index}
                      classes={{
                        root: classes.selectMenuItem,
                        selected: classes.selectMenuItemSelected,
                      }}
                      value={biller}
                    >
                      {biller.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
        </div>
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={6} className={classes.grid}>
              <FormControl fullWidth className={classes.selectFormControl}>
                <InputLabel
                  htmlFor="product-select"
                  className={classes.selectLabel}
                >
                  Denom
                </InputLabel>
                <Select
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  classes={{
                    select: classes.select,
                  }}
                  value={this.state.selectedProduct}
                  onChange={(e) => {
                    this.setState({ selectedProduct: e.target.value });
                    axios
                      .post(Config.backendUrl + "product/item/price", {
                        productId: e.target.value.id,
                      })
                      .then((res) => {
                        //console.log(res.data)
                        this.setState({ price: res.data.price });
                      })
                      .catch((err) => {});
                  }}
                  inputProps={{
                    name: "productPre",
                    id: "productPre",
                  }}
                >
                  <MenuItem
                    disabled
                    classes={{
                      root: classes.selectMenuItem,
                    }}
                    value={""}
                  >
                    Denom
                  </MenuItem>
                  {this.state.selectedBiller &&
                    this.state.selectedBiller.Products.map((product, index) => (
                      <MenuItem
                        key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={product}
                      >
                        {fnumber(product.denom)}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4} className={classes.grid}>
              {!this.state.buttonDisabled ? (
                <Button
                  id="pulsaPre"
                  color="instagram"
                  round
                  onClick={() => this.handleInquiry("pulsa")}
                >
                  <Pay />
                  Submit
                </Button>
              ) : (
                <CircularProgress color="secondary" />
              )}
            </GridItem>
          </GridContainer>
        </div>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal,
          }}
          open={this.state.confirmationModal}
          TransitionComponent={Transition}
          keepMounted
          //onClose={() => this.setState({successModal: false})}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            {this.state.modalBody && modalReceipt(this.state.modalBody)}
          </DialogContent>
          {this.state.isRequestError ? (
            <DialogActions className={classes.modalFooter}>
              <Button
                round
                onClick={() => this.handleModal("error")}
                color="danger"
              >
                Ok
              </Button>
            </DialogActions>
          ) : this.state.isInquiry ? (
            <DialogActions className={classes.modalFooter}>
              <Button
                round
                onClick={() => this.handleModal("cancel")}
                color="danger"
              >
                Cancel
              </Button>
              <Button
                round
                onClick={() => this.handleModal("confirm")}
                color="instagram"
              >
                Confirm
              </Button>
            </DialogActions>
          ) : (
            <DialogActions className={classes.modalFooter}>
              <Button
                round
                onClick={() => this.handleModal("close")}
                color="danger"
              >
                <Close />
                Close
              </Button>
              {this.state.statusPrint && (
                <Button
                  round
                  onClick={() => this.handleModal("print")}
                  color="instagram"
                >
                  <Print />
                  Print
                </Button>
              )}
              {this.state.modalBody && this.state.statusPrint && (
                <Button
                  round
                  justIcon
                  onClick={() => {
                    const { savePdf } = require("libs/pdf");
                    savePdf(
                      {
                        title: this.state.modalTitle,
                        body: this.state.modalBody,
                        filename: this.state.filename,
                      },
                      "PdfReceipt"
                    );
                  }}
                  color="success"
                >
                  <Download />
                </Button>
              )}
              {navigator.canShare &&
                this.state.modalBody &&
                this.state.statusPrint && (
                  <Button
                    round
                    justIcon
                    onClick={async () =>
                      share({
                        title: this.state.modalTitle,
                        body: this.state.modalBody,
                        filename: this.state.filename,
                      })
                    }
                    color="info"
                  >
                    <Share />
                  </Button>
                )}
            </DialogActions>
          )}
        </Dialog>
      </div>
    );
  }
}
Denom.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Denom);
