// import { getBlob } from './pdf';

const share = async (data) => {
  const { getBlob } = require("./pdf");
  const blob = await getBlob(data, "PdfReceipt");
  const blobs = new Blob([blob], { type: "application/pdf" });
  const pdf = new File([blobs], data.filename, { type: "application/pdf" });
  const files = [pdf];
  // Share PDF file if supported.
  if (navigator.canShare({ files })) await navigator.share({ files });
};

export default share;
