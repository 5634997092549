import React from "react";
import ReactPdf from "@react-pdf/renderer";
import PdfPrintList from "helper/PdfPrintList";
import PdfReceipt from "helper/PdfReceipt";
import PdfCollective from "helper/PdfCollective";
// import Resource from 'libs/resource'

const components = {
  PdfPrintList,
  PdfReceipt,
  PdfCollective,
};
// const ReactPdf = require('@react-pdf/renderer')
//   const PdfPrintList = require('helper/PdfPrintList')
//   const PdfReceipt = require('helper/PdfReceipt')
//   const components = {
//     PdfPrintList,
//     PdfReceipt
//   }
const savePdf = async (data, component) => {
  if (data) {
    // Render the PDF here
    const Component = components[component];
    const blob = await ReactPdf.pdf(<Component data={data} />).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", data.filename);

    document.body.appendChild(link);
    link.click();

    link.parentNode.removeChild(link);
  }
};
const getUrl = async (data, component) => {
  if (data) {
    const Component = components[component];
    const blob = await ReactPdf.pdf(<Component data={data} />).toBlob();
    const url = URL.createObjectURL(blob);
    return url;
  }
};
const getBlob = async (data, component) => {
  if (data) {
    const Component = components[component];
    return await ReactPdf.pdf(<Component data={data} />).toBlob();
  }
};
export { savePdf, getUrl, getBlob };
