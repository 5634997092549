import {
  primaryColor,
  secondaryColor,
  warningColor,
  dangerColor,
  successColor,
  infoColor,
  roseColor,
  grayColor,
  blackColor,
  whiteColor,
  twitterColor,
  facebookColor,
  googleColor,
  linkedinColor,
  pinterestColor,
  youtubeColor,
  tumblrColor,
  behanceColor,
  dribbbleColor,
  redditColor,
  instagramColor,
} from "assets/jss/material-kit-pro-react.js";

const accordionStyle = theme => ({
  root: {
    flexGrow: 1,
    marginBottom: "20px",
    marginTop:"20px"
  },
  expansionPanel: {
    boxShadow: "none",
    "&:before": {
      display: "none !important"
    },
    backgroundColor: "transparent",
  },
  expansionPanelExpanded: {
    margin: "0 !important"
  },
  expansionPanelSummary: {
    margin:"auto 10px 10px 10px",
    minHeight: "auto !important",
    backgroundColor: "transparent",
    borderBottom: "1px solid " + grayColor[10],
    padding: "25px 10px 5px 0px",
    borderTopLeftRadius: "3px",
    borderTopRightRadius: "3px",
    color: grayColor[8]
  },
  primaryExpansionPanelSummary: {
    "&:hover": {
      color: primaryColor[0]
    }
  },
  secondaryExpansionPanelSummary: {
    "&:hover": {
      color: secondaryColor[0]
    }
  },
  warningExpansionPanelSummary: {
    "&:hover": {
      color: warningColor[0]
    }
  },
  dangerExpansionPanelSummary: {
    "&:hover": {
      color: dangerColor[0]
    }
  },
  successExpansionPanelSummary: {
    "&:hover": {
      color: successColor[0]
    }
  },
  infoExpansionPanelSummary: {
    "&:hover": {
      color: infoColor[0]
    }
  },
  roseExpansionPanelSummary: {
    "&:hover": {
      color: roseColor[0]
    }
  },
  blackExpansionPanelSummary: {
    "&:hover": {
      color: blackColor
    }
  },
  whiteExpansionPanelSummary: {
    "&:hover": {
      color: whiteColor
    }
  },
  twitterExpansionPanelSummary: {
    "&:hover": {
      color: twitterColor
    }
  },
  facebookExpansionPanelSummary: {
    "&:hover": {
      color: facebookColor
    }
  },
  googleExpansionPanelSummary: {
    "&:hover": {
      color: googleColor
    }
  },
  linkedinExpansionPanelSummary: {
    "&:hover": {
      color: linkedinColor
    }
  },
  pinterestExpansionPanelSummary: {
    "&:hover": {
      color: pinterestColor
    }
  },
  youtubeExpansionPanelSummary: {
    "&:hover": {
      color: youtubeColor
    }
  },
  tumblrExpansionPanelSummary: {
    "&:hover": {
      color: tumblrColor
    }
  },
  behanceExpansionPanelSummary: {
    "&:hover": {
      color: behanceColor
    }
  },
  dribbbleExpansionPanelSummary: {
    "&:hover": {
      color: dribbbleColor
    }
  },
  redditExpansionPanelSummary: {
    "&:hover": {
      color: redditColor
    }
  },
  instagramExpansionPanelSummary: {
    "&:hover": {
      color: instagramColor
    }
  },
  expansionPanelSummaryExpaned: {
    "& $expansionPanelSummaryExpandIcon": {
      [theme.breakpoints.up("md")]: {
        top: "auto !important"
      },
      transform: "rotate(180deg)",
      [theme.breakpoints.down("sm")]: {
        top: "10px !important"
      },
      // some jss/css to make the cards look a bit better on Internet Explorer
      "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
        display: "inline-block !important",
        top: "10px !important"
      }
    }
  },
  primaryExpansionPanelSummaryExpaned: {
    color: primaryColor[0]
  },
  secondaryExpansionPanelSummaryExpaned: {
    color: secondaryColor[0]
  },
  warningExpansionPanelSummaryExpaned: {
    color: warningColor[0]
  },
  dangerExpansionPanelSummaryExpaned: {
    color: dangerColor[0]
  },
  successExpansionPanelSummaryExpaned: {
    color: successColor[0]
  },
  infoExpansionPanelSummaryExpaned: {
    color: infoColor[0]
  },
  roseExpansionPanelSummaryExpaned: {
    color: roseColor[0]
  },
  grayExpansionPanelSummaryExpaned: {
    color: grayColor[0]
  },
  blackExpansionPanelSummaryExpaned: {
    color: blackColor
  },
  whiteExpansionPanelSummaryExpaned: {
    color: whiteColor
  },
  twitterExpansionPanelSummaryExpaned: {
    color: twitterColor
  },
  facebookExpansionPanelSummaryExpaned: {
    color: facebookColor
  },
  googleExpansionPanelSummaryExpaned: {
    color: googleColor
  },
  linkedinExpansionPanelSummaryExpaned: {
    color: linkedinColor
  },
  pinterestExpansionPanelSummaryExpaned: {
    color: pinterestColor
  },
  youtubeExpansionPanelSummaryExpaned: {
    color: youtubeColor
  },
  tumblrExpansionPanelSummaryExpaned: {
    color: tumblrColor
  },
  behanceExpansionPanelSummaryExpaned: {
    color: behanceColor
  },
  dribbbleExpansionPanelSummaryExpaned: {
    color: dribbbleColor
  },
  redditExpansionPanelSummaryExpaned: {
    color: redditColor
  },
  instagramExpansionPanelSummaryExpaned: {
    color: instagramColor
  },
  expansionPanelSummaryContent: {
    margin: "0 !important"
  },
  expansionPanelSummaryExpandIcon: {
    [theme.breakpoints.up("md")]: {
      top: "auto !important"
    },
    transform: "rotate(0deg)",
    color: "inherit",
    right: "10px",
    position: "absolute",
    [theme.breakpoints.down("sm")]: {
      top: "10px !important"
    },
    // some jss/css to make the cards look a bit better on Internet Explorer
    "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
      display: "inline-block !important"
    }
  },
  expansionPanelSummaryExpandIconExpanded: {},
  title: {
    fontSize: "15px",
    fontWeight: "bolder",
    marginTop: "0",
    marginBottom: "0",
    color: "inherit"
  },
  expansionPanelDetails: {
    display: "block",
    padding: "15px 0px 5px",
    fontSize: ".875rem"
  }
});

export default accordionStyle;
