/*eslint-disable*/
import React from "react";
// @material-ui/core components

// core components
import Footer from "components/GFooter/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import Input from "components/GCustomInput/CustomInputVer.js";
import CustomInput from "components/GCustomInput/CustomInput.js";
import * as EmailValidator from "email-validator";

/**
 * Register style
 */
import styles from "assets/jss/material-kit-pro-react/views/gRegisterStyle.js";

import image from "assets/img/newbg1.jpg";

import { withRouter } from "react-router-dom";

import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import { CloseRounded, Save } from "@material-ui/icons";

import { Redirect } from "react-router-dom";
import axios from "axios";
import Config from "config";

import secureStorage from "libs/secureStorage";
import { Modal, Header, Transition } from "semantic-ui-react";
import ReCAPTCHA from "react-google-recaptcha";
import Resource from "../../libs/resource";

class Verification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      user: "",
      verCode: "",
      authCode: "",
      message: "",
      submidDisabled: true,
      resendDisabled: false,
      resendEmailDisabled: false,
      saveDisabled: true,
      count: 0,
      openModal: false,
      modalTitle: "",
      inputChange: "",
      errMessage: "",
      inputTimeout: "",
      email: "",
      phone: "",
      resetPass: secureStorage.getItem("resetPass"),
      captchaRes: "",
      confirmCode: "",
      captchaKey: "",
    };
    this.reCaptcha = React.createRef();
  }
  componentDidMount() {
    const token = secureStorage.getItem("token");
    if (!token) {
      const device = secureStorage.getItem("device");
      const user = secureStorage.getItem("account");
      const resetPass = secureStorage.getItem("resetPass");
      // console.log({user,device})
      if (resetPass) {
        const { email, phone, user } = resetPass;
        this.setState({ email, phone, user });
        secureStorage.removeItem("resetPass");
      } else if (user) {
        const { email, phone } = user;
        this.setState({ email, phone });
      }
    }
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    // window.location.hostname !=='payfren.id' && Resource.getInfo().then(info=>{
    //   // console.log(info)
    //   this.setState({captchaKey:info.captchaKey})
    // }).catch(err=>console.log(err))
  }

  // handleKeydown = (e) =>{
  //   if (e.key === 'Enter')
  //     this.verify(e.target.id)
  // }

  verify() {
    if (this.state.verCode.length === 6) {
      const user = this.state.resetPass
        ? this.state.user
        : secureStorage.getItem("account");
      // const authCode = this.state.authCode && this.state.authCode.replace(/ - /g,'')
      const verCode = this.state.verCode;
      axios
        .post(Config.backendUrl + "user/verification", {
          user,
          verCode,
          // authCode,
          captchaRes:
            this.state.resetPass && this.state.captchaRes
              ? this.state.captchaRes
              : false,
          resetPass: this.state.resetPass ? true : false,
          device: !this.state.resetPass && secureStorage.getItem("device"),
        })
        .then((res) => {
          // console.log({ response: res, resetPass: this.resetPass, user })
          if (!this.state.resetPass) {
            user.isVerified = true;
            secureStorage.setItem("account", user);
            secureStorage.setItem("token", res.data.token);
            axios.defaults.headers.Authorization = "Bearer " + res.data.token;
            this.setState({ message: "" });
          } else {
            this.setState({ confirmCode: res.data.confirmCode });
          }
        })
        .catch((err) => {
          //console.log(err.response)
          if (err.response) {
            if (this.state.count === 3) secureStorage.removeItem("account");
            if (err.response.status === 400) {
              this.setState({ count: this.state.count + 1 });
            }
            this.reCaptcha &&
              this.reCaptcha.current &&
              this.reCaptcha.current.reset();
            this.setState({ message: err.response.data, submidDisabled: true });
          } else {
            this.setState({
              message: "Koneksi jaringan bermasalah",
              submidDisabled: true,
            });
          }
        });
    }
  }

  render() {
    const user = secureStorage.getItem("account");
    // const resetPass =secureStorage.getItem('resetPass')
    // console.log(resetPass)
    if (!user && !this.state.resetPass) {
      return <Redirect to="/login" />;
    }
    if (secureStorage.getItem("token")) {
      return <Redirect to="/" />;
    }
    if (this.state.confirmCode) {
      return <Redirect to={"/reset/" + this.state.confirmCode} />;
    }
    const { classes } = this.props;
    // const hostname = window.location.hostname
    return (
      <div>
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={10} md={10}>
                <Card className={classes.cardSignup}>
                  <CardBody>
                    <GridContainer justify="center">
                      <GridItem
                        xs={12}
                        sm={12}
                        md={12}
                        style={{ textAlign: "center" }}
                      >
                        {this.state.resetPass ? (
                          <p>
                            Kode OTP telah dikirim via{" "}
                            {this.state.email ? "email" : "SMS"} ke{" "}
                            {this.state.email
                              ? this.state.email
                              : this.state.phone}{" "}
                          </p>
                        ) : (
                          <p>
                            Kode OTP telah dikirim via email ke{" "}
                            {this.state.email}{" "}
                          </p>
                        )}

                        {/* <p>Cek Spam jika tidak ada di Inbox/Kotak Masuk </p> */}
                        <h4 className={classes.cardTitle}>
                          Masukkan kode verifikasi OTP
                        </h4>
                        <Input
                          id="verCode"
                          formControlProps={{
                            //fullWidth: true,
                            className: classes.customFormControlClasses,
                          }}
                          inputProps={{
                            value: this.state.verCode,
                            onChange: (e) => {
                              let verCode = e.target.value;
                              if (verCode.length > 6)
                                verCode = verCode.substring(
                                  0,
                                  verCode.length - 1
                                );
                              !isNaN(verCode) && this.setState({ verCode });
                              if (this.state.resetPass)
                                verCode.length === 6 &&
                                  !isNaN(verCode) &&
                                  this.state.captchaRes &&
                                  this.setState({ submidDisabled: false });
                              else
                                verCode.length === 6 &&
                                  !isNaN(verCode) &&
                                  this.setState({ submidDisabled: false });
                            },
                            onKeyDown: (e) => {
                              if (e.key === "Enter") {
                                if (this.state.resetPass)
                                  this.state.captchaRes && this.verify();
                                else this.verify();
                              }
                            },
                            type: "tel",
                          }}
                        />
                        {this.state.message ? (
                          <p style={{ color: "red" }}>{this.state.message}</p>
                        ) : null}
                        <br />
                        {this.state.resetPass && ( //(window.location.hostname==='payfren.id' || (window.location.hostname!=='payfren.id' && this.state.captchaKey)) &&
                          // <div style={{textAlign:'center'}}>
                          <ReCAPTCHA
                            style={{ display: "inline-block" }}
                            ref={this.reCaptcha}
                            hl="id"
                            // sitekey={"6LeTB2YdAAAAAMRSlP7bunNp5XvSbZlBViFvKltd"}
                            sitekey={"6LfFQRwqAAAAAGicM3Ug910qi17m1XNUcQqkSwnY"}
                            onChange={(e) => {
                              if (this.state.verCode.length !== 6) {
                                this.setState({
                                  captchaRes: e,
                                  submidDisabled: true,
                                });
                              } else if (isNaN(this.state.verCode)) {
                                this.setState({
                                  captchaRes: e,
                                  submidDisabled: true,
                                });
                              } else
                                this.setState({
                                  captchaRes: e,
                                  submidDisabled: false,
                                });
                            }}
                          />
                        )
                        // </div>
                        }
                        <br />
                        <Button
                          round
                          color="instagram"
                          onClick={() => this.verify()}
                          disabled={this.state.submidDisabled}
                        >
                          submit
                        </Button>
                      </GridItem>
                      {/* } */}
                      {!this.state.resetPass && (
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ textAlign: "center", marginTop: 200 }}
                        >
                          {this.state.resendEmailDisabled ? (
                            <p>
                              Kode verifikasi OTP telah terkirim ulang. Silahkan
                              cek Email.
                            </p>
                          ) : (
                            <p>
                              Tekan tombol dibawah untuk mengirim ulang kode
                              verifikasi OTP via Email
                            </p>
                          )}
                          <Button
                            size="sm"
                            round
                            color="facebook"
                            onClick={() => {
                              this.setState({ resendEmailDisabled: true });
                              axios
                                .post(
                                  Config.backendUrl +
                                    "user/verification/resend",
                                  { user, email: true }
                                )
                                .then((res) => {})
                                .catch((err) => {});
                            }}
                            style={{ marginBottom: 20 }}
                            disabled={this.state.resendEmailDisabled}
                          >
                            kirim ulang otp email
                          </Button>
                          {this.state.resendDisabled ? (
                            <p>
                              Kode verifikasi OTP telah terkirim. Silahkan cek
                              SMS
                            </p>
                          ) : (
                            <p>
                              Tekan tombol dibawah untuk mengirim kode
                              verifikasi OTP via SMS ke nomor {this.state.phone}
                            </p>
                          )}
                          <Button
                            size="sm"
                            round
                            color="facebook"
                            onClick={() => {
                              this.setState({ resendDisabled: true });
                              axios
                                .post(
                                  Config.backendUrl +
                                    "user/verification/resend",
                                  { user, phone: true }
                                )
                                .then((res) => {})
                                .catch((err) => {});
                            }}
                            disabled={this.state.resendDisabled}
                          >
                            kirim otp sms
                          </Button>
                        </GridItem>
                      )}
                      {user && !user.isVerified && (
                        <GridItem
                          xs={12}
                          sm={12}
                          md={12}
                          style={{ textAlign: "center", marginTop: 20 }}
                        >
                          <p>Salah No HP atau Email ?</p>
                          <Button
                            size="sm"
                            round
                            color="facebook"
                            onClick={() => {
                              this.setState({
                                openModal: true,
                                modalTitle: "No HP",
                                inputChange: user.phone,
                              });
                            }}
                            disabled={this.state.resendDisabled}
                          >
                            ubah no hp
                          </Button>
                          <Button
                            size="sm"
                            round
                            color="facebook"
                            onClick={() => {
                              this.setState({
                                openModal: true,
                                modalTitle: "Email",
                                inputChange: user.email,
                              });
                            }}
                            disabled={this.state.resendDisabled}
                          >
                            ubah email
                          </Button>
                        </GridItem>
                      )}
                    </GridContainer>
                  </CardBody>
                </Card>
              </GridItem>
            </GridContainer>
            <Transition.Group animation="fly down" duration={500}>
              {this.state.openModal && (
                <Modal
                  size="mini"
                  dimmer="blurring"
                  onClose={() => this.setState({ openModal: false })}
                  // onOpen={()=>this.setState({openModal:true})}
                  open={this.state.openModal}
                >
                  <Header
                    icon="edit"
                    content={"Ubah " + this.state.modalTitle}
                  />
                  <Modal.Content>
                    <CustomInput
                      labelText={this.state.modalTitle}
                      id="inputChange"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Masukkan " + this.state.modalTitle,
                        type: "text",
                        value: this.state.inputChange,
                        onChange: (e) => {
                          this.setState({ saveDisabled: true });
                          this.state.inputTimeout &&
                            clearTimeout(this.state.inputTimeout);
                          const val = e.target.value;

                          // let saveDisabled = false
                          if (
                            !val ||
                            val === user.phone ||
                            val === user.email ||
                            (this.state.modalTitle === "Email" &&
                              !EmailValidator.validate(val)) ||
                            (this.state.modalTitle === "No HP" && isNaN(val)) ||
                            (this.state.modalTitle === "No HP" &&
                              val.indexOf("08") !== 0)
                          )
                            this.setState({ saveDisabled: true });
                          else {
                            this.state.inputTimeout = setTimeout(() => {
                              const payload = {
                                username: null,
                                email: this.state.modalTitle === "Email" && val,
                                phone: this.state.modalTitle !== "Email" && val,
                              };
                              axios
                                .post(
                                  Config.backendUrl + "user/availability",
                                  payload
                                )
                                .then((res) => {
                                  // saveDisabled=false
                                  const data = res.data;
                                  this.setState({
                                    errMessage: "",
                                    saveDisabled: false,
                                  });
                                })
                                .catch((err) => {
                                  console.log(err);
                                  // saveDisabled = true
                                  if (err.response) {
                                    // saveDisabled = true
                                    const data = err.response.data;
                                    const target = Object.keys(data)[0];
                                    this.setState({
                                      errMessage: data[target],
                                      saveDisabled: true,
                                    });
                                  }
                                });
                            }, 1500);
                          }
                          this.setState({ inputChange: val });
                        },
                        onKeyDown: (e) =>
                          e.key === "Enter" && this.changeData(),
                        type:
                          this.state.modalTitle === "Email" ? "email" : "tel",
                      }}
                    />
                    <div style={{ color: "red" }}>{this.state.errMessage}</div>
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      round
                      color="google"
                      onClick={() =>
                        this.setState({ openModal: false, errMessage: "" })
                      }
                    >
                      <CloseRounded />
                      batal
                    </Button>
                    <Button
                      round
                      color="facebook"
                      onClick={this.changeData}
                      disabled={this.state.saveDisabled}
                    >
                      <Save />
                      simpan
                    </Button>
                  </Modal.Actions>
                </Modal>
              )}
            </Transition.Group>
          </div>
          <Footer />
        </div>
      </div>
    );
  }
  changeData = (e) => {
    const user = secureStorage.getItem("account");
    const payload = {
      user,
      email: this.state.modalTitle === "Email" && this.state.inputChange,
      phone: this.state.modalTitle !== "Email" && this.state.inputChange,
    };
    axios
      .post(Config.backendUrl + "user/verification/changedata", payload)
      .then((res) => {
        // saveDisabled=false
        const data = res.data;
        const email =
          this.state.modalTitle === "Email"
            ? { email: this.state.inputChange }
            : {};
        const phone =
          this.state.modalTitle !== "Email"
            ? { phone: this.state.inputChange }
            : {};
        const newUser = {
          ...user,
          ...email,
          ...phone,
        };
        secureStorage.setItem("account", newUser);
        this.setState({
          ...email,
          ...phone,
          resendDisabled: true,
          errMessage: "",
          saveDisabled: true,
          openModal: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}
Verification.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(styles)(Verification));
