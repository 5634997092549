import React from "react";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import InputAdornment from "@material-ui/core/InputAdornment";

import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Phone from "@material-ui/icons/PhoneAndroid";
import Address from "@material-ui/icons/ContactMail";
import ProvinceIcon from "@material-ui/icons/Map";

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Provinces from "helper/Provinces";
import SnackbarContent from "components/GSnackbar/SnackbarContent.js";

import Input from "components/GCustomInput/CustomInput.js";

import settingsStyles from "assets/jss/material-kit-pro-react/views/gSettingsStyle.js";

import axios from "axios";
import Config from "config";
import secureStorage from "libs/secureStorage";
import { Person } from "@material-ui/icons";
import defaultImage from "assets/img/faces/profile.png";
import Link from "@material-ui/core/Link";
import PublishIcon from "@material-ui/icons/Publish";
/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

const styles = {
  ...settingsStyles,
  profileImage: {
    float: "left",
    overflow: "hidden",
    borderRadius: "5% !important",
    width: "100px",
    height: "100px",
    margin: "0 10px 10px 20px",
  },
  imgContainer: {
    borderRadius: "50px",
    lineHeight: "20px",
    margin: "0px",
    marginTop: "-10px",
    paddingTop: "10px",
    paddingRight: "10px",
    display: "inline-flex",
    color: "inherit",
    position: "relative",
    textALign: "center",
    "& svg": {
      position: "absolute",
      top: "50px",
      right: "45px",
      zIndex: "1",
      width: "50px !important",
      height: "50px !important",
      color: "transparent",
    },
    "&:hover,&:focus": {
      background: "rgba(11, 127, 171, 0.2)",
      "& svg": {
        color: "#4b77be",
        alpha: "0.5",
      },
    },
  },
};

class AccountDetail extends React.Component {
  constructor(props) {
    super();
    this.state = {
      username: "",
      fullName: "",
      email: "",
      phone: "",
      address: "",
      province: "",

      alert: false,
      error: [],
      success: [],

      showModal: false,
      modalTitle: "",
      modalMessage: "",

      saveButtonDisabled: true,
      buttonDisabled: true,
      profileImage: defaultImage,
      imageFile: "",
      imageData: "",
    };
  }
  componentDidMount() {
    this.initField();
    this.initProfileImage();
  }
  initProfileImage() {
    const imageData = secureStorage.getItem("profileImage");
    if (imageData) {
      this.setState({ profileImage: imageData.image, imageData });
    }
  }
  initField() {
    const user = secureStorage.getItem("account");
    //console.log(Provinces.findIndex(item=>item.name===user.province))
    this.setState({
      username: user.username,
      fullName: user.name,
      email: user.email,
      phone: user.phone,
      address: user.address,
      province: Provinces.findIndex((item) => item.name === user.province),
    });
  }
  handleInput = (e) => {
    //alert(e.id)
    if (this.state.buttonDisabled) this.setState({ buttonDisabled: false });
    if (e.target.id === "fullName") {
      this.setState({ fullName: e.target.value });
    }
    if (e.target.id === "phone") this.setState({ phone: e.target.value });
    if (e.target.id === "email") this.setState({ email: e.target.value });
    if (e.target.id === "address") this.setState({ address: e.target.value });
  };
  handleBlur = (e) => {
    if (e.target.value)
      this.setState({
        success: { ...this.state.success, [e.target.id]: true },
      });
    else {
      this.setState({
        success: { ...this.state.success, [e.target.id]: false },
      });
      this.setState({ error: { ...this.state.error, [e.target.id]: true } });
    }
    //console.log(this.state.error)
  };
  handleSelect = (event) => {
    if (this.state.buttonDisabled) this.setState({ buttonDisabled: false });
    this.setState({ province: event.target.value });
  };
  handleSave = (e) => {
    //this.setState({showModal:true})
    let error = [];
    if (!this.state.fullName)
      //console.log("test")
      error.fullName = true;
    if (!this.state.phone) error.phone = true;

    if (!this.state.email) error.email = true;
    if (!this.state.address) error.address = true;
    this.setState({ error: { ...error } });
    //console.log(this.state.error)
    if (
      this.state.fullName &&
      this.state.phone &&
      this.state.email &&
      this.state.address &&
      this.state.province
    ) {
      this.save();
    } else {
      this.setState({ alert: true });
    }
  };
  save() {
    this.setState({ buttonDisabled: true });
    const user = secureStorage.getItem("account");

    const payload = {
      // fullName : this.state.fullName,
      // phone : this.state.phone,
      // email : this.state.email,
      // address : this.state.address,
      // province : Provinces[this.state.province],
    };
    if (this.state.fullName !== user.name) payload.name = this.state.fullName;
    if (this.state.phone !== user.phone) payload.phone = this.state.phone;
    if (this.state.email !== user.email) payload.email = this.state.email;
    if (
      this.state.province !==
      Provinces.findIndex((item) => item.name === user.province)
    )
      payload.province = Provinces[this.state.province];
    //console.log(payload)
    axios
      .post(Config.backendUrl + "user/update", payload)
      .then((res) => {
        //console.log(res.data)
        secureStorage.setItem("account", res.data.user);
        this.setState({
          showModal: true,
          modalTitle: "Sukses",
          modalMessage: "Data telah diupdate",
        });
      })
      .catch((err) => {
        if (err.response) {
          this.setState({
            showModal: true,
            modalTitle: "Gagal",
            modalMessage: err.response.data,
          });
        } else {
          this.setState({
            showModal: true,
            modalTitle: "Gagal",
            modalMessage: "Koneksi jaringan terputus",
          });
        }
      });
  }
  handleAlertExit = (e) => {
    this.setState({ alert: false });
  };
  handleCloseModal = (e) => {
    this.setState({ showModal: false });
  };
  render() {
    const { classes } = this.props;
    return (
      <GridContainer justify="center">
        <GridItem xs={12} sm={12} md={12}>
          <Link
            component="button"
            onClick={(e) => {
              const input = document.createElement("input");
              input.type = "file";
              input.accept = "image/gif, image/jpeg, image/png";
              input.onchange = (e) => {
                const data = new FormData();
                const file = e.target.files[0];
                data.append("file", file);
                const reader = new FileReader();
                reader.onload = (ev) => {
                  this.setState({
                    profileImage: ev.target.result,
                    imageFile: file,
                    saveButtonDisabled: false,
                  });
                };
                reader.readAsDataURL(file);
              };
              input.click();
            }}
            className={classes.imgContainer}
          >
            <PublishIcon />
            <img
              src={this.state.profileImage}
              alt="..."
              className={classes.profileImage}
            />
          </Link>
          <div>
            <Button
              round
              disabled={this.state.saveButtonDisabled}
              onClick={() => {
                if (this.state.imageFile) {
                  const data = new FormData();
                  data.append("image", this.state.imageFile);
                  axios
                    .post(Config.backendUrl + "user/image/save", data)
                    .then((res) => {
                      const imageData = {
                        name: res.data.image,
                        image: this.state.profileImage,
                      };
                      secureStorage.setItem("profileImage", imageData);
                      this.props.onProfileImageChange(imageData.image);
                      this.setState({ saveButtonDisabled: true });
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                }
              }}
              color="instagram"
            >
              simpan
            </Button>
          </div>
        </GridItem>
        <GridItem xs={12} sm={12} md={12}>
          <div className={classes.textCenter}></div>
          {/**
           * Registration Form
           */}
          <form className={classes.form}>
            <Input
              // error={this.state.error?this.state.error.email:false}
              // success={this.state.success?this.state.success.email:false}
              id="username"
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Person className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                readOnly: true,
                disabled: true,
                // placeholder: "contoh : john@payfren.id ",
                value: this.state.username,
                // onChange:this.handleInput,
                // onBlur:this.handleBlur
              }}
              labelText="Username"
            />
            <Input
              error={this.state.error ? this.state.error.email : false}
              success={this.state.success ? this.state.success.email : false}
              id="email"
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Email className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: "contoh : john@payfren.id ",
                value: this.state.email,
                onChange: this.handleInput,
                onBlur: this.handleBlur,
              }}
              labelText="Email"
            />
            <Input
              error={this.state.error ? this.state.error.phone : false}
              success={this.state.success ? this.state.success.phone : false}
              id="phone"
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Phone className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: "contoh : 081123321456",
                value: this.state.phone,
                onChange: this.handleInput,
                onBlur: this.handleBlur,
              }}
              labelText="No Handphone"
            />
            <Input
              error={this.state.error ? this.state.error.fullName : false}
              success={this.state.success ? this.state.success.fullName : false}
              id="fullName"
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Face className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: "contoh : Eren Jaeger",
                value: this.state.fullName,
                onChange: this.handleInput,
                onBlur: this.handleBlur,
              }}
              labelText="Nama lengkap"
            />
            <Input
              error={this.state.error ? this.state.error.address : false}
              success={this.state.success ? this.state.success.address : false}
              id="address"
              formControlProps={{
                fullWidth: true,
                className: classes.customFormControlClasses,
              }}
              inputProps={{
                endAdornment: (
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <Address className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                ),
                placeholder: "contoh : Jl. Jenderal Sudirman No. 99 Jagakarsa",
                value: this.state.address,
                onChange: this.handleInput,
                onBlur: this.handleBlur,
              }}
              labelText="Alamat lengkap"
            />
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel
                htmlFor="province-select"
                className={classes.selectLabel}
              >
                Provinsi
              </InputLabel>
              <Select
                endAdornment={
                  <InputAdornment
                    position="end"
                    className={classes.inputAdornment}
                  >
                    <ProvinceIcon className={classes.inputAdornmentIcon} />
                  </InputAdornment>
                }
                MenuProps={{
                  className: classes.selectMenu,
                }}
                classes={{
                  select: classes.select,
                }}
                value={this.state.province}
                onChange={this.handleSelect}
                inputProps={{
                  name: "province",
                  id: "province",
                }}
              >
                <MenuItem
                  disabled
                  classes={{
                    root: classes.selectMenuItem,
                  }}
                  value={-1}
                >
                  Provinsi
                </MenuItem>
                {Provinces.map((prov, index) => (
                  <MenuItem
                    key={index}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={index}
                  >
                    {prov.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {this.state.alert ? (
              <SnackbarContent
                message={<span>Semua kolom harus diisi</span>}
                onExited={this.handleAlertExit}
                close
                color="danger"
                icon="info_outline"
              />
            ) : null}
            <div>
              <div className={classes.textCenter}>
                <Button
                  round
                  color="instagram"
                  onClick={this.handleSave}
                  disabled={this.state.buttonDisabled ? true : false}
                >
                  Simpan
                </Button>
              </div>
              <Dialog
                classes={{
                  root: classes.modalRoot,
                  paper: classes.modal,
                }}
                open={this.state.showModal}
                TransitionComponent={Transition}
                keepMounted
                //onClose={() => this.setState({showModal: false})}
                aria-labelledby="classic-modal-slide-title"
                aria-describedby="classic-modal-slide-description"
              >
                <DialogTitle
                  id="classic-modal-slide-title"
                  disableTypography
                  className={classes.modalHeader}
                >
                  <h4 className={classes.modalTitle}>
                    {this.state.modalTitle}
                  </h4>
                </DialogTitle>
                <DialogContent
                  id="classic-modal-slide-description"
                  className={classes.modalBody}
                >
                  <p>{this.state.modalMessage}</p>
                </DialogContent>
                <DialogActions className={classes.modalFooter}>
                  <Button round onClick={this.handleCloseModal} color="success">
                    OK
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </form>
        </GridItem>
      </GridContainer>
    );
  }
}

AccountDetail.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AccountDetail);
