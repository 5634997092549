import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// @material-ui/icons

// core components
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/gTransactionStyle.js";

// @material-ui/icons
// core components
import ArrowBackIos from '@material-ui/icons/ArrowBackIos';

import Slide from "@material-ui/core/Slide";


import Accordion from "components/GAccordion/Accordion.js";

import Prepaid from "./Prepaid";
import Postpaid from "./Postpaid";
import PaketData from "./PaketData";
import PaketDataDenom from "./PaketDataDenom"


/**
 * Transition for Modal
 */


class Pulsa extends React.Component {

  constructor(props){
    super(props);
    this.state = {
      checked : false,
      direction : 'left',
      expanded : 'panel1',
    };
  }

  componentDidMount(){
    this.setState({checked:true});
  }

  handleBack = () =>{
    this.setState({checked:false,direction:'right'});
  }
  handleExited = () =>{
    this.props.history.push('/MainMenu')
  }

  handleChangeExpansion = (e)=>(event, isExpanded)=>{

    this.setState({expanded:isExpanded?e:false});
  }

  render(){
    const {classes} = this.props;
    
    return (
      <Slide direction={this.state.direction} 
        in={this.state.checked} 
        mountOnEnter 
        unmountOnExit
        onExited={this.handleExited}
      >
        <div className={classNames(classes.main, classes.mainRaised)}>
          <Button color="google" round className={classes.backButton} onClick={this.handleBack}><ArrowBackIos/> Back</Button>
          <div className={classes.container}>
            <Accordion
                activeColor="behance"
                active={0}
                collapses={[
                  {
                    title: "Pulsa Prepaid",
                    content:
                      <Prepaid />
                  },
                  {
                    title : 'Paket Data (Denom)',
                    content : 
                      <PaketDataDenom/>
                  },
                  {
                    title: "Paket Data",
                    content:
                      <PaketData />
                  },
                  {
                    title: "Pulsa Postpaid",
                    content:
                      <Postpaid />
                  },
                ]}
              />
          </div>
        </div>
      </Slide>
    );
  }
}
Pulsa.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Pulsa);