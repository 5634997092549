import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

import CircularProgress from "@material-ui/core/CircularProgress"

import settingsStyles from "assets/jss/material-kit-pro-react/views/gSettingsStyle.js";

// @material-ui/icons


// core components
import Pay from "@material-ui/icons/Payment";

import Input from "components/GCustomInput/CustomInput.js";


import axios from 'axios'
import Config from 'config'

import SnackbarContent from "components/GSnackbar/SnackbarContent.js";



const styles = {
	...settingsStyles,
}



class ChangePassword extends React.Component {

  constructor(props){
    super(props);
    this.state = {

			oldPass : '',
			newPass : '',
			rePass : '',
			buttonPassDisabled : false,

			snackBar : false,
			snackMessage : '',
			snackColor : 'danger',

		}
  }

  componentDidMount(){

	}
	handleChangePass = (e) => {
		this.setState({[e.target.id]:e.target.value})
	}
	handleAlertExit = () =>{
		this.setState({snackBar:false})
	}
	handleSubmitChangePass = (e) => {
		if(!this.state.oldPass && !this.state.newPass && !this.state.rePass){
			this.setState({
				snackBar : true,
				snackColor : 'danger',
				snackMessage : 'Semua field harus diisi !'
			})
		}
		else if(this.state.newPass !== this.state.rePass){
			this.setState({
				snackBar : true,
				snackColor : 'danger',
				snackMessage : 'Password dan Re-type passwod harus sama !'
			})
		}
		else{
			axios.post(Config.backendUrl+'user/changepass',{
				oldPass : this.state.oldPass,
				newPass : this.state.newPass
			}).then(res => {
				this.setState({
					snackBar : true,
					snackColor : 'success',
					snackMessage : res.data
				})
			}).catch(error => {
					if(error.response){
						this.setState({
							snackBar : true,
							snackColor : 'danger',
							snackMessage : error.response.data
						})
					}
			});
		}
	}
	
  
  render(){
    const {classes} = this.props;

    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} className={classes.grid}>
          {
            this.state.snackBar?
              <SnackbarContent
              message={
                <span>
                  {this.state.snackMessage}
                </span>
              }
              onExited = {this.handleAlertExit}
              close
              color={this.state.snackColor}
              icon="info_outline"
            />:null
          }
            <Input
              labelText="Password Lama"
              id="oldPass"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "password",
                value : this.state.oldPass,
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className={classes.inputIconsColor}>
                      lock_outline
                    </Icon>
                  </InputAdornment>
                ),
                //autoComplete: "off",
                onChange: this.handleChangePass
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} className={classes.grid}>
            <Input
              labelText="Password Baru"
              id="newPass"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "password",
                value : this.state.newPass,
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className={classes.inputIconsColor}>
                      lock_outline
                    </Icon>
                  </InputAdornment>
                ),
                //autoComplete: "off",
                onChange: this.handleChangePass
              }}
            />
          </GridItem>
        </GridContainer>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} className={classes.grid}>
            <Input
              labelText="Konfirmasi Password"
              id="rePass"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "password",
                value : this.state.rePass,
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className={classes.inputIconsColor}>
                      lock_outline
                    </Icon>
                  </InputAdornment>
                ),
                //autoComplete: "off",
                onChange: this.handleChangePass
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={8} className={classes.grid}>
            {!this.state.buttonPassDisabled?
            <Button id="changePass" color="instagram" round onClick={this.handleSubmitChangePass}><Pay/>Submit</Button>
            :<CircularProgress color="secondary" />}
          </GridItem>
        </GridContainer>
      </div>	
    );
  }
}
ChangePassword.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(ChangePassword);