import React from "react";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

import CircularProgress from "@material-ui/core/CircularProgress";

import settingsStyles from "assets/jss/material-kit-pro-react/views/gSettingsStyle.js";

// @material-ui/icons

// core components
import Pay from "@material-ui/icons/Payment";

import Input from "components/GCustomInput/CustomInput.js";

import axios from "axios";
import Config from "config";

import SnackbarContent from "components/GSnackbar/SnackbarContent.js";
import secureStorage from "libs/secureStorage";

const styles = {
  ...settingsStyles,
};

class AdminNominal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      admPost: [],
      admPre: [],
      admNon: [],

      selectedPost: "",
      selectedPre: "",
      selectedNon: "",

      buttonDisabled: false,

      snackBar: false,
      snackMessage: "",
      snackColor: "danger",

      isLoading: true,
    };
  }

  componentDidMount() {
    this.getValues("Post");
    this.getValues("Pre");
    this.getValues("Non");

    this.loadAdminNominal("Pre", 15);
    this.loadAdminNominal("Post", 14);
    this.loadAdminNominal("Non", 984);
  }

  loadAdminNominal = (type, productId) => {
    axios
      .post(Config.backendUrl + "product/admin", {
        productId,
      })
      .then((res) => {
        this.setState({
          ["adm" + type]: res.data.map((item) => ({
            name: item.nominal,
            value: item.id,
          })),
        });
      })
      .catch((err) => {});
  };

  getValues = (type) => {
    const adminSelected = secureStorage.getItem(`adminNominal${type}`);
    this.setState({
      [`selected${type}`]: adminSelected === null ? "" : adminSelected.value,
    });
  };

  handleSelect = (type, e) => {
    const val = e.target.value;
    this.setState({
      [`selected${type}`]: val,
    });
    if (val === "") {
      secureStorage.removeItem(`adminNominal${type}`);
    } else {
      const adminNominals = this.state[`adm${type}`];
      const find = adminNominals.find((item) => item.value === val);
      secureStorage.setItem(`adminNominal${type}`, find);
    }
  };

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} className={classes.grid}>
            {this.state.snackBar ? (
              <SnackbarContent
                message={<span>{this.state.snackMessage}</span>}
                onExited={this.handleAlertExit}
                close
                color={this.state.snackColor}
                icon="info_outline"
              />
            ) : null}
          </GridItem>
        </GridContainer>
        <GridContainer
          justify="center"
          sx={{ paddingLeft: 5, paddingRight: 5 }}
        >
          <GridItem xs={12} sm={4} md={3} lg={3} className={classes.grid}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="post-select" className={classes.selectLabel}>
                PLN POATPAID
              </InputLabel>
              <Select
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                inputProps={{ name: "postAdmin", id: "postAdmin" }}
                value={this.state.selectedPost}
                onChange={(e) => this.handleSelect("Post", e)}
              >
                <MenuItem classes={{ root: classes.selectMenuItem }} value={""}>
                  {"Pilih Nominal"}
                </MenuItem>
                {this.state.admPost.map((item, index) => (
                  <MenuItem
                    key={index}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={item.value}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={4} md={3} lg={3} className={classes.grid}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="pre-select" className={classes.selectLabel}>
                PLN PREPAID
              </InputLabel>
              <Select
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                inputProps={{ name: "preAdmin", id: "preAdmin" }}
                value={this.state.selectedPre}
                onChange={(e) => this.handleSelect("Pre", e)}
              >
                <MenuItem
                  disabled
                  classes={{ root: classes.selectMenuItem }}
                  value={""}
                >
                  {"Pilih Nominal"}
                </MenuItem>
                {this.state.admPre.map((item, index) => (
                  <MenuItem
                    key={index}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={item.value}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>

          <GridItem xs={12} sm={4} md={3} lg={3} className={classes.grid}>
            <FormControl fullWidth className={classes.selectFormControl}>
              <InputLabel htmlFor="non-select" className={classes.selectLabel}>
                PLN NONTAGLIS
              </InputLabel>
              <Select
                MenuProps={{ className: classes.selectMenu }}
                classes={{ select: classes.select }}
                inputProps={{ name: "nonAdmin", id: "nonAdmin" }}
                value={this.state.selectedNon}
                onChange={(e) => this.handleSelect("Non", e)}
              >
                <MenuItem classes={{ root: classes.selectMenuItem }} value={""}>
                  {"Pilih Nominal"}
                </MenuItem>
                {this.state.admNon.map((item, index) => (
                  <MenuItem
                    key={index}
                    classes={{
                      root: classes.selectMenuItem,
                      selected: classes.selectMenuItemSelected,
                    }}
                    value={item.value}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </GridItem>
        </GridContainer>
      </div>
    );
  }
}

AdminNominal.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(AdminNominal);
