import React from "react";
// @material-ui/core components
//hook styles class conversion
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import InputAdornment from "@material-ui/core/InputAdornment";
// import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import People from "@material-ui/icons/People";
// import Code from "@material-ui/icons/Code"
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CustomInput from "components/GCustomInput/CustomInput.js";

import loginStyles from "assets/jss/material-kit-pro-react/views/gLoginPageStyle.js";

import logo from "assets/img/logo.png";
import image from "assets/img/newbg1.jpg";

import { Redirect } from "react-router-dom";
import axios from "axios";
import Config from "config";
import { IconButton } from "@material-ui/core";
import PinInput from "views/CustomComponents/PinInput";

import { Modal, Header, Transition } from "semantic-ui-react";
import secureStorage from "libs/secureStorage";
import * as EmailValidator from "email-validator";
import ReCAPTCHA from "react-google-recaptcha";
import Resource from "libs/resource";

// import FingerprintJS from "@fingerprintjs/fingerprintjs";

const styles = {
  ...loginStyles,
  imageSrc: {
    borderRadius: "40% !important",
    // position: 'absolute',
    // left: 0,
    // right: 0,
    // top: 0,
    // bottom: 0,
    // backgroundSize: '100%',
    // backgroundPosition: 'center',

    width: 120,
    height: "auto",
  },
  imageContainer: {
    // borderRadius: 60,
    // position: 'relative',
    // top: '50%',
    // color:'#fff',
    // background:"linear-gradient(60deg, #e5e5e5, #fff)",
    // height:100,
    // width:132,
  },
  imageSrcDefault: {
    // borderRadius: "6px !important",
    // position: 'absolute',
    // left: 0,
    // right: 0,
    // top: 0,
    // bottom: 0,
    // backgroundSize: '100%',
    // backgroundPosition: 'center',
    width: 151,
  },
  imageContainerDefault: {
    borderRadius: 60,
    position: "relative",
    top: "50%",
    color: "#fff",
    background: "linear-gradient(60deg, #e5e5e5, #fff)",
    height: 100,
    width: 132,
  },
};

class LoginPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      //cardAnimaton : "cardHidden",
      redirectToReferrer: false,
      username: "",
      password: "",
      errorMessage: "",
      device: "",
      verification: false,
      showPassword: false,

      resetPass: "",
      acResetPass: "",
      openModal: false,
      resetDisabled: true,
      resetErrMsg: "",
      captchaRes: "",
      captchaKey: "",
      background: image,
      logo:
        window.location.hostname === "payfren.id"
          ? logo
          : "/android-icon-512x512.png",
    };
    this.reCaptcha = React.createRef();
  }

  componentDidMount() {
    // secureStorage.removeItem('domainImage')
    const hostname = window.location.hostname;
    // console.log({hostname})
    hostname !== "payfren.id" && this.initResources();
    this.getDeviceUnique();
    if (!secureStorage.getItem("token") && secureStorage.getItem("account"))
      secureStorage.removeItem("account");
  }
  initResources() {
    Resource.checkUpdate();
    // Resource.getInfo()
    // .then(info=>{
    //   // console.log(info)
    //   // console.log(info.captchaKey)
    //   this.setState({captchaKey:info.captchaKey,info})
    // }).catch(err=>{
    //   // console.log('info error')
    //   console.log(err)
    // })
    // Resource.getImage()
    // .then(image=>{
    //   // console.log(image)
    //   this.setState({logo:image})
    // }).catch(err=>{
    //   // console.log('image error')
    //   console.log(err)
    // })
  }

  // async getUID() {
  //   const fp = await FingerprintJS.load();
  //   const result = await fp.get();
  //   return result.visitorId;
  // }

  async getDeviceUnique() {
    const device = secureStorage.getItem("device");

    if (!device) {
      axios
        .post(Config.backendUrl + "user/device")
        .then((res) => {
          //console.log(res.data.device)
          secureStorage.setItem("device", res.data.device);
          this.setState({ device: res.data.device });
        })
        .catch((err) => {
          console.log(err);
        });
    } else this.setState({ device: device });
    //console.log(device)
  }

  handleLogin = () => {
    if (this.state.username && this.state.password) this.login();
  };

  login(pin) {
    const payload = !pin
      ? {
          username: this.state.username,
          password: this.state.password,
          device: secureStorage.getItem("device"),
        }
      : {
          username: secureStorage.getItem("username"),
          pin,
          device: secureStorage.getItem("device"),
        };
    //secureStorage.setItem('token','asdf')
    axios
      .post(Config.backendUrl + "user/login", payload)
      .then((res) => {
        secureStorage.setItem("account", res.data.user);

        if (res.data.isLogged) {
          secureStorage.setItem("token", res.data.token);
          axios.defaults.headers.Authorization = "Bearer " + res.data.token;
          this.setState({ redirectToReferrer: true });
        } else {
          this.setState({ verification: true, authCode: res.data.authCode });
        }
        // this.initDotmatrix()
      })
      .catch((err) => {
        console.log(err.response);
        if (err.response) {
          if (pin) this.setState({ wrongPin: true });
          else this.setState({ errorMessage: err.response.data });
        }
      });
  }
  handleResetPass = (e) => {
    const phone = !isNaN(this.state.resetPass) && this.state.resetPass;
    const email =
      EmailValidator.validate(this.state.resetPass) && this.state.resetPass;
    const captchaRes = this.state.captchaRes;
    // const acResetPass = this.state.acResetPass && this.state.acResetPass.replace(/ - /g,'')
    // console.log(captchaRes)
    if ((phone || email) && captchaRes) {
      axios
        .post(Config.backendUrl + "user/resetpass", {
          phone,
          email,
          captchaRes,
          // acResetPass,
        })
        .then((res) => {
          secureStorage.setItem("resetPass", {
            phone,
            email,
            user: res.data.user,
          });
          this.setState({ verification: true, openModal: false });
        })
        .catch((err) => {
          this.reCaptcha && this.reCaptcha.current.reset();
          err.response && this.setState({ resetErrMsg: err.response.data });
        });
    }
  };

  // initDotmatrix(){
  //   if(navigator.userAgent.toLocaleLowerCase().indexOf('electron/')!==-1){
  //     secureStorage.setItem('dotmatrix','online')
  //   }
  //   else{
  //     axios.post('http://localhost:4321/login')
  //     .then(res=>{
  //       console.log(res.data)
  //       secureStorage.setItem('dotmatrix',res.data)
  //     }).catch(err=>{
  //       secureStorage.setItem('dotmatrix',"offline")
  //       console.log(err)
  //     })
  //   }
  // }
  handleKeydown = (e) => {
    if (
      e.key === "Enter" &&
      this.state.resetDisabled === false &&
      this.state.captchaRes
    )
      this.handleResetPass();
  };

  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };
  //secureStorage.setItem('token','asdf')
  //secureStorage.removeItem('token')
  cardHeader() {
    const hostname = window.location.hostname;
    const { classes } = this.props;
    return (
      <CardHeader
        color="instagram"
        className={classes.cardHeader}
        style={{ display: "flex", justifyContent: "center" }}
      >
        {/* <div
					style={}
				><img src={logo} className={classes.imageSrc} alt='PayFren' /></div> */}
        <div
          className={
            hostname !== "payfren.id"
              ? classes.imageContainer
              : classes.imageContainerDefault
          }
        >
          <img
            src={this.state.logo}
            className={
              hostname !== "payfren.id"
                ? classes.imageSrc
                : classes.imageSrcDefault
            }
            alt={
              hostname !== "payfren.id"
                ? this.state.info && this.state.info.appName
                : "PayFren"
            }
          />
        </div>
      </CardHeader>
    );
  }
  render() {
    const { from } = this.props.location.state || { from: { pathname: "/" } };
    // setTimeout(function() {
    //   //this.setState({cardAnimaton:""});
    // }, 700);
    const { classes } = this.props;
    // const hostname = window.location.hostname

    if (this.state.redirectToReferrer === true) {
      return <Redirect to={from} />;
    }
    if (secureStorage.getItem("token")) {
      return <Redirect to="/" />;
    }
    if (this.state.verification) {
      console.log("this.state.verification", this.state.verification);
      return (
        <Redirect
          to={{ pathname: "/verification", authCode: this.state.authCode }}
        />
      );
    }
    return (
      <div>
        <div
          className={classes.pageHeader}
          style={{
            backgroundImage: "url(" + image + ")",
            backgroundSize: "cover",
            backgroundPosition: "top center",
          }}
        >
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <Card className={classes[this.state.cardAnimaton]}>
                  {!secureStorage.getItem("username") ? (
                    <form className={classes.form}>
                      {this.cardHeader()}
                      <CardBody>
                        <CustomInput
                          labelText="Username/Phone/Email"
                          id="username"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: "text",
                            value: this.state.username,
                            endAdornment: (
                              <InputAdornment position="end">
                                <People className={classes.inputIconsColor} />
                              </InputAdornment>
                            ),
                            onChange: this.handleChange,
                          }}
                        />
                        <CustomInput
                          labelText="Password"
                          id="password"
                          formControlProps={{
                            fullWidth: true,
                          }}
                          inputProps={{
                            type: this.state.showPassword ? "text" : "password",
                            value: this.state.password,
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={() =>
                                    this.setState({
                                      showPassword: !this.state.showPassword,
                                    })
                                  }
                                >
                                  {this.state.showPassword ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                            //autoComplete: "off",
                            onChange: this.handleChange,
                            onKeyPress: (e) =>
                              e.key === "Enter" && this.handleLogin(),
                          }}
                        />
                      </CardBody>
                      <div
                        style={{
                          textAlign: "center",
                          margin: "-10px 0 10px 0",
                          color: "red",
                        }}
                      >
                        {this.state.errorMessage}
                      </div>
                      <CardFooter className={classes.cardFooter}>
                        <Button
                          round
                          color="instagram"
                          size="lg"
                          onClick={this.handleLogin}
                        >
                          Login
                        </Button>
                      </CardFooter>
                      <div
                        style={{
                          textAlign: "center",
                          margin: "-10px 0 10px 0",
                        }}
                      >
                        <Button
                          simple
                          round
                          color="instagram"
                          onClick={() => this.setState({ openModal: true })}
                        >
                          Lupa Password ?
                        </Button>
                      </div>
                    </form>
                  ) : (
                    <form className={classes.form}>
                      {this.cardHeader()}
                      <CardBody style={{ textAlign: "center" }}>
                        <PinInput
                          length={6}
                          initialValue=""
                          // secret
                          focus
                          onChange={(value, index) => {
                            if (value.length < 6) {
                              this.setState({ wrongPin: false });
                            }
                          }}
                          type="numeric"
                          inputMode="tel"
                          style={{ padding: "10px" }}
                          inputStyle={{
                            backgroundColor: "rgb(248, 249, 250)",
                            border: "1px solid",
                            borderColor: this.state.wrongPin
                              ? "red"
                              : "rgb(204, 204, 204)",
                            borderRadius: "0.3rem",
                            fontSize: "2rem",
                            margin: "0.25rem",
                            height: "3rem",
                            outline: "none",
                            textAlign: "center",
                            transitionDuration: "250ms",
                            transitionProperty:
                              "background, color, border, box-shadow, transform",
                            width: "2.5rem",
                          }}
                          inputFocusStyle={{
                            borderColor: this.state.wrongPin
                              ? "red"
                              : "rgb(0, 123, 255)",
                            outline: "none",
                            transform: "scale(1.05)",
                          }}
                          onComplete={(value, index) => {
                            this.login(value);
                          }}
                          autoSelect={true}
                          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                        />
                      </CardBody>
                    </form>
                  )}
                </Card>
              </GridItem>
            </GridContainer>
            <Transition.Group animation="fly down" duration={500}>
              {this.state.openModal && (
                <Modal
                  // dimmer='blurring'
                  onClose={() => this.setState({ openModal: false })}
                  // onOpen={()=>this.setState({openModal:true})}
                  open={this.state.openModal}
                  // trigger={
                  //   <div style={{textAlign:"center",margin:"-10px 0 10px 0"}}>
                  //     <Button simple round color="instagram"  onClick={()=>console.log('lupa')}>Lupa Password ?</Button>
                  //   </div>
                  // }
                >
                  <Header icon="repeat" content="Reset Password" />
                  <Modal.Content>
                    <p>Masukkan Email atau No HP</p>
                    <CustomInput
                      labelText="Email/No HP"
                      id="resetPass"
                      formControlProps={{
                        fullWidth: true,
                      }}
                      inputProps={{
                        placeholder: "Masukkan Email atau No HP",
                        type: "text",
                        value: this.state.resetPass,
                        endAdornment: (
                          <InputAdornment position="end">
                            <People className={classes.inputIconsColor} />
                          </InputAdornment>
                        ),
                        onChange: (e) => {
                          this.setState({ resetErrMsg: "" });
                          const val = e.target.value;
                          if (!val) {
                            this.setState({
                              resetPass: val,
                              resetDisabled: true,
                            });
                          } else if (
                            isNaN(val) &&
                            !EmailValidator.validate(val)
                          )
                            this.setState({
                              resetPass: val,
                              resetDisabled: true,
                            });
                          else if (!isNaN(val) && val.indexOf("08") !== 0)
                            this.setState({
                              resetPass: val,
                              resetDisabled: true,
                            });
                          else if (!this.state.captchaRes)
                            this.setState({
                              resetPass: val,
                              resetDisabled: true,
                            });
                          else
                            this.setState({
                              resetPass: val,
                              resetDisabled: false,
                            });
                        },
                        onKeyDown: this.handleKeydown,
                      }}
                    />
                    <div style={{ color: "red" }}>{this.state.resetErrMsg}</div>
                    {/* {(window.location.hostname==='payfren.id' || (window.location.hostname!=='payfren.id' && this.state.captchaKey)) && */}
                    <ReCAPTCHA
                      ref={this.reCaptcha}
                      hl="id"
                      // sitekey={"6LeTB2YdAAAAAMRSlP7bunNp5XvSbZlBViFvKltd"}
                      sitekey={"6LfFQRwqAAAAAGicM3Ug910qi17m1XNUcQqkSwnY"}
                      onChange={(e) => {
                        // console.log(isNaN('a'))
                        // console.log(this.state.resetPass,isNaN(this.state.resetPass) , !EmailValidator.validate(this.state.resetPass))
                        if (this.state.resetPass === "") {
                          this.setState({ captchaRes: e, resetDisabled: true });
                        } else if (
                          isNaN(this.state.resetPass) &&
                          !EmailValidator.validate(this.state.resetPass)
                        ) {
                          this.setState({ captchaRes: e, resetDisabled: true });
                        } else
                          this.setState({
                            captchaRes: e,
                            resetDisabled: false,
                          });
                      }}
                    />
                    {/* } */}
                    {/* <p>
                    Atau masukkan Kode Autentikasi
                  </p>
                  <CustomInput
                    labelText="Kode Autentikasi"
                    id="acResetPass"
                    formControlProps={{
                      fullWidth: true
                    }}
                    inputProps={{
                      placeholder:'Kode Autentikasi',
                      type: "text",
                      value : this.state.acResetPass,
                      endAdornment: (
                        <InputAdornment position="end">
                          <Code className={classes.inputIconsColor} />
                        </InputAdornment>
                      ),
                      onChange:(e)=>{
                        let val = e.target.value
                        val = val.replace(/ - /g,'')
                        if(val.length>24)
                          val = val.slice(0,-1)
                        if(val)
                          val = val.toUpperCase().match(/.{1,4}/g).join(' - ')
                        this.setState({acResetPass:val})
                      },
                      onKeyDown:this.handleKeydown,
                    }}
                  /> */}
                  </Modal.Content>
                  <Modal.Actions>
                    <Button
                      round
                      color="google"
                      onClick={() => this.setState({ openModal: false })}
                    >
                      cancel
                    </Button>
                    <Button
                      round
                      color="facebook"
                      onClick={this.handleResetPass}
                      disabled={this.state.resetDisabled}
                    >
                      confirm
                    </Button>
                  </Modal.Actions>
                </Modal>
              )}
            </Transition.Group>
          </div>
          {/*<Footer whiteFont />*/}
        </div>
      </div>
    );
  }
}
LoginPage.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(LoginPage);
