import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// @material-ui/icons

// core components

import Button from "components/CustomButtons/Button.js";

import checkboxStyles from "assets/jss/material-kit-pro-react/gCustomCheckboxRadioSwitchStyle.js";
import settingsStyles from "assets/jss/material-kit-pro-react/views/gSettingsStyle.js";

// @material-ui/icons
import AccountCircle from "@material-ui/icons/AccountCircle";

// core components
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";

import Slide from "@material-ui/core/Slide";

import Accordion from "components/GAccordion/Accordion.js";
import Clearfix from "components/Clearfix/Clearfix";

import AccountDetail from "views/MyProfile/AccountDetail";
import Ticketing from "views/MyProfile/Ticketing";
import Bill from "views/MyProfile/Bill";
import Withdrawal from "./Withdrawal";
import secureStorage from "libs/secureStorage";

import axios from "axios";
import Config from "config";

const userTl = [4, 23, 29];
const userDm = [6, 7, 9, 10, 11, 14, 15, 16, 17, 18];

const styles = {
  ...checkboxStyles,
  ...settingsStyles,
};

class MyProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      fbData: null,
      isFbDisabled: false,

      invitationId: "",

      redirect: false,

      showModal: false,
      modalTitle: "",
      modalMessage: "",

      selectedEnabled: "thermal",

      direction: "right",
      expanded: "panel1",
      settingsMenu: [],
      invitationLink: "",
      toolTipOpen: false,
      log: "",

      snackBar: false,
      snackMessage: "",
      snackColor: "danger",

      activeAccordion: 0,

      bill: 0,
      showBill: false,
    };
  }

  componentDidMount() {
    const user = secureStorage.getItem("account");

    userTl.includes(user.userTypeId) && this.getBill();
    this.checkBill();
    this.setState({ checked: true });

    if (secureStorage.getItem("ver")) {
      this.setState({ activeAccordion: 2 });
      secureStorage.removeItem("ver");
    } else if (userTl.includes(user.userTypeId) && !user.isDm) {
      this.setState({ activeAccordion: 1 });
    }
  }

  getBill() {
    this.setState({ bill: secureStorage.getItem("bill") });
  }

  checkBill() {
    // this.setState({ isLoading: true });
    axios
      .post(Config.backendUrl + "bill/status")
      .then((res) => {
        const bill = res.data;
        console.log({ bill });
        this.setState({ showBill: bill ? true : false });
      })
      .catch((err) => {
        this.setState({ showBill: false });
        //console.log(err)
      });
  }

  handleTooltipClose = (e) => {
    this.setState({ toolTipOpen: false });
  };

  handleCopy = (e) => {
    this.input.select();
    document.execCommand("copy");
    this.setState({ toolTipOpen: true });
  };

  handleBack = () => {
    this.setState({ checked: false, direction: "right" });
  };
  handleExited = () => {
    this.props.onChangePage("MainMenu");
    this.props.history.push("/MainMenu");
  };

  handleDropdown = (e) => {
    //this.setState({log:e});
  };
  handleChangeExpansion = (e) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? e : false });
  };

  handleAlertExit = () => {
    this.setState({ snackBar: false });
  };

  render() {
    const { classes } = this.props;
    const user =
      secureStorage.getItem("account") && secureStorage.getItem("account");

    console.log({ user, showBill: this.state.showBill });
    return (
      <Slide
        direction={this.state.direction}
        in={this.state.checked}
        mountOnEnter
        unmountOnExit
        onExited={this.handleExited}
      >
        <div className={classNames(classes.main, classes.mainRaised)}>
          <div className={classes.titleContainer}>
            <Button
              color="google"
              round
              className={classes.backButton}
              onClick={this.handleBack}
            >
              <ArrowBackIos /> Back
            </Button>
            <div className={classes.pageTitle}>
              My Profile <AccountCircle />
            </div>
            <Clearfix />
          </div>
          <div className={classes.container}>
            <Accordion
              activeColor="behance"
              active={this.state.activeAccordion}
              collapses={[
                // (user && userDm.includes(user.userTypeId)) ||
                //   (user.isDm &&
                //     !this.state.showBill && {
                user &&
                  (userDm.includes(user.userTypeId) ||
                    (user.isDm && !this.state.showBill)) && {
                    title: "TOP UP",
                    content: (
                      <div className={classes.section}>
                        <div>
                          <Ticketing onClose={this.handleBack} />
                        </div>
                      </div>
                    ),
                  },
                user &&
                  userTl.includes(user.userTypeId) &&
                  (!user.isDm || this.state.showBill) && {
                    title: "TAGIHAN",
                    content: (
                      <div className={classes.section}>
                        <div>
                          <Bill onClose={this.handleBack} />
                        </div>
                      </div>
                    ),
                  },
                {
                  title: "Account Detail",
                  content: (
                    <div className={classes.section}>
                      <AccountDetail
                        onProfileImageChange={(image) =>
                          this.props.onProfileImageChange(image)
                        }
                      />
                    </div>
                  ),
                },
                user &&
                  [0].includes(user.userTypeId) && {
                    title: "Tarik Saldo (Coming Soon)",
                    content: (
                      <div className={classes.section}>
                        <Withdrawal />
                      </div>
                    ),
                  },
              ]}
            />
          </div>
        </div>
      </Slide>
    );
  }
}
MyProfile.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(MyProfile);
