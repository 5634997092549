/*eslint-disable*/
import React from "react";

// react components for routing our app without refresh
// import { Link } from "react-router-dom";

// @material-ui/core components
//hook styles class conversion
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";

// @material-ui/icons
import {
  ExitToApp,
  Settings,
  AccountCircle,
  Dashboard,
} from "@material-ui/icons";

// core components
// import CustomDropdown from "components/GCustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/components/gHeaderLinksStyle.js";

import { Redirect } from "react-router-dom";
import defaultImage from "assets/img/faces/profile.png";
import classNames from "classnames";
import Clearfix from "components/Clearfix/Clearfix.js";

import { fnumber } from "helper/Helper";
import Badge from "components/BalanceBadge/Badge";

import axios from "axios";
import Config from "config";
import WsClient from "helper/WebSocket";
import secureStorage from "libs/secureStorage";
import { wsClient } from "helper/dotmatrix";
import Link from "@material-ui/core/Link";
import Resource from "libs/resource";

import { TlUsers } from "helper/userLibs";

class HeaderLinks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      token: null,
      user: null,
      balance: 0,
      billAmount: 0,
      showBill: false,
      profileImage: defaultImage,
      info: "",
    };
  }

  componentDidMount() {
    if (secureStorage.getItem("account")) {
      this.setState({
        token: secureStorage.getItem("token"),
        user: secureStorage.getItem("account"),
      });
    }
    // let client = new WsClient('wss://websocket.payfren.id/')
    // client.connect()
    // wsClient.onMessage(this.incomingMessage)
    window.location.hostname !== "payfren.id" &&
      Resource.getInfo()
        .then((info) => this.setState({ info }))
        .catch((err) => console.log(err));
    this.getBalance();
    this.checkBill();
    this.getBill();
    this.initProfileImage();
    //this.interval = setInterval(() => this.getBalance(), 2000)
  }
  setProfileImage(profileImage) {
    this.setState({ profileImage });
  }
  initProfileImage() {
    const imageData = secureStorage.getItem("profileImage");
    if (imageData) this.setState({ profileImage: imageData.image });
  }
  incomingMessage = (payload) => {
    if (payload.type == "balance") {
      this.setState({ balance: payload.balance });
      secureStorage.setItem("balance", payload.balance);
    }
    //
  };

  getBalance() {
    this.setState({ balance: secureStorage.getItem("balance") });
  }
  getBill() {
    this.setState({ billAmount: secureStorage.getItem("bill") });
  }
  setBalance(balance) {
    this.setState({
      balance: balance ? balance : secureStorage.getItem("balance"),
    });
  }
  setBill(billAmount) {
    this.setState({
      billAmount: billAmount ? billAmount : secureStorage.getItem("bill"),
    });
  }

  checkBill() {
    // this.setState({ isLoading: true });
    axios
      .post(Config.backendUrl + "bill/status")
      .then((res) => {
        const bill = res.data;
        console.log({ bill });
        this.setState({ showBill: bill ? true : false });
      })
      .catch((err) => {
        this.setState({ showBill: false });
        //console.log(err)
      });
  }

  handleLogout = (e) => {
    axios
      .post(Config.backendUrl + "user/logout")
      .then((res) => {
        secureStorage.removeItem("token");
        secureStorage.removeItem("account");
        this.setState({ token: null });
        //window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
  };
  handleChangePage = (page) => {
    this.props.onChangePage(page);
    // this.props.history.push(`/${page}`)
  };

  renderBalanceBill = (user) => {
    const { classes } = this.props;
    return (
      <div className={classes.balance}>
        <Badge className={classes.balanceBadge} color="tumblr">
          {user.UserType.name}
        </Badge>
        <Badge
          className={classes.balanceBadge}
          color={
            user &&
            TlUsers.includes(user.userTypeId) &&
            (!user.isDm || this.state.showBill)
              ? "gray"
              : "pinterest"
          }
        >
          Saldo {fnumber(this.state.balance ? this.state.balance : 0)}
        </Badge>
        {user &&
          TlUsers.includes(user.userTypeId) &&
          (!user.isDm || this.state.showBill) && (
            <Badge
              className={classNames(classes.balanceBadge, classes.balance)}
              color="pinterest"
            >
              Setoran{" "}
              {fnumber(this.state.billAmount ? this.state.billAmount : 0)}
            </Badge>
          )}
      </div>
    );
  };

  renderBalance = (user) => {
    const { classes } = this.props;
    return (
      <div className={classes.balance}>
        <Badge className={classes.balanceBadge} color="tumblr">
          {user.UserType.name}
        </Badge>
        <Badge className={classes.balanceBadge} color={"pinterest"}>
          {fnumber(this.state.balance ? this.state.balance : 0)}
        </Badge>
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    const info = this.state.info;
    const user = secureStorage.getItem("account");
    const imageClasses = classNames(
      classes.imgRaised,
      classes.imgRoundedCircle,
      classes.imgFluid
    );
    const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
    //console.log(this.state.token)
    if (!secureStorage.getItem("token")) {
      return <Redirect to="/" />;
    }

    return (
      <List className={classes.list}>
        <ListItem className={classes.listItem}>
          <div>
            <Link
              component="button"
              onClick={(e) => {
                secureStorage.setItem("ver", "1");
                this.handleChangePage("MyProfile");
              }}
              className={classes.imgContainer}
            >
              <img
                src={this.state.profileImage}
                alt="..."
                className={classes.imgRoundedCircle}
              />
            </Link>
            <div className={classes.profileContainer}>
              <div className={classes.profileName}>
                {user && user.name}
                {user.LoginUsers && ` - ${user.LoginUsers[0].name}`}
              </div>
              {info && ["saminara.com"].includes(info.name)
                ? this.renderBalanceBill(user)
                : this.renderBalance(user)}
              {/* <div className={classes.balance}>
                <Badge className={classes.balanceBadge} color="tumblr">
                  {user.UserType.name}
                </Badge>
                <Badge
                  className={classes.balanceBadge}
                  color={
                    user &&
                    TlUsers.includes(user.userTypeId) &&
                    // (!user.isDm || this.state.billAmount > 0)
                    // !user.isDm
                    (!user.isDm || this.state.showBill)
                      ? "gray"
                      : "pinterest"
                  }
                >
                  Saldo {fnumber(this.state.balance ? this.state.balance : 0)}
                </Badge>
                {user &&
                  TlUsers.includes(user.userTypeId) &&
                  (!user.isDm || this.state.showBill) && (
                    <Badge
                      className={classNames(
                        classes.balanceBadge,
                        classes.balance
                      )}
                      color="pinterest"
                    >
                      Setoran{" "}
                      {fnumber(
                        this.state.billAmount ? this.state.billAmount : 0
                      )}
                    </Badge>
                  )}
              </div> */}
            </div>
          </div>
          <Clearfix />
        </ListItem>

        <ListItem className={classes.listItem}>
          <Button
            id="MyProfile"
            color="transparent"
            className={classes.navLink}
            onClick={(e) => this.handleChangePage("MyProfile")}
            // component={Link}
            // to="/MyProfile"
          >
            <AccountCircle className={classes.icons} /> My Profile
          </Button>
        </ListItem>
        {/* <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            className={classes.navLink}
          >
            <Group className={classes.icons} /> Community
          </Button>
        </ListItem> */}
        <ListItem className={classes.listItem}>
          <Button
            id="Dashboard"
            color="transparent"
            className={classes.navLink}
            onClick={(e) => {
              axios
                .post(Config.backendUrl + "user/createlink")
                .then((res) => {
                  // console.log(res.data)
                  const { hash } = res.data;
                  //window.location.reload();
                  if (
                    navigator.userAgent
                      .toLocaleLowerCase()
                      .indexOf("electron/") !== -1
                  ) {
                    wsClient.send("dashboard", { hash });
                  } else {
                    // const url = 'http://localhost:3001'
                    const url = `https://monitoring.${window.location.hostname}`;
                    const win = window.open(url + "/link/" + hash, "_blank");
                    win.focus();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }}
            // component={Link}
            // to="/Settings"
          >
            <Dashboard className={classes.icons} />
            Dashboard
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            id="Settings"
            color="transparent"
            className={classes.navLink}
            onClick={(e) => this.handleChangePage("Settings")}
            // component={Link}
            // to="/Settings"
          >
            <Settings className={classes.icons} />
            Settings
          </Button>
        </ListItem>
        <ListItem className={classes.listItem}>
          <Button
            color="transparent"
            className={classes.navLink}
            onClick={this.handleLogout}
          >
            <ExitToApp className={classes.icons} />
            Logout
          </Button>
        </ListItem>
      </List>
    );
  }
}
HeaderLinks.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(HeaderLinks);
