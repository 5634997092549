import React from 'react'
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";


import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import SnackbarContent from "components/GSnackbar/SnackbarContent.js";
import CircularProgress from "@material-ui/core/CircularProgress"


import Input from "components/GCustomInput/CustomInputTicket.js";

import settingsStyles from "assets/jss/material-kit-pro-react/views/gSettingsStyle.js";

import { fnumber } from 'helper/Helper'

import axios from 'axios'
import Config from 'config'

const styles = {
	...settingsStyles,
}

class Ticketing extends React.Component{
	constructor(props){
		super()
		this.state = {
			amount : '',
			status : '',

			ticket : '',
			unique : '345',
			uniqueAmount : '2.000.345',

			accountNumber : '',

			bank : [],
			selectedBank : '',

			toolTipOpen : false,
			
			alert : false,
			error:[],
			success :[],

			showModal : false,
			modalTitle : '',
			modalMessage :'',

			buttonDisabled : true,
		}
	}
	componentDidMount(){
		this.initTicket()
		this.initBank()
	}
	initBank(){
		axios.post(Config.backendUrl+ 'topup/bank')
		.then(res=>{
			this.setState({bank:res.data})
		}).catch(err=>{
			console.log(err)
		})
	}
	initTicket(){
		axios.post(Config.backendUrl+ 'topup/ticket/status')
		.then(res => {
			// console.log(res.data)
			const ticket = res.data
			this.setState({
				ticket : res.data,
				status:ticket.statusId,
				uniqueAmount : ticket.uniqueAmount?ticket.uniqueAmount:ticket.finalAmount,
				unique : ticket.unique,
				accountNumber : `${ticket.Bank.name} ${ticket.Bank.accountNumber} a.n. ${ticket.Bank.accountName}`,
			})
			if(res.data.statusId!==2)
				this.setState({buttonDisabled:false})
		}).catch(err=>{
			//console.log(err)
		})
	}

	ticket(){
		this.setState({buttonDisabled:true})

		const payload = {
			amount : this.state.amount,
			bank : this.state.selectedBank,
			ticket : this.state.ticket,
		}
		
		//console.log(payload)
		axios.post(Config.backendUrl+ 'topup/ticket',payload)
		.then(res => {
				// console.log(res.data)
				const ticket = res.data
				this.setState({
					ticket : ticket,
					status:ticket.statusId,
					uniqueAmount : ticket.uniqueAmount?ticket.uniqueAmount:ticket.finalAmount,
					unique : ticket.unique,
					accountNumber : `${ticket.Bank.name} ${ticket.Bank.accountNumber} a.n. ${ticket.Bank.accountName}`,
				})
				if(ticket.statusId!==2)
						this.setState({buttonDisabled:false})
		}).catch(err => {
			if(err.response){
				
			}
			else{
				
			}
		});
	}
	
	handleInput = (e) => {
		//alert(e.id)
		if(this.state.buttonDisabled)
			this.setState({buttonDisabled:false})
		
		const valStr = e.target.value.replace(/\./g, '')
		let value = isNaN(valStr) || valStr === '' ? 0 : parseInt(valStr)
		if (value < 0)
			value = 0
		this.setState({ amount: fnumber(value) })
		if(!value || value<1000 || !this.state.selectedBank)
			this.setState({buttonDisabled:true})
	}
	

	submit = (e) =>{
		if(this.state.status<3){
			this.ticket()
		}else{
			axios.post(Config.backendUrl+ 'topup/ticket/settle',{
				ticket:this.state.ticket
			}).then(res=>{
				this.setState({
					buttonDisabled:true,
					ticket : '',
					amount : '',
					selectedBank : '',
					status:''
				})
				this.props.onClose()
			}).catch(err=>{
				console.log(err)
			})
		}
	}
	
	handleAlertExit = (e) =>{
		this.setState({alert:false})
	}
	handleCloseModal = (e) =>{
		this.setState({showModal:false})
	}
	handleTooltipClose = (e) =>{
		this.setState({toolTipOpen:false})
	}

	handleCopy = (e) => {
		this.input.select();
		document.execCommand('copy');
		this.setState({toolTipOpen:true})
	}
	render(){
		const {classes} = this.props;
		return (
			<GridContainer justify="center">
				<GridItem xs={12} sm={12} md={8} className={classes.grid}>
					<input style={{position:'absolute',left:'-5000px',top:'-5000px'}}  
						defaultValue={this.state.uniqueAmount} 
						ref={(input)=>this.input=input} 
					/>
					{!this.state.status ? 
					<div>
						<FormControl fullWidth className={classes.selectFormControl}>
							<InputLabel
								htmlFor="bank-select"
								className={classes.selectLabel}
							>
								Bank
							</InputLabel>
							<Select
								
								MenuProps={{
									className: classes.selectMenu
								}}
								classes={{
									select: classes.select
								}}
								
								value={this.state.selectedBank}
								onChange={(e)=>{
									this.setState({
										selectedBank:e.target.value,
									})
								}}
								inputProps={{
									name: "bank",
									id: "bank"
								}}
							>
								<MenuItem
									disabled
									classes={{
										root: classes.selectMenuItem
									}}
									value={-1}
								>
									{this.state.bank.length>0?'Bank':<CircularProgress size={30} />}
								</MenuItem>
								{this.state.bank.map((item,index)=>(
									<MenuItem
										key={index}
										classes={{
											root: classes.selectMenuItem,
											selected: classes.selectMenuItemSelected
										}}
										value={item}
									>
										{item.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
						<Input
							labelText="Nominal"
							id="amount"
							formControlProps={{
								fullWidth: true,
							}}
							inputProps={{
								value: this.state.amount,
								onChange : this.handleInput,
								placeholder  : 'Masukkan Nominal',
								type : 'tel',
							}}
						/>
					</div>
					:null}
					{
						this.state.status?
						<div>
							<SnackbarContent
								message={
									this.state.status===1?
									<span style={{color:'#000'}}>
										Tiket ini berlaku sampai dengan 1 jam. 
										Silahkan transfer sebesar <b style={{color:'#FF0000'}}>Nominal Transfer</b>.
										Pastikan buka kembali halaman ini saat mentransfer uang. 
										Untuk mengecek apakah tiket masih berlaku atau sudah expired.
										Tekan tombol <b style={{color:'#FF0000'}}>SELESAI</b> jika sudah melakukan transfer.
										Kemudian sistem akan mengecek dan menambahkan saldo secara otomatis, paling lama 15 menit.
									</span>
									:
									this.state.status===2?
									<span style={{color:'#000'}}>
										Sistem sedang melakukan verifikasi silahkan tunggu. Saldo akan bertambah secara otomatis.
									</span>
									:
									this.state.status===3?
									<span style={{color:'#000'}}>
										Verifikasi berhasil, saldo anda telah bertambah.
									</span>
									:
									this.state.status===6?
									<span style={{color:'#000'}}>
										Ticket telah expired.
									</span>
									:
									this.state.status===7?
									<span style={{color:'#000'}}>
										Sistem tidak dapat memverifikasi ticket. Silahkan kirim foto bukti transfer ke email <b style={{color:'#FF0000'}}>topup@payfren.id</b>
									</span>
									:
									<span style={{color:'#000'}}>
										Ticket dibatalkan.
									</span>
								}
								onExited = {this.handleAlertExit}
								close
								color="warning"
								icon="info_outline"
							/>
						
							<Input
								labelText="Unique Code"
								id="unique"
								formControlProps={{
									style: {width:"250px"}
								}}
								inputProps={{
									value: this.state.unique,
									type : 'tel',
									readOnly : true,
								}}
							/>
							<br />
							<Input
								labelText="Nominal Transfer"
								id="uniqueAmount"
								formControlProps={{
									style: {width:"250px"}
								}}
								inputProps={{
									value: fnumber(this.state.uniqueAmount),
									type : 'tel',
									readOnly : true,
								}}
								style={{float:"left",overflow:"hidden"}}
							/>
							{/* <ClickAwayListener onClickAway={this.handleTooltipClose}>
								<Tooltip
									placement="top"
									PopperProps={{
										disablePortal: true,
									}}
									onClose={this.handleTooltipClose}
									open={this.state.toolTipOpen}
									disableFocusListener={true}
									disableTouchListener={true}
									title="Copied"
								>
									<Button justIcon round color="info" 
										style={{overflow:"hidden",margin:"20px 0 0 10px"}}
										onClick={this.handleCopy}
									>
										<FileCopy />
									</Button>
								</Tooltip>
							</ClickAwayListener> */}
							<div style={{textAlign:'center'}}>
								<Input
									labelText="Rekening"
									id="accountNumber"
									formControlProps={{
										fullWidth : true,
									}}
									inputProps={{
										value: this.state.accountNumber,//this.state.selectedBank ? this.state.selectedBank.accountNumber:'',
										type : 'tel',
										readOnly : true,
									}}
								/>
							</div>
						</div>:null
					}
					<div style={{textAlign:'center'}}>
						<Button round color="instagram" onClick={this.submit} disabled={this.state.buttonDisabled}>
							{!this.state.status?'Request Ticket':this.state.status===1?'Selesai':'Tutup'}
						</Button>
					</div>
				</GridItem>
			</GridContainer>
		)
	}
}

Ticketing.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Ticketing);