import React from "react";
import ReactDOM from "react-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";
import { SnackbarProvider } from "notistack";

import 'semantic-ui-css/semantic.min.css'

import App from "views";

ReactDOM.render(
  <SnackbarProvider>
    <App />
  </SnackbarProvider>
  ,
  document.getElementById("root")
);
