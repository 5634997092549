import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";





import settingsStyles from "assets/jss/material-kit-pro-react/views/gSettingsStyle.js";

// @material-ui/icons
import Icon from "@material-ui/core/Icon";
import InputAdornment from "@material-ui/core/InputAdornment";

// core components


import Input from "components/GCustomInput/CustomInput.js";

import PinInput from 'views/CustomComponents/PinInput'

import axios from 'axios'
import Config from 'config'

import { Modal } from 'semantic-ui-react'
import secureStorage from 'libs/secureStorage'


const styles = {
  ...settingsStyles,
}



class Pin extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      openPassword: false,
      pin: '',
      rePin: '',
      modalTitle: '',
      isSet: false,
      wrongPin: false,
      turnOffPin: false,
      resetPin: false,
      errorPassword: false,
      password: ''
    }
    this.pinRef = React.createRef()
  }

  componentDidMount() {
    // secureStorage.removeItem('username')
    this.setState({ username: secureStorage.getItem('username') })
    this.initPin()
  }
  initPin() {
    const user = secureStorage.getItem('account')
    const username = secureStorage.getItem('username')
    if (user.username !== username)
      secureStorage.removeItem('username')
    axios.post(Config.backendUrl + 'user/pin/isset')
      .then(res => {
        this.setState({ isSet: true })
      }).catch(error => {
      });
  }
  handleChangePass = (e) => {
    this.setState({ [e.target.id]: e.target.value })
  }
  handleAlertExit = () => {
    this.setState({ snackBar: false })
  }
  handlePin = (pin) => {
    if ((this.state.turnOffPin || this.state.isSet) && !this.state.resetPin) {
      this.sendPin({ pin }, 'validate')
    }
    else if (!this.state.pin) {
      this.setState({
        pin,
        modalTitle: 'Konfirmasi PIN'
      })
      this.pinRef.current.clear()
      this.pinRef.current.focus()
    }
    else if (this.state.pin && this.state.pin !== pin) {
      this.setState({
        wrongPin: true,
      })
    }
    // else if(this.state.pin && this.state.rePin && this.state.pin === this.state.rePin){
    else {
      this.sendPin({ pin }, 'set')
    }
  }

  sendPin(payload, url) {
    axios.post(Config.backendUrl + 'user/pin/' + url, payload)
      .then(res => {
        this.setState({
          open: false,
          isSet: true,
        })
        if (this.state.turnOffPin) {
          secureStorage.removeItem('username')
          this.setState({ username: '', turnOffPin: false, pin: '', rePin: '' })
        }
        else if (this.state.resetPin) {
          this.setState({ open: false, pin: '', rePin: '', resetPin: false })
        }
        else {
          const user = secureStorage.getItem('account')
          secureStorage.setItem('username', user.username)
          this.setState({ username: user.username, pin: '', rePin: '' })
        }
      }).catch(error => {
        if (error.response) {
          if (error.response.data) {
            this.setState({
              wrongPin: true,
              modalTitle: error.response.data,
              pin: '',
              rePin: '',
            })
          }
        }
      });
  }
  handleCheckPass = (e) => {
    if (this.state.password) {
      axios.post(Config.backendUrl + 'user/pin/pass', { password: this.state.password })
        .then(res => {
          this.setState({
            open: true,
            modalTitle: 'Masukkan PIN Baru',
            resetPin: true,
            openPassword: false,
            password: '',
          })
        }).catch(error => {
          this.setState({ errorPassword: true })
        });
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} className={classes.grid}>
            {!this.state.username ?
              <Button round color="success" onClick={() => {
                this.setState({ open: true, modalTitle: 'Masukkan PIN' })
                this.pinRef.current && this.pinRef.current.focus()
              }}>Aktifkan pin</Button>
              :
              <Button round color="danger" onClick={() => {
                this.setState({ open: true, modalTitle: 'Masukkan PIN', turnOffPin: true })
                this.pinRef.current && this.pinRef.current.focus()
              }} >Matikan PIN</Button>
            }
          </GridItem>
          {this.state.isSet &&
            <GridItem xs={12} sm={12} md={8} className={classes.grid}>
              <Button round color="instagram" onClick={() => {
                this.setState({ openPassword: true })
              }}>ganti pin</Button>
            </GridItem>}
        </GridContainer>

        <Modal
          // dimmer='blurring'
          open={this.state.open}
          onClose={() => this.setState({ open: false, pin: '', rePin: '' })}
        >
          <Modal.Header>{this.state.modalTitle}</Modal.Header>
          <Modal.Content style={{ textAlign: 'center' }}>
            <PinInput
              ref={this.pinRef}
              length={6}
              initialValue=""
              // secret 
              focus
              onChange={(value, index) => {
                if (value.length < 6) {
                  this.setState({ wrongPin: false })
                }
              }}
              type="numeric"
              inputMode="tel"
              style={{ padding: '10px' }}
              inputStyle={{
                backgroundColor: 'rgb(248, 249, 250)',
                border: '1px solid',
                borderColor: this.state.wrongPin ? 'red' : 'rgb(204, 204, 204)',
                borderRadius: '0.3rem',
                fontSize: '2rem',
                margin: '0.25rem',
                height: '3.5rem',
                outline: 'none',
                textAlign: 'center',
                transitionDuration: '250ms',
                transitionProperty: 'background, color, border, box-shadow, transform',
                width: '3rem',
              }}
              inputFocusStyle={{
                borderColor: this.state.wrongPin ? 'red' : 'rgb(0, 123, 255)',
                outline: 'none',
                transform: 'scale(1.05)',
              }}
              onComplete={(value, index) => {
                this.handlePin(value)
              }}
              autoSelect={true}
              regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button round color="danger" onClick={() => this.setState({ open: false, pin: '', rePin: '' })}>cancel</Button>
          </Modal.Actions>
        </Modal>
        <Modal
          onClose={() => this.setState({ openPassword: false, password: '', resetPin: false })}
          open={this.state.openPassword}
          size='small'
        >
          <Modal.Header>Masukkan Password</Modal.Header>
          <Modal.Content>
            <Input
              error={this.state.errorPassword}
              labelText="Password"
              id="password"
              formControlProps={{
                fullWidth: true
              }}
              inputProps={{
                type: "password",
                value: this.state.password,
                endAdornment: (
                  <InputAdornment position="end">
                    <Icon className={classes.inputIconsColor}>
                      lock_outline
                    </Icon>
                  </InputAdornment>
                ),
                //autoComplete: "off",
                onChange: (e) => this.setState({ password: e.target.value, errorPassword: false }),
                onKeyPress: (e) => e.key === 'Enter' && this.handleCheckPass(),
              }}
            />
          </Modal.Content>
          <Modal.Actions>
            <Button round color="danger" onClick={() => this.setState({ openPassword: false, password: '', resetPin: false })}>cancel</Button>
            <Button round color="instagram" onClick={this.handleCheckPass}>submit</Button>
          </Modal.Actions>
        </Modal>
      </div>
    );
  }
}
Pin.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Pin);