import receipt from "helper/receipt";
// import {ipcRenderer} from 'electron'
import axios from "axios";
// const {ipcRenderer,remote} = require('electron')
import WsClient from "helper/WebSocket";
import posLoader from "helper/pos";
import secureStorage from "libs/secureStorage";
// import secureStorage from 'libraries/secureStorage'
// const incomingMessage = (payload) => {
//   if(payload!=='pong')
//     console.log({payload})
//   //
// }
let wsClient;
if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1) {
  wsClient = new WsClient("ws://localhost:3129/");
  wsClient.connect();
  // wsClient.onMessage(incomingMessage)
}

const dotmatrix = (payload, collective) => {
  let data;
  // console.log({payload,collective})
  const printer = secureStorage.getItem("selectedPrinter");
  if (!collective) {
    if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1) {
      data = receipt(payload);
      const payload = {
        // type : 'print',
        // token : secureStorage.getItem('token'),
        data,
      };

      wsClient.send("print", payload);
    } else {
      data = receipt(payload).join("");
      console.log({
        data,
        printer,
      });
      axios
        .post(
          "http://127.0.0.1:4321/print",
          {
            data,
            printer,
          },
          {
            headers: {
              //'Access-Control-Allow-Headers' : 'Content-Type, Accept, X-Requested-With',
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, PUT, POST, DELETE, PATCH, OPTIONS",
              "Content-Type": "application/json",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  } else {
    data = payload.map((item) => receipt(item).join(""));
    payload = {
      data,
      printer,
      ...collective,
    };

    if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1) {
      wsClient.send("collective", payload);
    } else {
      axios
        .post(
          "http://127.0.0.1:4321/collective",
          { data, printer, ...collective },
          {
            headers: {
              //'Access-Control-Allow-Headers' : 'Content-Type, Accept, X-Requested-With',
              "Access-Control-Allow-Origin": "*",
              "Access-Control-Allow-Methods":
                "GET, PUT, POST, DELETE, PATCH, OPTIONS",
            },
          }
        )
        .then((res) => {
          console.log(res.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }
};
const printList = (payload) => {
  const data = receipt(payload);
  const printer = secureStorage.getItem("selectedPrinter");
  if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1) {
    const payload = {
      data,
    };

    wsClient.send("print", payload);
  } else {
    axios
      .post(
        "http://127.0.0.1:4321/print",
        { data, printer },
        {
          headers: {
            //'Access-Control-Allow-Headers' : 'Content-Type, Accept, X-Requested-With',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, PUT, POST, DELETE, PATCH, OPTIONS",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
const printPos = (payload) => {
  const printer = secureStorage.getItem("selectedPrinter");
  const data = posLoader(payload);
  if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1) {
    const payload = {
      data,
    };

    wsClient.send("print", payload);
  } else {
    axios
      .post(
        "http://127.0.0.1:4321/print",
        { data: data.join(""), printer },
        {
          headers: {
            //'Access-Control-Allow-Headers' : 'Content-Type, Accept, X-Requested-With',
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods":
              "GET, PUT, POST, DELETE, PATCH, OPTIONS",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }
};
export { dotmatrix, wsClient, printList, printPos };
