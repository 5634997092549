import moment from "moment";
import loader from "./loader";
// import _ from 'lodash'
import secureStorage from "libs/secureStorage";
import getinfo from "helper/getinfo";

const receipt = (payload) => {
  const printer = secureStorage.getItem("printer");
  const user = secureStorage.getItem("account");
  const info = secureStorage.getItem("domainInfo");
  const title = `${payload.title.padStart(105, " ")}\r\n`;
  const body = payload.body;

  const leftPage = [
    ["TGL", moment(body.date.value).format("DD/MM/YY HH:mm")],
    [body.phoneNumber.caption, body.phoneNumber.caption],
    [body.name.caption, body.name.value],
    ["PERIOD", body.period.value],
    [
      body.referenceNo.caption,
      body.referenceNo.value.toString().substring(0, 15),
    ],
    ["RP TAG", body.amount.value],
    ["ADMIN", body.admin.value],
    ["BAYAR", body.total.value],
    // ...totalPayment,
  ];

  for (let i = leftPage.length; i < 11; i++) leftPage.push([""]);

  const middlePage = [
    ["TANGGAL", moment(body.date.value).format("DD/MM/YY HH:mm")],
    [body.phoneNumber.caption, body.phoneNumber.caption],
    [body.name.caption, body.name.value],
    ["PERIOD", body.period.value],
    ...getinfo(body.info.value),
  ];

  printer === "dotmatrix"
    ? middlePage.push([`${String.fromCharCode(27)}4Loket : ${user.name}`])
    : middlePage.push([`Loket : ${user.name}`]);
  printer === "dotmatrix"
    ? middlePage.push([
      `Powered by ${payload.appName}${String.fromCharCode(27)}5`,
    ])
    : middlePage.push([`Powered by ${payload.appName}`]);

  for (let i = middlePage.length; i < 11; i++) middlePage.push([""]);

  const rightPage = [
    [
      body.referenceNo.caption,
      body.referenceNo.value.toString().substring(0, 15),
    ],
    ["RP TAG", body.amount.value],
    ["ADMIN", body.admin.value],
    ["BAYAR", body.total.value],
  ];
  for (let i = rightPage.length; i < 11; i++) rightPage.push([""]);

  if (printer === "dotmatrix")
    return loader(title, leftPage, middlePage, rightPage, payload);
  else return { title, leftPage, middlePage, rightPage, payload };
};
export default receipt;
