import axios from 'axios'
import secureStorage from 'libs/secureStorage'
import Config from 'config'

const getInfo = (update)=>{
  return new Promise((resolve,reject)=>{
    const info = secureStorage.getItem('domainInfo')
    if(!info || update){
      
      axios.get(`${Config.Resource}info`)
      .then(res=>{
        const resInfo = res.data
        secureStorage.setItem('domainInfo',resInfo)
        
        resolve(resInfo)
      }).catch(err=>{
        reject(err)
      })
    }
    else resolve(info)
  })
}
const getImage = (update)=>{
  return new Promise((resolve,reject)=>{
    const resource = secureStorage.getItem('domainImage')
    if(!resource || update){
      axios.get(`${Config.Resource}image`,{responseType:'blob'})
      .then(res=>{
        const reader = new FileReader()
        reader.onload =  (e)=> {
          if(e.target.result){
            const resResource = JSON.parse(e.target.result)
            secureStorage.setItem('domainImage',resResource)
            // const img = `data:image/jpeg;base64,${resResource.image}`
            return resolve(`data:image/jpeg;base64,${resResource.image}`)
          }
          else reject('not found')
        }
        reader.readAsText(res.data)
      }).catch(err=>{
        reject(err)
      })
    }
    else resolve(`data:image/jpeg;base64,${resource.image}`)
  })
}
const checkUpdate = (callback)=>{
  const prevInfo = secureStorage.getItem('domainInfo')
  
  if(prevInfo){
    getInfo(true)
    .then(info=>{
      // console.log({prevInfo,info})
      if(prevInfo.revision!==info.revision)callback && callback()
      if(prevInfo.image!==info.image)getImage(true).then(()=>callback && callback()).catch(err=>callback && callback(err))
    })
  }
}
const Resource = {getInfo,getImage,checkUpdate}
export default Resource