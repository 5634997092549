import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/gTransactionStyle.js";

// @material-ui/icons
import Print from "@material-ui/icons/Print";
import Share from "@material-ui/icons/Share";

// core components
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Pay from "@material-ui/icons/Payment";

import Slide from "@material-ui/core/Slide";
import Input from "components/GCustomInput/CustomInput.js";

// Custom select
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Accordion from "components/GAccordion/Accordion.js";

//import NumPad from 'react-numpad' // remove this idiot => .replace(/^(-)?0+(0\.|\d)/,"$1$2")

import axios from "axios";
import Config from "config";
import { fnumber } from "helper/Helper";

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";
import print from "helper/printer";
import modalReceipt from "helper/modalReceipt";
import share from "libs/share";
//import { savePdf} from "libs/pdf"
import secureStorage from "libs/secureStorage";

import Collective from "./Collective";
import moment from "moment";
import _ from "lodash";

import { Close, CloudDownload as Download } from "@material-ui/icons";
/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class Listrik extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      direction: "left",
      expanded: "panel1",
      log: "",

      idPelPre: "",
      nominalPre: "",
      adminPre: "",

      idPelPost: "",
      adminPost: "",

      adminNominalPre: [],
      adminNominalPost: [],
      adminNominalNon: [],

      idPelNon: "",
      adminNon: "",

      selectedProductId: "",

      filename: "",

      buttonDisabled: false,

      isRequestError: false,
      confirmationModal: false,
      isInquiry: true,

      statusPrint: false,
    };
  }

  componentDidMount() {
    this.setState({ checked: true });
    this.loadAdminNominal("Pre", 15);
    this.loadAdminNominal("Post", 14);
    this.loadAdminNominal("Non", 984);

    this.getAdminSelected("Post");
    this.getAdminSelected("Pre");
    this.getAdminSelected("Non");
  }

  getAdminSelected = (type) => {
    const adminSelected = secureStorage.getItem(`adminNominal${type}`);
    if (adminSelected) {
      this.setState({
        [`admin${type}`]: adminSelected.name,
      });
    }
  };

  loadAdminNominal = (type, productId) => {
    axios
      .post(Config.backendUrl + "product/admin", {
        productId,
      })
      .then((res) => {
        this.setState({ ["adminNominal" + type]: res.data });
      })
      .catch((err) => {});
  };

  handleBack = () => {
    this.setState({ checked: false, direction: "right" });
  };
  handleExited = () => {
    this.props.history.push("/MainMenu");
  };

  handleDropdown = (e) => {
    //this.setState({log:e});
  };
  handleChangeExpansion = (e) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? e : false });
  };
  handleNumpad = (target, value) => {
    this.setState({ [target]: "" + value });
  };
  handleSelect = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleSubmitInquiry = (target) => {
    if (target === "prepaid") {
      if (this.state.idPelPre && this.state.nominalPre && this.state.adminPre) {
        const payload = {
          customerCode: this.state.idPelPre,
          nominal: this.state.nominalPre.value,
          adminNominal: this.state.adminPre,
          productId: 15, //prepaid
        };
        this.setState({
          buttonDisabled: true,
          selectedProductId: payload.productId,
        });
        //this.setState({confirmationModal:true})
        //console.log(this.state.isInquired)
        this.request(payload, "inquiry");
      }
    } else if (target === "postpaid") {
      if (this.state.idPelPost && this.state.adminPost) {
        const payload = {
          customerCode: this.state.idPelPost,
          adminNominal: this.state.adminPost,
          productId: 14, //postpaid
        };
        this.setState({
          buttonDisabled: true,
          selectedProductId: payload.productId,
        });
        this.request(payload, "inquiry");
      }
    } else if (target === "nontaglis") {
      if (this.state.idPelNon && this.state.adminNon) {
        const payload = {
          customerCode: this.state.idPelNon,
          adminNominal: this.state.adminNon,
          productId: 984, //nontaglis
        };
        this.setState({
          buttonDisabled: true,
          selectedProductId: payload.productId,
        });
        this.request(payload, "inquiry");
      }
    }
  };
  setModal(data, isError) {
    if (!isError) {
      this.setState({
        statusPrint: data.statusId === 4 ? true : false,
        modalTitle: data.receiptTitle,
        modalBody: data.receiptBody,
        confirmationModal: true,
        filename: `${data.productName}_${data.customerCode}_${moment(
          data.receiptBody.date.value
        ).format("YYMMDDHHmm")}.pdf`,
      });
    } else {
      this.setState({
        statusPrint: data.statusId === 4 ? true : false,
        modalTitle: data.receiptTitle,
        modalBody: data.receiptBody,
        confirmationModal: true,
      });
    }
  }
  handleChange = (e) => {
    this.setState({ [e.target.id]: e.target.value });
  };

  handleModal = (e) => {
    if (e === "confirm") {
      const payload = {
        ...this.state.purchasePayload,
      };
      // this.request(payload, "purchase");
      _.throttle(() => this.request(payload, "purchase"), 1000)();
      //console.log(this.state.purchasePayload)
    } else if (e === "cancel" || e === "close" || e === "error") {
      this.setState({
        statusPrint: false,
        confirmationModal: false,
        isInquired: false,
        isRequestError: false,
        idPelPre: "",
        nominalPre: "",
        // adminPre: "",
        idPelPost: "",
        // adminPost: "",
        idPelNon: "",
        // adminNon: "",
        buttonDisabled: false,
      });
    } else if (e === "print") {
      const title = this.state.modalTitle;
      const body = this.state.modalBody;
      const filename = this.state.filename;
      const productId = this.state.selectedProductId;
      print({ title, body, filename, productId });
      this.setState({
        statusPrint: false,
        confirmationModal: false,
        isInquired: false,
        isRequestError: false,
        idPelPre: "",
        nominalPre: "",
        // adminPre: "",
        idPelPost: "",
        // adminPost: "",
        idPelNon: "",
        // adminNon: "",
        buttonDisabled: false,
      });
    }
  };

  request(payload, urlQuery) {
    this.setState({ confirmationModal: false });
    axios
      .post(Config.backendUrl + "request/" + urlQuery, payload)
      .then((res) => {
        if (res.data) {
          this.setModal(res.data, false);
          if (urlQuery === "inquiry") {
            let data = res.data;
            delete data.receiptTitle;
            delete data.receiptBody;
            this.setState({
              isInquiry: true,
              purchasePayload: data,
            });
          } else if (urlQuery === "purchase") {
            this.setState({
              isInquiry: false,
              purchasePayload: "",
            });
          }
        }
        //alert('test1')
      })
      .catch((err) => {
        console.log(err);
        this.setState({ isRequestError: true });
        // console.log(err.response);
        if (err.response) {
          if (!err.response.data.receiptTitle) {
            if (typeof err.response.data.receiptBody !== "string")
              this.setModal(
                {
                  receiptTitle: "Error",
                  receiptBody: [err.response.statusText],
                },
                true
              );
            else
              this.setModal(
                {
                  receiptTitle: err.response.statusText,
                  receiptBody: [err.response.data],
                },
                true
              );
          } else {
            this.setModal(err.response.data, true);
          }
        } else
          this.setModal(
            { receiptTitle: "Error", receiptBody: ["Network Error"] },
            true
          );
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <Slide
        direction={this.state.direction}
        in={this.state.checked}
        mountOnEnter
        unmountOnExit
        onExited={this.handleExited}
      >
        <div className={classNames(classes.main, classes.mainRaised)}>
          <Button
            color="google"
            round
            className={classes.backButton}
            onClick={this.handleBack}
          >
            <ArrowBackIos /> Back
          </Button>
          <div className={classes.container}>
            <Accordion
              activeColor="behance"
              active={0}
              collapses={[
                {
                  title: "PLN POSTPAID",
                  content: (
                    <div className={classes.section}>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={8}
                          className={classes.grid}
                        >
                          {
                            //IdPel
                          }
                          <Input
                            labelText="ID Pelanggan / Nomor Meter"
                            id="idPelPost"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.idPelPost,
                              onChange: this.handleChange,
                              type: "tel",
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <div>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            className={classes.grid}
                          >
                            {
                              //Postpaid Admin
                            }
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="admin-select"
                                className={classes.selectLabel}
                              >
                                Admin
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={this.state.adminPost}
                                onChange={this.handleSelect}
                                inputProps={{
                                  name: "adminPost",
                                  id: "adminPost",
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                  value={""}
                                >
                                  {this.state.adminNominalPost.length > 0 ? (
                                    "Admin"
                                  ) : (
                                    <CircularProgress size={30} />
                                  )}
                                </MenuItem>
                                {this.state.adminNominalPost.map(
                                  (admin, index) => (
                                    <MenuItem
                                      key={index}
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected:
                                          classes.selectMenuItemSelected,
                                      }}
                                      value={admin.nominal}
                                    >
                                      {fnumber(admin.nominal)}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            className={classes.grid}
                          >
                            {!this.state.buttonDisabled ? (
                              <Button
                                id="postpaid"
                                color="instagram"
                                round
                                onClick={() =>
                                  this.handleSubmitInquiry("postpaid")
                                }
                              >
                                <Pay />
                                Submit
                              </Button>
                            ) : (
                              <CircularProgress color="secondary" />
                            )}
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  ),
                },
                {
                  title: "PLN PREPAID",
                  content: (
                    <div className={classes.section}>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={8}
                          className={classes.grid}
                        >
                          {
                            //IdPel
                          }

                          <Input
                            labelText="ID PELANGGAN / NO METER"
                            id="idPelPre"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              placeholder: "Masukkan IDPEL / NO METER",
                              value: this.state.idPelPre,
                              onChange: this.handleChange,
                              type: "tel",
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <div>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            className={classes.grid}
                          >
                            {
                              //Prepaid Nominal
                            }
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="nominal-select"
                                className={classes.selectLabel}
                              >
                                Nominal
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={this.state.nominalPre}
                                onChange={this.handleSelect}
                                inputProps={{
                                  name: "nominalPre",
                                  id: "nominalPre",
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                  value={""}
                                >
                                  Nominal
                                </MenuItem>
                                {Nominal.map((nominal, index) => (
                                  <MenuItem
                                    key={index}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={nominal}
                                  >
                                    {nominal.display}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            className={classes.grid}
                          >
                            {
                              //Prepaid Admin
                            }
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="admin-select"
                                className={classes.selectLabel}
                              >
                                Admin
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={this.state.adminPre}
                                onChange={this.handleSelect}
                                inputProps={{
                                  name: "adminPre",
                                  id: "adminPre",
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                  value={""}
                                >
                                  {this.state.adminNominalPre.length > 0 ? (
                                    "Admin"
                                  ) : (
                                    <CircularProgress size={30} />
                                  )}
                                </MenuItem>
                                {this.state.adminNominalPre.map(
                                  (admin, index) => (
                                    <MenuItem
                                      key={index}
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected:
                                          classes.selectMenuItemSelected,
                                      }}
                                      value={admin.nominal}
                                    >
                                      {fnumber(admin.nominal)}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            className={classes.grid}
                          >
                            {!this.state.buttonDisabled ? (
                              <Button
                                id="prepaid"
                                color="instagram"
                                round
                                onClick={() =>
                                  this.handleSubmitInquiry("prepaid")
                                }
                              >
                                <Pay />
                                Submit
                              </Button>
                            ) : (
                              <CircularProgress color="secondary" />
                            )}
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  ),
                },
                {
                  title: "NON TAGLIS",
                  content: (
                    <div className={classes.section}>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={8}
                          className={classes.grid}
                        >
                          {
                            //IdPel
                          }
                          <Input
                            labelText="ID Pelanggan / Nomor Meter"
                            id="idPelNon"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              value: this.state.idPelNon,
                              onChange: this.handleChange,
                              type: "tel",
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <div>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            className={classes.grid}
                          >
                            {
                              //Nontaglist Admin
                            }
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="admin-select"
                                className={classes.selectLabel}
                              >
                                Admin
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={this.state.adminNon}
                                onChange={this.handleSelect}
                                inputProps={{
                                  name: "adminNon",
                                  id: "adminNon",
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                  value={""}
                                >
                                  {this.state.adminNominalNon.length > 0 ? (
                                    "Admin"
                                  ) : (
                                    <CircularProgress size={30} />
                                  )}
                                </MenuItem>
                                {this.state.adminNominalNon.map(
                                  (admin, index) => (
                                    <MenuItem
                                      key={index}
                                      classes={{
                                        root: classes.selectMenuItem,
                                        selected:
                                          classes.selectMenuItemSelected,
                                      }}
                                      value={admin.nominal}
                                    >
                                      {fnumber(admin.nominal)}
                                    </MenuItem>
                                  )
                                )}
                              </Select>
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            className={classes.grid}
                          >
                            {!this.state.buttonDisabled ? (
                              <Button
                                id="nontaglis"
                                color="instagram"
                                round
                                onClick={() =>
                                  this.handleSubmitInquiry("nontaglis")
                                }
                              >
                                <Pay />
                                Submit
                              </Button>
                            ) : (
                              <CircularProgress color="secondary" />
                            )}
                          </GridItem>
                        </GridContainer>
                      </div>
                    </div>
                  ),
                },
                {
                  title: "COLLECTIVE",
                  content: <Collective />,
                },
              ]}
            />
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.confirmationModal}
              TransitionComponent={Transition}
              keepMounted
              //onClose={() => this.setState({successModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {this.state.modalBody && modalReceipt(this.state.modalBody)}
              </DialogContent>
              {this.state.isRequestError ? (
                <DialogActions className={classes.modalFooter}>
                  <Button
                    round
                    onClick={() => this.handleModal("error")}
                    color="danger"
                  >
                    Ok
                  </Button>
                </DialogActions>
              ) : this.state.isInquiry ? (
                <DialogActions className={classes.modalFooter}>
                  <Button
                    round
                    onClick={() => this.handleModal("cancel")}
                    color="danger"
                  >
                    Cancel
                  </Button>
                  <Button
                    round
                    onClick={() => this.handleModal("confirm")}
                    color="instagram"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              ) : (
                <DialogActions className={classes.modalFooter}>
                  <Button
                    round
                    onClick={() => this.handleModal("close")}
                    color="danger"
                  >
                    <Close />
                    Close
                  </Button>
                  {this.state.statusPrint && (
                    <Button
                      round
                      onClick={() => this.handleModal("print")}
                      color="instagram"
                    >
                      <Print />
                      Print
                    </Button>
                  )}
                  {this.state.modalBody && this.state.statusPrint && (
                    <Button
                      round
                      justIcon
                      onClick={() => {
                        const { savePdf } = require("libs/pdf");
                        savePdf(
                          {
                            title: this.state.modalTitle,
                            body: this.state.modalBody,
                            filename: this.state.filename,
                            productId: this.state.selectedProductId,
                          },
                          "PdfReceipt"
                        );
                      }}
                      color="success"
                    >
                      <Download />
                    </Button>
                  )}
                  {navigator.canShare &&
                    this.state.modalBody &&
                    this.state.statusPrint && (
                      <Button
                        round
                        justIcon
                        onClick={async () =>
                          share({
                            title: this.state.modalTitle,
                            body: this.state.modalBody,
                            filename: this.state.filename,
                            productId: this.state.selectedProductId,
                          })
                        }
                        color="info"
                      >
                        <Share />
                      </Button>
                    )}
                </DialogActions>
              )}
            </Dialog>
          </div>
        </div>
      </Slide>
    );
  }
}
Listrik.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Listrik);

const Nominal = [
  { display: "20.000", value: 20000 },
  { display: "50.000", value: 50000 },
  { display: "100.000", value: 100000 },
  { display: "200.000", value: 200000 },
  { display: "500.000", value: 500000 },
  { display: "1.000.000", value: 1000000 },
];
