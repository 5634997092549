import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// @material-ui/icons
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Print from "@material-ui/icons/Print";
import Share from "@material-ui/icons/Share";
import Pay from "@material-ui/icons/Payment";

// core components
import Accordion from "components/GAccordion/Accordion.js";
import Slide from "@material-ui/core/Slide";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Input from "components/GCustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-pro-react/views/gTransactionStyle.js";

// Modal
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

// Custom select
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import axios from "axios";
import Config from "config";
import print from "helper/printer";

import modalReceipt from "helper/modalReceipt";
// import print from "helper/printer"

import share from "libs/share";
//import { savePdf} from "libs/pdf"

import moment from "moment";

import { Close, CloudDownload as Download } from "@material-ui/icons";

/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class Tv extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      direction: "left",
      expanded: "panel1",
      confirmationModal: false,

      billers: [],
      selectedBiller: "",

      customerCode: "",

      selectedProduct: "",
      products: [],

      filename: "",
    };
  }

  componentDidMount() {
    this.setState({ checked: true });
    //this.loadProduct()
    this.loadBiller();
  }

  loadBiller = () => {
    axios
      .post(Config.backendUrl + "product/dropdown", {
        productCategoryId: 11,
      })
      .then((res) => {
        //console.log(res.data)
        this.setState({ products: res.data });
      })
      .catch((err) => {});
  };

  handleBack = () => {
    this.setState({ checked: false, direction: "right" });
  };
  handleExited = () => {
    this.props.history.push("/MainMenu");
  };

  handleChangeExpansion = (e) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? e : false });
  };

  handleSubmitInquiry = () => {
    if (this.state.customerCode !== "" && this.state.selectedProduct !== "") {
      const payload = {
        customerCode: this.state.customerCode,

        adminNominal: this.state.selectedProduct.AdminNominals[0].nominal,
        productId: this.state.selectedProduct.id, //prepaid
      };
      // console.log(payload)
      this.setState({ buttonDisabled: true });
      //this.setState({confirmationModal:true})
      //console.log(payload)
      this.request(payload, "inquiry");
    }
  };

  setModal(data, isError) {
    if (!isError) {
      this.setState({
        modalTitle: data.receiptTitle,
        modalBody: data.receiptBody,
        confirmationModal: true,
        filename: `${data.productName}_${data.customerCode}_${moment(
          data.receiptBody.date.value
        ).format("YYMMDDHHmm")}.pdf`,
      });
    } else {
      this.setState({
        modalTitle: data.receiptTitle,
        modalBody: data.receiptBody,
        confirmationModal: true,
      });
    }
  }

  handleModal = (e) => {
    if (e === "confirm") {
      const payload = {
        ...this.state.purchasePayload,
      };
      this.request(payload, "purchase");
      //console.log(this.state.purchasePayload)
    } else if (e === "cancel" || e === "close" || e === "error") {
      this.setState({
        confirmationModal: false,
        isInquired: false,
        isRequestError: false,
        customerCode: "",
        selectedProduct: "",

        buttonDisabled: false,
      });
    } else if (e === "print") {
      const title = this.state.modalTitle;
      const body = this.state.modalBody;
      const filename = this.state.filename;
      print({
        title,
        body,
        filename,
        ...this.state.products[this.state.productDropdown],
      });
      this.setState({
        confirmationModal: false,
        isInquired: false,
        isRequestError: false,
        customerCode: "",
        selectedProduct: "",

        buttonDisabled: false,
      });
    }
  };

  request(payload, urlQuery) {
    this.setState({ confirmationModal: false });
    axios
      .post(Config.backendUrl + "request/" + urlQuery, payload)
      .then((res) => {
        if (res.data) {
          //console.log(res.data)
          this.setModal(res.data, false);
          if (urlQuery === "inquiry") {
            let data = res.data;
            delete data.receiptTitle;
            delete data.receiptBody;
            this.setState({
              isInquiry: true,
              purchasePayload: data,
            });
          } else if (urlQuery === "purchase") {
            this.setState({
              isInquiry: false,
              purchasePayload: "",
            });
          }
        }
        //alert('test1')
      })
      .catch((err) => {
        this.setState({ isRequestError: true });
        console.log(err.response);
        if (err.response) {
          if (!err.response.data.receiptTitle)
            this.setModal(
              {
                receiptTitle: err.response.statusText,
                receiptBody: [err.response.data],
              },
              true
            );
          else this.setModal(err.response.data, true);
        } else
          this.setModal(
            { receiptTitle: "Error", receiptBody: ["Network Error"] },
            true
          );
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <Slide
        direction={this.state.direction}
        in={this.state.checked}
        mountOnEnter
        unmountOnExit
        onExited={this.handleExited}
      >
        <div className={classNames(classes.main, classes.mainRaised)}>
          <Button
            color="google"
            round
            className={classes.backButton}
            onClick={this.handleBack}
          >
            <ArrowBackIos /> Back
          </Button>
          <div className={classes.container}>
            <Accordion
              activeColor="behance"
              active={0}
              collapses={[
                {
                  title: "TV Kabel",
                  content: (
                    <div className={classes.section}>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={6}
                          className={classes.grid}
                        >
                          <Input
                            labelText="IDPEL"
                            id="customercode"
                            inputProps={{
                              placeholder: "Masukkan ID Pelanggan",
                              value: this.state.customerCode,
                              onChange: (e) => {
                                this.setState({ customerCode: e.target.value });
                              },
                              type: "tel",
                            }}
                            formControlProps={{
                              fullWidth: true,
                            }}
                          />
                        </GridItem>
                      </GridContainer>
                      <div>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={6}
                            className={classes.grid}
                          >
                            <FormControl
                              fullWidth
                              className={classes.selectFormControl}
                            >
                              <InputLabel
                                htmlFor="biller-select"
                                className={classes.selectLabel}
                              >
                                Operator
                              </InputLabel>
                              <Select
                                MenuProps={{
                                  className: classes.selectMenu,
                                }}
                                classes={{
                                  select: classes.select,
                                }}
                                value={this.state.selectedProduct}
                                onChange={(e) =>
                                  this.setState({
                                    selectedProduct: e.target.value,
                                  })
                                }
                                inputProps={{
                                  name: "productPost",
                                  id: "productPost",
                                }}
                              >
                                <MenuItem
                                  disabled
                                  classes={{
                                    root: classes.selectMenuItem,
                                  }}
                                  value={""}
                                >
                                  {this.state.products.length > 0 ? (
                                    "Operator"
                                  ) : (
                                    <CircularProgress size={30} />
                                  )}
                                </MenuItem>
                                {this.state.products.map((product, index) => (
                                  <MenuItem
                                    key={index}
                                    classes={{
                                      root: classes.selectMenuItem,
                                      selected: classes.selectMenuItemSelected,
                                    }}
                                    value={product}
                                  >
                                    {product.name.toString().toUpperCase()}
                                  </MenuItem>
                                ))}
                              </Select>
                            </FormControl>
                          </GridItem>
                        </GridContainer>
                      </div>
                      <div>
                        <GridContainer justify="center">
                          <GridItem
                            xs={12}
                            sm={12}
                            md={4}
                            className={classes.grid}
                          >
                            {!this.state.buttonDisabled ? (
                              <Button
                                id="pulsaPost"
                                color="instagram"
                                round
                                onClick={() => this.handleSubmitInquiry()}
                              >
                                <Pay />
                                Submit
                              </Button>
                            ) : (
                              <CircularProgress color="secondary" />
                            )}
                          </GridItem>
                        </GridContainer>
                      </div>
                      <Dialog
                        classes={{
                          root: classes.modalRoot,
                          paper: classes.modal,
                        }}
                        open={this.state.confirmationModal}
                        TransitionComponent={Transition}
                        keepMounted
                        //onClose={() => this.setState({successModal: false})}
                        aria-labelledby="classic-modal-slide-title"
                        aria-describedby="classic-modal-slide-description"
                      >
                        <DialogTitle
                          id="classic-modal-slide-title"
                          disableTypography
                          className={classes.modalHeader}
                        >
                          <h4 className={classes.modalTitle}>
                            {this.state.modalTitle}
                          </h4>
                        </DialogTitle>
                        <DialogContent
                          id="classic-modal-slide-description"
                          className={classes.modalBody}
                        >
                          {this.state.modalBody &&
                            modalReceipt(this.state.modalBody)}
                        </DialogContent>
                        {this.state.isRequestError ? (
                          <DialogActions className={classes.modalFooter}>
                            <Button
                              round
                              onClick={() => this.handleModal("error")}
                              color="danger"
                            >
                              Ok
                            </Button>
                          </DialogActions>
                        ) : this.state.isInquiry ? (
                          <DialogActions className={classes.modalFooter}>
                            <Button
                              round
                              onClick={() => this.handleModal("cancel")}
                              color="danger"
                            >
                              Cancel
                            </Button>
                            <Button
                              round
                              onClick={() => this.handleModal("confirm")}
                              color="instagram"
                            >
                              Confirm
                            </Button>
                          </DialogActions>
                        ) : (
                          <DialogActions className={classes.modalFooter}>
                            <Button
                              round
                              onClick={() => this.handleModal("close")}
                              color="danger"
                            >
                              <Close />
                              Close
                            </Button>
                            <Button
                              round
                              onClick={() => this.handleModal("print")}
                              color="instagram"
                            >
                              <Print />
                              Print
                            </Button>
                            {this.state.modalBody && (
                              <Button
                                round
                                justIcon
                                onClick={() => {
                                  const { savePdf } = require("libs/pdf");
                                  savePdf(
                                    {
                                      title: this.state.modalTitle,
                                      body: this.state.modalBody,
                                      filename: this.state.filename,
                                    },
                                    "PdfReceipt"
                                  );
                                }}
                                color="success"
                              >
                                <Download />
                              </Button>
                            )}
                            {navigator.canShare && this.state.modalBody && (
                              <Button
                                round
                                justIcon
                                onClick={async () =>
                                  share({
                                    title: this.state.modalTitle,
                                    body: this.state.modalBody,
                                    filename: this.state.filename,
                                  })
                                }
                                color="info"
                              >
                                <Share />
                              </Button>
                            )}
                          </DialogActions>
                        )}
                      </Dialog>
                    </div>
                  ),
                },
              ]}
            />
          </div>
        </div>
      </Slide>
    );
  }
}
Tv.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Tv);
