import moment from "moment";
import { fnumber } from "helper/Helper";
import _ from "lodash";
// const EscPosEncoder = require('esc-pos-encoder');
// const encoder = new EscPosEncoder()
import { secureStorage } from "libs";
import receiptToArray from "./receiptToArray";

const posLoader = (payload) => {
  const account = secureStorage.getItem("account");
  const info = secureStorage.getItem("domainInfo");
  const title = payload.title;
  // const body = payload.body;
  const body = !Array.isArray(payload.body)
    ? receiptToArray(payload.body)
    : payload.body;

  const loader = body.map((item) => {
    const line = Array.isArray(item)
      ? item.length > 1
        ? item[0].toString().padEnd(15, " ") +
          ": " +
          (typeof item[1] !== "number"
            ? item[0] === "TGL TRANSAKSI"
              ? moment(item[1])
                  .format("DD MMM YYYY HH:mm:ss")
                  .toUpperCase()
              : item[1]
            : "Rp. " + fnumber(item[1]).padStart(9, " "))
        : item[0]
      : item;
    return line + "\n";
  });
  const feed = _.times(7, (n) => String.fromCharCode(10));

  // const receipt = encoder.initialize()
  //   .newline('center').bold(true)
  //   .text(title)
  //   .bold(false).align('left').newline().newline()
  //   .line(loader.join('\n'))
  //   .newline()
  //   .newline()
  //   .cut('full').encode()
  // console.log(receipt)
  // const final = [
  //   receipt,
  //   ...feed,
  // ]
  const final = [
    String.fromCharCode(29) +
      "!" +
      String.fromCharCode(16) +
      // +String.fromCharCode(27)+ 'E1'
      title +
      // +String.fromCharCode(27)+'E'
      String.fromCharCode(29) +
      "!" +
      String.fromCharCode(0),
    // ' '+title,
    // ,
    " \n\n",
    ...loader,
    "\n",
    `${String.fromCharCode(27)}4Loket : ${account.name}\n`,
    `Powered by ${info.name}${String.fromCharCode(27)}5`,
    ...feed,
    String.fromCharCode(27) + "i",
  ];
  return final;
};
export default posLoader;
