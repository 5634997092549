import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// @material-ui/icons
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Print from "@material-ui/icons/Print";
import Share from "@material-ui/icons/Share";
import Pay from "@material-ui/icons/Payment";

// core components
import Accordion from "components/GAccordion/Accordion.js";
import Slide from "@material-ui/core/Slide";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Input from "components/GCustomInput/CustomInput.js";

import styles from "assets/jss/material-kit-pro-react/views/gTransactionStyle.js";

// Modal
import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

// Custom select
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import axios from "axios";
import Config from "config";
import print from "helper/printer";

import modalReceipt from "helper/modalReceipt";
// import print from "helper/printer"

import share from "libs/share";
//import { savePdf} from "libs/pdf"

import Keys from "libs/keycodes";
import moment from "moment";

import { Close, CloudDownload as Download } from "@material-ui/icons";

import { Table, Dropdown } from "semantic-ui-react";

/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class MultiPayment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      direction: "left",
      expanded: "panel1",
      confirmationModal: false,

      billers: [],
      selectedBiller: "",

      customerCode: "",

      selectedProduct: "",
      products: [],

      filename: "",
    };
  }

  componentDidMount() {
    this.setState({ checked: true });
    //this.loadProduct()
    // this.loadBiller()
    document.addEventListener("keydown", this.handleKeydown);
  }
  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeydown);
  }
  handleKeydown = (e) => {
    // console.log(e.keyCode)
    switch (e.keyCode) {
      case Keys.B:
        this.handleBack();
    }
  };

  loadBiller = () => {
    axios
      .post(Config.backendUrl + "product/dropdown", {
        productCategoryId: 11,
      })
      .then((res) => {
        //console.log(res.data)
        this.setState({ products: res.data });
      })
      .catch((err) => {});
  };

  handleBack = () => {
    this.setState({ checked: false, direction: "right" });
  };
  handleExited = () => {
    this.props.history.push("/MainMenu");
  };

  handleChangeExpansion = (e) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? e : false });
  };

  render() {
    const { classes } = this.props;

    return (
      <Slide
        direction={this.state.direction}
        in={this.state.checked}
        mountOnEnter
        unmountOnExit
        onExited={this.handleExited}
      >
        <div className={classNames(classes.main, classes.mainRaised)}>
          <Button
            color="google"
            round
            className={classes.backButton}
            onClick={this.handleBack}
          >
            <ArrowBackIos /> Back
          </Button>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.grid}
              ></GridItem>
              <GridItem xs={12} sm={12} md={12} className={classes.grid}>
                <Table celled inverted selectable>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>#</Table.HeaderCell>
                      <Table.HeaderCell>ITEM</Table.HeaderCell>
                      <Table.HeaderCell>QTY</Table.HeaderCell>
                      <Table.HeaderCell>NOMINAL</Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>1</Table.Cell>
                      <Table.Cell>John</Table.Cell>
                      <Table.Cell>Approved</Table.Cell>
                      <Table.Cell textAlign="right">None</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>2</Table.Cell>
                      <Table.Cell>Jamie</Table.Cell>
                      <Table.Cell>Approved</Table.Cell>
                      <Table.Cell textAlign="right">Requires call</Table.Cell>
                    </Table.Row>
                    <Table.Row>
                      <Table.Cell>3</Table.Cell>
                      <Table.Cell>Jill</Table.Cell>
                      <Table.Cell>Denied</Table.Cell>
                      <Table.Cell textAlign="right">None</Table.Cell>
                    </Table.Row>
                  </Table.Body>
                </Table>
              </GridItem>
              <GridItem
                xs={12}
                sm={12}
                md={12}
                className={classes.grid}
              ></GridItem>
            </GridContainer>
          </div>
        </div>
      </Slide>
    );
  }
}
MultiPayment.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(MultiPayment);
