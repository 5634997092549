import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-pro-react/views/gTransactionStyle.js";

// @material-ui/icons
import Print from "@material-ui/icons/Print";
import Share from "@material-ui/icons/Share";

// core components
import ArrowBackIos from "@material-ui/icons/ArrowBackIos";
import Pay from "@material-ui/icons/Payment";

import Slide from "@material-ui/core/Slide";
import Input from "components/GCustomInput/CustomInput.js";

// Custom select
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Accordion from "components/GAccordion/Accordion.js";

import axios from "axios";
import Config from "config";

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CircularProgress from "@material-ui/core/CircularProgress";

import modalReceipt from "helper/modalReceipt";
import print from "helper/printer";
import { fnumber } from "helper/Helper";

import share from "libs/share";
//import { savePdf} from "libs/pdf"

import Denom from "views/Transaction/Emoney/Denom";

import moment from "moment";

import { Close, CloudDownload as Download } from "@material-ui/icons";
/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class Emoney extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      checked: false,
      direction: "left",
      expanded: "panel1",
      log: "",

      productDropdown: "",
      products: [],
      customerCode: "",

      nominal: "",

      buttonDisabled: false,

      isRequestError: false,
      confirmationModal: false,
      isInquiry: true,

      filename: "",

      payload: "",
    };
  }

  componentDidMount() {
    this.setState({ checked: true });
    this.loadProduct();
  }

  loadProduct = () => {
    axios
      .post(Config.backendUrl + "product/dropdown", {
        productCategoryId: 2,
      })
      .then((res) => {
        this.setState({ products: res.data });
      })
      .catch((err) => {});
  };

  handleBack = () => {
    this.setState({ checked: false, direction: "right" });
  };
  handleExited = () => {
    this.props.history.push("/MainMenu");
  };

  handleDropdown = (e) => {
    //this.setState({log:e});
  };
  handleChangeExpansion = (e) => (event, isExpanded) => {
    this.setState({ expanded: isExpanded ? e : false });
  };

  handleSelect = (e) => {
    this.setState({
      productDropdown: e.target.value,
    });
  };
  handleSubmitInquiry = (target) => {
    // console.log(target +'-'+ this.state.nominal+' ' + this.state.customerCode+' '
    // +this.state.products[this.state.productDropdown].AdminNominals[0].nominal+' '
    // + this.state.products[this.state.productDropdown].id)

    if (target === "emoney") {
      if (
        this.state.nominal !== undefined &&
        this.state.productDropdown !== ""
      ) {
        const payload = {
          customerCode: this.state.customerCode,
          nominal: parseInt(this.state.nominal),
          adminNominal: this.state.products[this.state.productDropdown]
            .AdminNominals[0].nominal,
          productId: this.state.products[this.state.productDropdown].id, //prepaid
        };
        this.setState({
          buttonDisabled: true,
          payload,
          confirmationModal: true,
          isInquiry: true,
          modalTitle: `PEMBELIAN ${this.state.products[this.state.productDropdown].name}`,
          modalBody: [
            ["NO HP", payload.customerCode],
            ["NOMINAl", payload.nominal],
            ["ADMIN", payload.adminNominal],
            ["RP BAYAR", payload.nominal + payload.adminNominal],
          ],
        });
        //this.setState({confirmationModal:true})

        // this.request(payload,'purchase')
      }
    }
  };
  setModal(data, isError) {
    if (!isError) {
      this.setState({
        modalTitle: data.receiptTitle,
        modalBody: data.receiptBody,
        confirmationModal: true,
        filename: `${data.productName}_${data.customerCode}_${moment(
          data.receiptBody.date.value
        ).format("YYMMDDHHmm")}.pdf`,
      });
    } else {
      this.setState({
        modalTitle: data.receiptTitle,
        modalBody: data.receiptBody,
        confirmationModal: true,
      });
    }
  }
  handleChange = (e) => {
    if (e.target.id === "nominal") {
      const valStr = e.target.value.replace(/\./g, "");
      let value = isNaN(valStr) || valStr === "" ? 0 : parseInt(valStr);
      if (value < 0) value = 0;
      this.setState({ [e.target.id]: value });
    } else this.setState({ [e.target.id]: e.target.value });
  };

  handleModal = (e) => {
    if (e === "confirm") {
      this.request(this.state.payload, "purchase");
    } else if (e === "cancel" || e === "close" || e === "error") {
      this.setState({
        confirmationModal: false,
        isInquiry: false,
        isRequestError: false,
        nominal: "",
        productDropdown: "",
        customerCode: "",
        payload: "",
        buttonDisabled: false,
      });
    } else if (e === "print") {
      const title = this.state.modalTitle;
      const body = this.state.modalBody;
      const filename = this.state.filename;
      print({
        title,
        body,
        filename,
        ...this.state.products[this.state.productDropdown],
      });
      this.setState({
        confirmationModal: false,
        isInquired: false,
        isRequestError: false,
        nominal: "",
        productDropdown: "",
        customerCode: "",

        buttonDisabled: false,
      });
    }
  };

  request(payload, urlQuery) {
    this.setState({ confirmationModal: false });
    axios
      .post(Config.backendUrl + "request/" + urlQuery, payload)
      .then((res) => {
        if (res.data) {
          //console.log(res.data)
          this.setModal(res.data, false);
          if (urlQuery === "inquiry") {
            let data = res.data;
            delete data.receiptTitle;
            delete data.receiptBody;
            this.setState({
              isInquiry: true,
              purchasePayload: data,
            });
          } else if (urlQuery === "purchase") {
            this.setState({
              isInquiry: false,
              purchasePayload: "",
            });
          }
        }
        //alert('test1')
      })
      .catch((err) => {
        this.setState({ isRequestError: true });
        console.log(err.response);
        if (err.response) {
          if (!err.response.data.receiptTitle)
            this.setModal(
              {
                receiptTitle: err.response.statusText,
                receiptBody: [err.response.data],
              },
              true
            );
          else this.setModal(err.response.data, true);
        } else
          this.setModal(
            { receiptTitle: "Error", receiptBody: ["Network Error"] },
            true
          );
      });
  }

  render() {
    const { classes } = this.props;

    return (
      <Slide
        direction={this.state.direction}
        in={this.state.checked}
        mountOnEnter
        unmountOnExit
        onExited={this.handleExited}
      >
        <div className={classNames(classes.main, classes.mainRaised)}>
          <Button
            color="google"
            round
            className={classes.backButton}
            onClick={this.handleBack}
          >
            <ArrowBackIos /> Back
          </Button>
          <div className={classes.container}>
            <Accordion
              activeColor="behance"
              active={0}
              collapses={[
                {
                  title: "E-WALLET (DENOM)",
                  content: <Denom />,
                },
                {
                  title: "E-WALLET",
                  content: (
                    <div className={classes.section}>
                      <GridContainer justify="center">
                        <GridItem
                          xs={12}
                          sm={12}
                          md={7}
                          style={{ marginTop: "-7px" }}
                        >
                          <FormControl
                            fullWidth
                            className={classes.selectFormControl}
                          >
                            <InputLabel
                              htmlFor="product-select"
                              className={classes.selectLabel}
                            >
                              Item
                            </InputLabel>
                            <Select
                              MenuProps={{
                                className: classes.selectMenu,
                              }}
                              classes={{
                                select: classes.select,
                              }}
                              value={this.state.productDropdown}
                              onChange={this.handleSelect}
                              inputProps={{
                                name: "productDropdown",
                                id: "productDropdown",
                              }}
                            >
                              <MenuItem
                                disabled
                                classes={{
                                  root: classes.selectMenuItem,
                                }}
                                value={""}
                              >
                                Item
                              </MenuItem>
                              {this.state.products.map((product, index) => (
                                <MenuItem
                                  key={index}
                                  classes={{
                                    root: classes.selectMenuItem,
                                    selected: classes.selectMenuItemSelected,
                                  }}
                                  value={index}
                                >
                                  {product.name}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </GridItem>
                        <GridItem xs={12} sm={12} md={7}>
                          {
                            //IdPel
                          }
                          <Input
                            labelText="No Pelanggan"
                            id="customerCode"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              placeholder: "Masukkan No Pelanggan",
                              value: this.state.customerCode,
                              onChange: this.handleChange,
                              type: "tel",
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={7}>
                          {
                            //IdPel
                          }
                          <Input
                            labelText="Nominal"
                            id="nominal"
                            formControlProps={{
                              fullWidth: true,
                            }}
                            inputProps={{
                              placeholder: "Masukkan Nominal",
                              value: fnumber(this.state.nominal),
                              onChange: this.handleChange,
                              type: "tel",
                            }}
                          />
                        </GridItem>
                        <GridItem xs={12} sm={12} md={12}>
                          {!this.state.buttonDisabled ? (
                            <Button
                              id="prepaid"
                              color="instagram"
                              round
                              onClick={() => this.handleSubmitInquiry("emoney")}
                            >
                              <Pay />
                              Submit
                            </Button>
                          ) : (
                            <CircularProgress color="secondary" />
                          )}
                        </GridItem>
                      </GridContainer>
                    </div>
                  ),
                },
              ]}
            />
            <Dialog
              classes={{
                root: classes.modalRoot,
                paper: classes.modal,
              }}
              open={this.state.confirmationModal}
              TransitionComponent={Transition}
              keepMounted
              //onClose={() => this.setState({successModal: false})}
              aria-labelledby="classic-modal-slide-title"
              aria-describedby="classic-modal-slide-description"
            >
              <DialogTitle
                id="classic-modal-slide-title"
                disableTypography
                className={classes.modalHeader}
              >
                <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
              </DialogTitle>
              <DialogContent
                id="classic-modal-slide-description"
                className={classes.modalBody}
              >
                {this.state.modalBody && modalReceipt(this.state.modalBody)}
              </DialogContent>
              {this.state.isRequestError ? (
                <DialogActions className={classes.modalFooter}>
                  <Button
                    round
                    onClick={() => this.handleModal("error")}
                    color="danger"
                  >
                    Ok
                  </Button>
                </DialogActions>
              ) : this.state.isInquiry ? (
                <DialogActions className={classes.modalFooter}>
                  <Button
                    round
                    onClick={() => this.handleModal("cancel")}
                    color="danger"
                  >
                    Cancel
                  </Button>
                  <Button
                    round
                    onClick={() => this.handleModal("confirm")}
                    color="instagram"
                  >
                    Confirm
                  </Button>
                </DialogActions>
              ) : (
                <DialogActions className={classes.modalFooter}>
                  <Button
                    round
                    onClick={() => this.handleModal("close")}
                    color="danger"
                  >
                    <Close />
                    Close
                  </Button>
                  <Button
                    round
                    onClick={() => this.handleModal("print")}
                    color="instagram"
                  >
                    <Print />
                    Print
                  </Button>
                  {this.state.modalBody && (
                    <Button
                      round
                      justIcon
                      onClick={() => {
                        const { savePdf } = require("libs/pdf");
                        savePdf(
                          {
                            title: this.state.modalTitle,
                            body: this.state.modalBody,
                            filename: this.state.filename,
                          },
                          "PdfReceipt"
                        );
                      }}
                      color="success"
                    >
                      <Download />
                    </Button>
                  )}
                  {navigator.canShare && this.state.modalBody && (
                    <Button
                      round
                      justIcon
                      onClick={async () =>
                        share({
                          title: this.state.modalTitle,
                          body: this.state.modalBody,
                          filename: this.state.filename,
                        })
                      }
                      color="info"
                    >
                      <Share />
                    </Button>
                  )}
                </DialogActions>
              )}
            </Dialog>
          </div>
        </div>
      </Slide>
    );
  }
}
Emoney.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Emoney);
