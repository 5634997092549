import React from "react";

//hook styles class conversion
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import style from "assets/jss/material-kit-pro-react/views/gMainMenuStyle.js";

import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";

// core components
import Header from "components/GHeader/Header.js";
import Footer from "components/GFooter/Footer.js";
import HeaderLinks from "components/GHeader/HeaderLinks.js";

import textLogo from "assets/img/logo.png";

import Parallax from "components/GParallax/Parallax.js";

import MainMenu from "views/Main/MainMenu";
import Listrik from "views/Transaction/Listrik";
import Pulsa from "views/Transaction/Pulsa";
import Bpjs from "views/Transaction/Bpjs";
import Telkom from "views/Transaction/Telkom";
import Emoney from "views/Transaction/Emoney";
import MultiFinance from "views/Transaction/MultiFinance";
import PBB from "views/Transaction/PBB";
import Pdam from "views/Transaction/Pdam";
import Tv from "views/Transaction/Tv";
import BankTransfer from "views/Transaction/BankTransfer";
import MultiPayment from "views/Transaction/MultiPayment";

import MyProfile from "views/MyProfile";
import Settings from "views/Settings";

import axios from "axios";
import Config from "config";
import secureStorage from "libs/secureStorage";
import Resource from "libs/resource";

const styles = {
  ...style,
  headerLogoDefault: {
    borderRadius: 30,
    // position: 'absolute',
    // left: 0,
    // right: 0,
    // top: 0,
    // bottom: 0,
    color: "#fff",
    background: "linear-gradient(60deg, #e5e5e5, #fff)",
    // backgroundSize: 'cover',
    // backgroundColor:"linear-gradient(60deg, #3C4858, #eee)",
    height: 60,
    width: 80,
    // backgroundPosition: 'center 40%',
    // backgroundImage: `url(${textLogo})`,
  },
  imageLogoDefault: {
    width: 90,
  },
  headerLogo: {
    borderRadius: 30,

    // color:'#fff',
    // background:"linear-gradient(60deg, #e5e5e5, #fff)",

    height: 60,
    width: "auto",
  },
  imageLogo: {
    borderRadius: "40% !important",
    width: 60,
  },
};

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "MainMenu",
      logo: "/android-icon-512x512.png",
      info: "",
    };
    this.Refs = {
      MainMenu: React.createRef(),
      Listrik: React.createRef(),
      PaketData: React.createRef(),
      Pulsa: React.createRef(),
      Bpjs: React.createRef(),
      Telkom: React.createRef(),
      Emoney: React.createRef(),
      MultiFinance: React.createRef(),
      PBB: React.createRef(),
      Postpaid: React.createRef(),
      Pdam: React.createRef(),
      Tv: React.createRef(),
      BankTransfer: React.createRef(),
      MultiPayment: React.createRef(),
      MyProfile: React.createRef(),
      Settings: React.createRef(),
    };
    this.headerLinkRef = React.createRef();
  }

  componentDidMount() {
    // console.log(window.location.hostname)
    const hostname = window.location.hostname;
    hostname !== "payfren.id" && this.initResources();
    this.getBalance();
    this.getBill();
    this.initProfileImage();
  }
  initResources() {
    Resource.checkUpdate();
    Resource.getInfo()
      .then((info) => {
        // console.log(info)
        // console.log(info.captchaKey)
        this.setState({ info });
      })
      .catch((err) => {
        console.log(err);
      });
    // Resource.getImage()
    // .then(image=>{
    //   // console.log(image)
    //   this.setState({logo:image})
    // }).catch(err=>{
    //   console.log(err)
    // })
  }
  initProfileImage() {
    const user = secureStorage.getItem("account");
    const imageData = secureStorage.getItem("profileImage");
    // console.log(user.image,imageData.name)
    if (
      (!imageData && user.image) ||
      (imageData && user.image && imageData.name !== user.image)
    )
      this.getImage(user.image);
  }
  getImage(name) {
    axios
      .get(Config.backendUrl + "user/image", { responseType: "blob" })
      .then((res) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const image = e.target.result;
          secureStorage.setItem("profileImage", { name, image });
          this.headerLinkRef.current &&
            this.headerLinkRef.current.setProfileImage(image);
        };
        reader.readAsDataURL(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  handleChangePage = (page) => {
    const currentPage = this.state.page;
    this.getBalance();
    this.getBill();
    this.setState({ page: page });
    if (page && ["Settings", "MyProfile"].indexOf(page) !== -1) {
      if (currentPage === "MainMenu") {
        this.Refs[currentPage].current &&
          this.Refs[currentPage].current.handleClick(page);
      } else {
        this.Refs[currentPage].current &&
          this.Refs[currentPage].current.handleBack();
        this.setState({ page: "MainMenu" });
      }
    }
  };
  getBalance() {
    if (secureStorage.getItem("token")) {
      axios
        .post(Config.backendUrl + "user/balance")
        .then((res) => {
          secureStorage.setItem("balance", res.data.balance);
          this.headerLinkRef.current.setBalance(res.data.balance);
        })
        .catch((error) => {});
    }
  }
  getBill() {
    if (secureStorage.getItem("token")) {
      axios
        .post(Config.backendUrl + "bill")
        .then((res) => {
          secureStorage.setItem("bill", res.data.amount);
          this.headerLinkRef.current.setBill(res.data.amount);
        })
        .catch((error) => {});
    }
  }
  render() {
    const hostname = window.location.hostname;
    const { classes, ...rest } = this.props;
    if (["/", "/index.html"].indexOf(this.props.location.pathname) !== -1) {
      return <Redirect to="/MainMenu" />;
    }
    return (
      <div>
        <Header
          color="instagram"
          brand={
            <span
              // style={}
              className={
                hostname !== "payfren.id"
                  ? classes.headerLogo
                  : classes.headerLogoDefault
              }
            >
              {hostname !== "payfren.id" ? (
                <img
                  src={this.state.logo}
                  className={classes.imageLogo}
                  alt={this.state.info && this.state.info.appName}
                />
              ) : (
                <img
                  src={textLogo}
                  className={classes.imageLogoDefault}
                  alt={"PayFren"}
                />
              )}
            </span>
          }
          rightLinks={
            <HeaderLinks
              onChangePage={this.handleChangePage}
              ref={this.headerLinkRef}
            />
          }
          fixed
          changeColorOnScroll={{
            height: 50,
            color: "facebook",
          }}
          {...rest}
        />
        <Parallax
          small
          filter="dark"
          image={require("assets/img/newbg1.jpg")}
        />
        <Router>
          <Route
            path="/MainMenu"
            render={(props) => (
              <MainMenu
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.MainMenu}
              />
            )}
          />
          <Route
            path="/Listrik"
            render={(props) => (
              <Listrik
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.Listrik}
              />
            )}
          />

          <Route
            path="/Pulsa"
            render={(props) => (
              <Pulsa
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.Pulsa}
              />
            )}
          />
          <Route
            path="/Bpjs"
            render={(props) => (
              <Bpjs
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.Bpjs}
              />
            )}
          />
          <Route
            path="/Telkom"
            render={(props) => (
              <Telkom
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.Telkom}
              />
            )}
          />
          <Route
            path="/Emoney"
            render={(props) => (
              <Emoney
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.Emoney}
              />
            )}
          />
          <Route
            path="/MultiFinance"
            render={(props) => (
              <MultiFinance
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.MultiFinance}
              />
            )}
          />
          <Route
            path="/PBB"
            render={(props) => (
              <PBB
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.PBB}
              />
            )}
          />

          <Route
            path="/Pdam"
            render={(props) => (
              <Pdam
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.Pdam}
              />
            )}
          />
          <Route
            path="/Tv"
            render={(props) => (
              <Tv
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.Tv}
              />
            )}
          />
          <Route
            path="/BankTransfer"
            render={(props) => (
              <BankTransfer
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.BankTransfer}
              />
            )}
          />
          <Route
            path="/MultiPayment"
            render={(props) => (
              <MultiPayment
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.MultiPayment}
              />
            )}
          />

          <Route
            path="/MyProfile"
            render={(props) => (
              <MyProfile
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.MyProfile}
                onProfileImageChange={(image) =>
                  this.headerLinkRef.current &&
                  this.headerLinkRef.current.setProfileImage(image)
                }
              />
            )}
          />
          <Route
            path="/Settings"
            render={(props) => (
              <Settings
                {...props}
                onChangePage={this.handleChangePage}
                ref={this.Refs.Settings}
              />
            )}
          />
        </Router>
        <Footer />
      </div>
    );
  }
}
Main.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Main);
