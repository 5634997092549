import loader from "./loader";
import moment from "moment";
// import _ from 'lodash'
import secureStorage from "libs/secureStorage";
import getinfo from "helper/getinfo";

const receipt = (payload) => {
  const printer = secureStorage.getItem("printer");
  const user = secureStorage.getItem("account");

  const title = `${payload.title.padStart(105, " ")}\r\n`;
  const body = payload.body;

  const date =
    body.date && body.date.value ? body.date.value : payload.createdAt;

  const leftPage = [
    ["TGL", moment(date).format("DD/MM/YY HH:mm")],
    ["IDPEL", body.subscriberNo.value],
    ["MSN", body.meterNumber.value],
    ["NAMA", body.name.value],
    ["KWH", body.kwh.value],
    ["RPTAG", body.nominal.value],
    ["ADMIN", body.admin.value],
    ["BAYAR", body.total.value],
  ];

  for (let i = leftPage.length; i < 11; i++) leftPage.push([""]);

  const middlePage = [
    ["TANGGAL", moment(date).format("DD/MM/YY HH:mm")],
    [body.subscriberNo.caption, body.subscriberNo.value],
    ["NO METER", body.meterNumber.value],
    [body.name.caption, body.name.value],
    [
      body.referenceNo.caption,
      body.referenceNo.value.toString().substring(0, 15),
    ],
    [body.kwh.caption, body.kwh.value.toString()],
    ...getinfo(body.info.value),
  ];

  printer === "dotmatrix"
    ? middlePage.push([`${String.fromCharCode(27)}4Loket : ${user.name}`])
    : middlePage.push([`Loket : ${user.name}`]);
  printer === "dotmatrix"
    ? middlePage.push([
        `Powered by ${payload.appName}${String.fromCharCode(27)}5`,
      ])
    : middlePage.push([`Powered by ${payload.appName}`]);

  for (let i = middlePage.length; i < 11; i++) middlePage.push([""]);

  const rightPage = [
    [body.nominal.caption, body.nominal.value],
    [body.admin.caption, body.admin.value],
    [body.total.caption, body.total.value],
    [body.token.caption, ""],
  ];

  printer === "dotmatrix"
    ? rightPage.push([
        `${String.fromCharCode(27)}E${String.fromCharCode(
          27
        )}4${body.token.value.padStart("25", " ")}${String.fromCharCode(
          27
        )}5${String.fromCharCode(27)}F`,
      ])
    : rightPage.push([`${body.token.value.padStart("25", " ")}`]);

  for (let i = rightPage.length; i < 11; i++) rightPage.push([""]);

  if (printer === "dotmatrix")
    return loader(title, leftPage, middlePage, rightPage, payload);
  else return { title, leftPage, middlePage, rightPage, payload };
};
export default receipt;
