/*eslint-disable*/
import React, { isValidElement } from "react";
// @material-ui/core components

import InputAdornment from "@material-ui/core/InputAdornment";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Icon from "@material-ui/core/Icon";
// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Email from "@material-ui/icons/Email";
import Check from "@material-ui/icons/Check";
import Phone from '@material-ui/icons/PhoneAndroid'
import Address from "@material-ui/icons/ContactMail"
import ProvinceIcon from "@material-ui/icons/Map"
import Close from "@material-ui/icons/Close";
import Clearfix from "components/Clearfix/Clearfix.js";

// core components
import Footer from "components/GFooter/Footer.js";
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import InfoArea from "components/InfoArea/InfoArea.js";
import Input from "components/GCustomInput/CustomInput.js";

/**
 * Register style
 */
import styles from "assets/jss/material-kit-pro-react/views/gRegisterStyle.js";

import image from "assets/img/newbg1.jpg";

import {withRouter} from "react-router-dom"
import classNames from "classnames";
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { RepeatOneSharp } from "@material-ui/icons";

import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Provinces from "helper/Provinces"
/**
 * Alert Snackbar
 */
import SnackbarContent from "components/GSnackbar/SnackbarContent.js";




import { Redirect } from 'react-router-dom'
import axios from 'axios'
import Config from 'config'

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
/**
 * Transition for Modal
 */
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});


class Confirmation extends React.Component {

	constructor(props){
		super(props);
		this.state = {
      confirmCode : '',
      message : ''
		}
	}
  componentDidMount() {
		//initFb();
		
		if(this.props.match.params.confirmCode){
			this.setState({confirmCode:this.props.match.params.confirmCode})
			this.confirmation(this.props.match.params.confirmCode);
		}
		
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
	}

	async confirmation(confirmCode){
		
			if(confirmCode){
				await axios.post(Config.backendUrl+ 'user/confirmation',{
					confirmCode : this.props.match.params.confirmCode,
				}).then(res => {
						//console.log(res)
						this.setState({message: res.data})
						
				}).catch(err => {
						console.log(err)
						if(err.response){
							this.setState({message:err.response.data})
						}
						else{
							this.setState({message:'Koneksi jaringan bermasalah'})
						}
				});
			}
		//console.log(result)
		
	}
	
	
  render(){
		
		if(this.state.isInviationValid === false || this.state.redirect){
			return <Redirect to="/" />
		}
		const {classes} = this.props;
		
		return (
			<div>
				<div
					className={classes.pageHeader}
					style={{
						backgroundImage: "url(" + image + ")",
						backgroundSize: "cover",
						backgroundPosition: "top center"
					}}
				>
					<div className={classes.container}>
						<GridContainer justify="center">
							<GridItem xs={12} sm={10} md={10}>
								<Card className={classes.cardSignup}>
									<h4 className={classes.cardTitle} >Email Confirmation</h4>
									<CardBody>
										<GridContainer justify="center">
											<GridItem xs={12} sm={12} md={12} style={{textAlign:'center'}}>
												{this.state.message}
											</GridItem>
										</GridContainer>
									</CardBody>
								</Card>
								
							</GridItem>
						</GridContainer>
					</div>
					<Footer />
				</div>
			</div>
		);
	}
}
Confirmation.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withRouter(withStyles(styles)(Confirmation));