import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";

import settingsStyles from "assets/jss/material-kit-pro-react/views/gSettingsStyle.js";

// @material-ui/icons
import FileCopy from "@material-ui/icons/FileCopy"

// core components
import Input from "components/GCustomInput/CustomInput.js";


import axios from 'axios'
import Config from 'config'

import QRCode from 'react-qr-code'
import {WhatsappShareButton} from 'react-share'
import {SocialIcon} from 'react-social-icons'

import Tooltip from '@material-ui/core/Tooltip';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

// import secureStorage from 'libs/secureStorage'


const styles = {
	...settingsStyles,
}

class InvitationLink extends React.Component {

  constructor(props){
    super(props);
    this.state = {

			invitationLink : '',
			toolTipOpen : false,

		}
    this.input = React.createRef()
  }

  componentDidMount(){

		this.initInvitationLink()
		
	}

	initInvitationLink(){
		//console.log(axios.defaults.headers.Authorization)

    axios.post(Config.backendUrl+'user/invitation')
    .then(res => {
        // let path = Config.PayFren+'reg/'+res.data
        // const path = Config.PayFren+'reg/'+res.data
        const hostname = window.location.hostname
        const path = `https://${hostname}/reg/${res.data}`
        this.setState({invitationLink:path})
    }).catch(err => {
        console.log(err)
    });
		
	}

	handleTooltipClose = (e) =>{
		this.setState({toolTipOpen:false})
	}

	handleCopy = (e) => {
		this.input.current.select();
		document.execCommand('copy');
		this.setState({toolTipOpen:true})
	}

  render(){
    const {classes} = this.props;
		
		
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={12} sm={12} md={8} className={classes.grid}>
            <input style={{position:'absolute',left:'-5000px',top:'-5000px'}}  
              defaultValue={this.state.invitationLink} 
              ref={this.input} 
            />
            <Input
              labelText="Invitation Link"
              id="invitationLink"
              formControlProps={{
                style: {width:"80%"}
              }}
              inputProps={{
                value: this.state.invitationLink
              }}
              style={{float:"left",overflow:"hidden"}}
            />
            <ClickAwayListener onClickAway={this.handleTooltipClose}>
              <Tooltip
                placement="top"
                PopperProps={{
                  disablePortal: true,
                }}
                onClose={this.handleTooltipClose}
                open={this.state.toolTipOpen}
                disableFocusListener={true}
                disableTouchListener={true}
                title="Copied"
              >
                <Button justIcon round color="info" 
                  style={{overflow:"hidden",margin:"20px 0 0 10px"}}
                  onClick={this.handleCopy}
                >
                  <FileCopy />
                </Button>
              </Tooltip>
            </ClickAwayListener>
            
          </GridItem>
        </GridContainer>
        <div>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4} className={classes.grid}>
                <QRCode value={this.state.invitationLink} />
            </GridItem>
          </GridContainer>
          <GridContainer justify="center">
            <GridItem xs={12} sm={12} md={4} className={classes.grid}>
              <WhatsappShareButton url={this.state.invitationLink}><SocialIcon network="whatsapp" className={classes.whatsapp} /></WhatsappShareButton>
            </GridItem>
          </GridContainer>
        </div>
      </div>					
    );
  }
}
InvitationLink.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(InvitationLink);