module.exports = {
  //axis
  '0838':{billerId:18,},
  '0831':{billerId:18,},
  '0832':{billerId:18,},
  '0833':{billerId:18,},
  //three
  '0895':{billerId:3,productId: 3 },
  '0896':{billerId:3,productId: 3 },
  '0897':{billerId:3,productId: 3 },
  '0898':{billerId:3,productId: 3 },
  '0899':{billerId:3,productId: 3 },
  //telkomsel
  '0811':{billerId:17,productId: 19 },
  '0812':{billerId:17,productId: 19 },
  '0813':{billerId:17,productId: 19 },
  '0821':{billerId:17,productId: 19 },
  '0822':{billerId:17,productId: 19 },
  '0852':{billerId:17,productId: 19 },
  '0853':{billerId:17,productId: 19 },
  '0823':{billerId:17,productId: 19 },
  '0851':{billerId:17,productId: 19 },
  //indosat
  '0814':{billerId:19,productId: 25 },
  '0815':{billerId:19,productId: 25 },
  '0816':{billerId:19,productId: 25 },
  '0855':{billerId:19,productId: 25 },
  '0856':{billerId:19,productId: 25 },
  '0857':{billerId:19,productId: 25 },
  '0858':{billerId:19,productId: 25 },
  //xl
  '0817':{billerId:243,productId: 22 },
  '0818':{billerId:243,productId: 22 },
  '0819':{billerId:243,productId: 22 },
  '0859':{billerId:243,productId: 22 },
  '0877':{billerId:243,productId: 22 },
  '0878':{billerId:243,productId: 22 },
  //smartfren
  '0881':{billerId:23,productId: 540 },
  '0882':{billerId:23,productId: 540 },
  '0883':{billerId:23,productId: 540 },
  '0884':{billerId:23,productId: 540 },
  '0885':{billerId:23,productId: 540 },
  '0886':{billerId:23,productId: 540 },
  '0887':{billerId:23,productId: 540 },
  '0888':{billerId:23,productId: 540 },
  '0889':{billerId:23,productId: 540 },
}