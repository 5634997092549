import {
  grayColor,
  roseColor,
  primaryColor,
  // secondaryColor,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  blackColor,
  whiteColor,
  twitterColor,
  facebookColor,
  googleColor,
  linkedinColor,
  pinterestColor,
  youtubeColor,
  tumblrColor,
  behanceColor,
  dribbbleColor,
  redditColor,
  instagramColor,
  hexToRgb
} from "assets/jss/material-kit-pro-react.js";

const navPillsStyle = theme => ({
  root: {
    marginTop: "0px",
    paddingLeft: "0",
    marginBottom: "0",
    overflow: "visible !important",
    lineHeight: "24px",
    textTransform: "uppercase",
    fontSize: "12px",
    fontWeight: "500",
    position: "relative",
    display: "block",
    color: "inherit"
  },
  flexContainer: {
    [theme.breakpoints.down("xs")]: {
      display: "flex",
      flexWrap: "wrap"
    }
  },
  displayNone: {
    display: "none !important"
  },
  fixed: {
    overflow: "visible !important"
  },
  horizontalDisplay: {
    display: "block"
  },
  pills: {
    float: "left",
    position: "relative",
    display: "block",
    borderRadius: "30px",
    fontSize:"10px",
    minWidth: "80px",
    textAlign: "center",
    transition: "all .3s",
    padding: "10px 15px",
    color: grayColor[8],
    height: "auto",
    opacity: "1",
    maxWidth: "100%",
    margin: "0 5px"
  },
  pillsWithIcons: {
    borderRadius: "4px"
  },
  tabIcon: {
    width: "20px",
    height: "20px",
    display: "block",
    margin: "0px 0 !important",
    "&, & *": {
      letterSpacing: "normal !important"
    }
  },
  horizontalPills: {
    width: "100%",
    float: "none !important",
    "& + button": {
      margin: "10px 0"
    }
  },
  contentWrapper: {
    marginTop: "20px",
    "& .react-swipeable-view-container > div > div": {
      paddingLeft: "15px",
      paddingRight: "15px"
    }
  },
  primary: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: primaryColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(primaryColor[0]) +
        ", 0.4)"
    }
  },
  info: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: infoColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.4)"
    }
  },
  success: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: successColor[0],
      boxShadow:
        "0 2px 2px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.14), 0 3px 1px -2px rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.2), 0 1px 5px 0 rgba(" +
        hexToRgb(successColor[0]) +
        ", 0.12)"
    }
  },
  warning: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: warningColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.4)"
    }
  },
  danger: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: dangerColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(warningColor[0]) +
        ", 0.4)"
    }
  },
  rose: {
    "&,&:hover": {
      color: whiteColor,
      backgroundColor: roseColor[0],
      boxShadow:
        "0 4px 20px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.14), 0 7px 10px -5px rgba(" +
        hexToRgb(roseColor[0]) +
        ", 0.4)"
    }
  },
  white: {
    "&,&:focus,&:hover": {
      backgroundColor: whiteColor,
      color: grayColor[0]
    }
  },
  twitter: {
    backgroundColor: twitterColor,
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(twitterColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(twitterColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(twitterColor) +
      ", 0.12)",
    "&:hover,&:focus,&:visited": {
      backgroundColor: twitterColor,
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(twitterColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(twitterColor) +
        ", 0.2)"
    }
  },
  facebook: {
    backgroundColor: facebookColor,
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(facebookColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(facebookColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(facebookColor) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: facebookColor,
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(facebookColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(facebookColor) +
        ", 0.2)"
    }
  },
  google: {
    backgroundColor: googleColor,
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(googleColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(googleColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(googleColor) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: googleColor,
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(googleColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(googleColor) +
        ", 0.2)"
    }
  },
  linkedin: {
    backgroundColor: linkedinColor,
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(linkedinColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(linkedinColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(linkedinColor) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: linkedinColor,
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(linkedinColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(linkedinColor) +
        ", 0.2)"
    }
  },
  pinterest: {
    backgroundColor: pinterestColor,
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(pinterestColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(pinterestColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(pinterestColor) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: pinterestColor,
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(pinterestColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(pinterestColor) +
        ", 0.2)"
    }
  },
  youtube: {
    backgroundColor: youtubeColor,
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(youtubeColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(youtubeColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(youtubeColor) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: youtubeColor,
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(youtubeColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(youtubeColor) +
        ", 0.2)"
    }
  },
  tumblr: {
    backgroundColor: tumblrColor,
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(tumblrColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(tumblrColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(tumblrColor) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: tumblrColor,
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(tumblrColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(tumblrColor) +
        ", 0.2)"
    }
  },
  github: {
    backgroundColor: grayColor[8],
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(grayColor[8]) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(grayColor[8]) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(grayColor[8]) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: grayColor[8],
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(grayColor[8]) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(grayColor[8]) +
        ", 0.2)"
    }
  },
  behance: {
    backgroundColor: behanceColor,
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(behanceColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(behanceColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(behanceColor) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: behanceColor,
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(behanceColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(behanceColor) +
        ", 0.2)"
    }
  },
  dribbble: {
    backgroundColor: dribbbleColor,
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(dribbbleColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(dribbbleColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(dribbbleColor) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: dribbbleColor,
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(dribbbleColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(dribbbleColor) +
        ", 0.2)"
    }
  },
  reddit: {
    backgroundColor: redditColor,
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(redditColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(redditColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(redditColor) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: redditColor,
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(redditColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(redditColor) +
        ", 0.2)"
    }
  },
  instagram: {
    backgroundColor: instagramColor,
    color: whiteColor,
    boxShadow:
      "0 2px 2px 0 rgba(" +
      hexToRgb(instagramColor) +
      ", 0.14), 0 3px 1px -2px rgba(" +
      hexToRgb(instagramColor) +
      ", 0.2), 0 1px 5px 0 rgba(" +
      hexToRgb(instagramColor) +
      ", 0.12)",
    "&:hover,&:focus": {
      backgroundColor: instagramColor,
      color: whiteColor,
      boxShadow:
        "0 14px 26px -12px rgba(" +
        hexToRgb(instagramColor) +
        ", 0.42), 0 4px 23px 0px rgba(" +
        hexToRgb(blackColor) +
        ", 0.12), 0 8px 10px -5px rgba(" +
        hexToRgb(instagramColor) +
        ", 0.2)"
    }
  },
  alignCenter: {
    alignItems: "center",
    justifyContent: "center"
  },
  tabLabelContainer: {
    padding: "unset !important"
  }
});

export default navPillsStyle;
