import React from "react";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
import Input from "components/GCustomInput/CustomInput.js";

import Accordion from "components/GAccordion/Accordion.js";

import Table from "components/Table/Table";

import TagsInput from "react-tagsinput";

import AttachFile from "@material-ui/icons/AttachFile";
import SaveIcon from "@material-ui/icons/Save";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import Print from "@material-ui/icons/Print";
import PageviewRoundedIcon from "@material-ui/icons/PageviewRounded";
import SettingsIcon from "@material-ui/icons/Settings";
import PaymentIcon from "@material-ui/icons/Payment";

import Zoom from "@material-ui/core/Zoom";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";

import FileInput from "components/CustomFileInput/CustomFileInput.js";
// Custom select
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";

import Badge from "components/Badge/Badge.js";

import { fnumber } from "helper/Helper";

import style from "assets/jss/material-kit-pro-react/views/gTransactionStyle.js";
import customSelectStyle from "assets/jss/material-kit-pro-react/gCustomSelectStyle.js";
import modalStyle from "assets/jss/material-kit-pro-react/gModalStyle.js";
//import 'react-tagsinput/react-tagsinput.css'
import "assets/scss/material-kit-pro-react.scss";

import axios from "axios";
import Config from "config";

import print from "helper/printer";

import secureStorage from "libs/secureStorage";

import modalReceipt from "helper/modalReceipt";

import readXlsx from "read-excel-file";

// import {savePdf,getUrl} from 'libs/pdf'

// import {toPdf} from 'libs/converter'

import { Modal, Checkbox } from "semantic-ui-react";
import { CircularProgress } from "@material-ui/core";

import moment from "moment";
import "moment/locale/id";
moment.locale("id");
const styles = (theme) => ({
  ...style,
  ...customSelectStyle,
  ...modalStyle(theme),
  grid: {
    textAlign: "center",
  },
  colGrid: {
    marginTop: 10,
    textAlign: "center",
  },
});
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

class Collective extends React.Component {
  constructor(props) {
    super();
    this.state = {
      tags: [],
      fileCodes: [],
      btnSaveNameDisabled: true,
      btnSavedGroupDisabled: false,
      btnSaveDisabled: true,
      btnSaveFileDisabled: true,
      btnInquiryDisabled: true,
      btnPurchaseDisabled: true,
      btnPrintDisabled: true,

      prevCount: 0,
      prevName: "",

      collectiveName: "",

      selectedCollective: "",
      selectedBatch: "",
      collections: [],

      totalTagihan: 0,
      totalQty: 0,
      totalAdmin: 0,
      totalBayar: 0,

      adminCollective: "",
      adminNominals: [],

      modalShow: false,
      modalTitle: "",
      modalBody: "",
      modalIsreceipt: false,

      printOpen: false,
      startNum: "",
      endNum: "",
      printSettings: false,
      sheetNum: 15,
      feedNum: 4,

      purchaseModal: false,
      check: {},

      printListModal: false,
      selectedPrinter: "",
      dotmatrix: "",
      printers: [],
      printListPayload: "",
    };
  }
  componentDidMount() {
    const printSettings = secureStorage.getItem("printSettings");
    printSettings && this.setState({ ...printSettings });
    this.loadAdminNonminal();
    this.loadPrinter();
  }

  loadPrinter = () => {
    // let selectedPrinter = secureStorage.getItem('printer')
    // const dotmatrix = secureStorage.getItem('dotmatrix')
    const isElectron =
      navigator.userAgent.toLocaleLowerCase().indexOf("electron/") !== -1;
    const selectedPrinter = isElectron ? "dotmatrix" : "deskjet";
    const dotmatrix = secureStorage.getItem("dotmatrix");
    const printers =
      dotmatrix === "online"
        ? [{ name: "dotmatrix", label: "DOT MATRIX" }]
        : [];
    printers.push({ name: "deskjet", label: "DESKJET/INKJET/LASERJET" });
    printers.push({ name: "pdf", label: "PDF" });
    this.setState({ selectedPrinter, printers });
  };

  loadAdminNonminal = () => {
    axios
      .post(Config.backendUrl + "product/admin", {
        productId: 14,
      })
      .then((res) => {
        this.setState({ adminNominals: res.data });
      })
      .catch((err) => {});
  };

  handleChangeName = (e) => {
    this.setState({ btnSaveNameDisabled: true });
    axios
      .post(Config.backendUrl + "collective/changename", {
        collectiveName: this.state.collectiveName,
        collective: this.state.selectedCollective,
      })
      .then((res) => {})
      .catch((err) => {
        this.errorMessage(err);
      });
  };

  handleInquiry = async () => {
    this.setState({
      btnInquiryDisabled: true,
      btnPurchaseDisabled: true,
      btnPrintDisabled: true,
    });
    const collections = this.state.collections;
    for (let index = 0; index < collections.length; index++) {
      if (!collections[index].BatchSalesDts) {
        this.setState({ ["loading" + index]: true });
        const payload = {
          batch: this.state.selectedBatch,
          collective: this.state.selectedCollective,
          collection: collections[index],
          adminNominal: this.state.adminCollective,
          isIncluded: this.state.check[index],
        };
        //console.log(payload)
        await axios
          .post(Config.backendUrl + "collective/inquiry", payload)
          .then((res) => {
            // console.log(res.data)
            collections[index] = res.data.collection;
            this.setState({
              ["loading" + index]: false,
              collections: collections,
              ...this.setTotal(collections),
              selectedBatch: !this.state.selectedBatch
                ? res.data.batch
                : this.state.selectedBatch,
            });
          })
          .catch((err) => {
            if (err.response) {
              this.setState({
                ["loading" + index]: false,
                selectedBatch: !this.state.selectedBatch
                  ? err.response.data.batch
                  : this.state.selectedBatch,
              });
            }
            console.log(err);
          });
      }
    }
    this.setState({
      btnInquiryDisabled: false,
      btnPurchaseDisabled: false,
      btnPrintDisabled: false,
    });
  };
  handlePurchase = async (e) => {
    this.setState({
      btnInquiryDisabled: true,
      btnPurchaseDisabled: true,
      btnPrintDisabled: true,
      purchaseModal: false,
    });
    const collections = this.state.collections;
    //console.log('halo')
    //console.log(collections)
    for (let index = 0; index < collections.length; index++) {
      //console.log(collections[index].BatchSalesDts , collections[index].BatchSalesDts[0].TrSale.requestTypeI, collections[index].BatchSalesDts[0].TrSale.statusId )
      if (
        collections[index].BatchSalesDts &&
        collections[index].BatchSalesDts[0].TrSale &&
        collections[index].BatchSalesDts[0].TrSale.requestTypeId === 1 &&
        collections[index].BatchSalesDts[0].TrSale.statusId === 4 &&
        this.state.check[index]
      ) {
        this.setState({ ["loading" + index]: true });
        const payload = {
          collection: collections[index],
        };
        //console.log(payload)

        await axios
          .post(Config.backendUrl + "collective/purchase", payload)
          .then((res) => {
            //console.log(res.data)
            collections[index] = res.data.collection;
            this.setState({
              ["loading" + index]: false,
              collections: collections,
            });
          })
          .catch((err) => {
            this.setState({
              ["loading" + index]: false,
            });
            console.log(err);
          });
      }
    }
    this.setState({
      btnInquiryDisabled: false,
      btnPurchaseDisabled: false,
      btnPrintDisabled: false,
    });
  };
  handlePrintDialog = (e) => {
    this.setState({
      printOpen: true,
      startNum: 1,
      endNum: this.state.collections.length,
    });
  };
  handlePrint = (e) => {
    this.setState({ printOpen: false });
    // secureStorage.removeItem('printBatch')
    const printBatch = secureStorage.getItem("printBatch");

    if (
      printBatch &&
      printBatch.collectiveId === this.state.selectedCollective.id &&
      printBatch.batchId === this.state.selectedBatch.id
    ) {
      // console.log(printBatch)
      const data = printBatch.data.filter(
        (item, index) =>
          index >= this.state.startNum - 1 && index <= this.state.endNum - 1
      );
      // console.log(data)
      print(data, {
        feedNum: this.state.feedNum,
        sheetNum: this.state.sheetNum,
      });
    } else {
      const collections = this.state.collections.map((item) => item.id);
      // console.log({collections,batch:this.state.selectedBatch})
      axios
        .post(Config.backendUrl + "collective/detail/all", {
          collections,
          batch: this.state.selectedBatch,
        })
        .then((res) => {
          // console.log(res.data)
          secureStorage.setItem("printBatch", {
            data: res.data,
            collectiveId: this.state.selectedCollective.id,
            batchId: this.state.selectedBatch.id,
          });
          const data = res.data.filter(
            (item, index) =>
              index >= this.state.startNum - 1 && index <= this.state.endNum - 1
          );
          // console.log(data)
          print(data, {
            feedNum: this.state.feedNum,
            sheetNum: this.state.sheetNum,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  initReceiptBody = (body) => {
    return body && modalReceipt(body);
  };
  handleViewDetail = (salesId) => {
    axios
      .post(Config.backendUrl + "collective/detail", { salesId })
      .then((res) => {
        //console.log(res)
        this.setState({
          modalShow: true,
          modalIsreceipt: true,
          modalTitle: res.data.receiptTitle,
          modalBody: this.initReceiptBody(res.data.receiptBody),
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleModal = (action) => {
    if (action === "print") {
      print({ title: this.state.modalTitle, body: this.state.modalBody });
    } else if (action === "deleteCollective") {
      this.removeCollective();
    }
  };
  removeCollective = (e) => {
    this.setState({
      collectiveName: "",
      selectedCollective: "",
      selectedBatch: "",
      collections: [],
      btnSavedGroupDisabled: false,
      btnPrintDisabled: true,
      btnPurchaseDisabled: true,
      modalTitle: "",
      modalBody: "",
      modalShow: false,
    });
    axios
      .post(Config.backendUrl + "collective/remove", {
        collective: this.state.selectedCollective,
      })
      .then((res) => {
        //console.log(res)
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleRemoveCollection = (collection, index) => {
    const collections = this.state.collections;
    axios
      .post(Config.backendUrl + "collective/collection/remove", { collection })
      .then((res) => {
        //console.log(res)
        collections.splice(index, 1);
        this.setState({ collections: collections });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  handleTags = (regularTags) => {
    if (
      this.state.collections.length === 0 ||
      (this.state.collections.length > 0 &&
        !this.state.collections[0].BatchSalesDts)
    ) {
      const collCustomerCodes = this.state.collections.map(
        (item) => item.customerCode
      );
      const tags = regularTags
        .filter(
          (d) =>
            d !== "" &&
            !isNaN(d) &&
            d.toString().length >= 11 &&
            d.toString().length <= 12
        )
        .filter((tag, index) => !collCustomerCodes.includes(tag));
      // console.log([...new Set(tags)])
      this.setState({ tags: [...new Set(tags)] });
      if (tags.length > 0) this.setState({ btnSaveDisabled: false });
      else this.setState({ btnSaveDisabled: true });
    }
  };
  handleSaveTags = (e) => {
    this.setState({
      btnSaveFileDisabled: true,
      btnSaveDisabled: true,
    });
    const newCustomerCodes =
      this.state.tags.length > 0 ? this.state.tags : this.state.fileCodes;
    const collCustomerCodes = this.state.collections.map(
      (item) => item.customerCode
    );
    const customerCodes = newCustomerCodes.filter(
      (tag, index) => !collCustomerCodes.includes(tag)
    );

    axios
      .post(Config.backendUrl + "collective/collection/save", {
        customerCodes,
        collective: this.state.selectedCollective,
        collectiveName: this.state.collectiveName,
        batch: this.state.selectedBatch,
      })
      .then((res) => {
        // console.log(res.data);
        this.setState({
          tags: [],

          //data
          collections: res.data.collections,
          selectedCollective: res.data.collective,
          collectiveName: res.data.collective.name,
          ...this.setTotal(res.data.collections),
          //button enabled/disable
          btnInquiryDisabled: this.state.adminCollective ? false : true,
          btnPurchaseDisabled: false,
          btnPrintDisabled: false,
        });
        this.filSaved();
      })
      .catch((err) => {});
  };
  initSavedGroup = (e) => {
    this.setState({ btnSavedGroupDisabled: true });
    axios
      .post(Config.backendUrl + "collective/get")
      .then((res) => {
        //console.log(res.data)
        this.setState({
          tags: [],
          btnSaveFileDisabled: true,
          btnSaveDisabled: true,
          modalShow: true,
          modalTitle: "GROUP TERSIMPAN",
          modalBody: this.initCollectives(res.data),
        });
      })
      .catch((err) => {});
  };
  initCollections = (params) => {
    this.setState({ modalShow: false });
    axios
      .post(Config.backendUrl + "collective/collection/get", {
        batch: params.batch ? params.batch : null,
        collective: params.collective,
      })
      .then((res) => {
        //console.log(res.data.collections)
        this.setState({
          modalShow: false,
          collections: res.data.collections,
          selectedCollective: res.data.collective,
          collectiveName: res.data.collective.name,
          selectedBatch: params.batch ? params.batch : null,
          ...this.setTotal(res.data.collections, true),
          //button enabled/disable
          btnInquiryDisabled: this.state.adminCollective ? false : true,
          btnPurchaseDisabled: false,
          btnPrintDisabled: false,
          btnSavedGroupDisabled: false,
        });
      })
      .catch((err) => {});
  };
  initCollectives(collectives) {
    const { classes } = this.props;
    return (
      <Accordion
        activeColor="info"
        active={0}
        collapses={collectives.map((row, index) => ({
          title: row.name,
          content: (
            <div className={classes.section}>
              <GridContainer justify="center">
                <GridItem xs={12} sm={12} md={8} className={classes.grid}>
                  <Button
                    round
                    color="success"
                    onClick={(e) => this.initCollections({ collective: row })}
                  >
                    transaksi baru
                  </Button>
                </GridItem>
                {row.BatchSales.map((batch, idx) => (
                  <GridItem
                    xs={12}
                    sm={12}
                    md={8}
                    className={classes.colGrid}
                    key={idx}
                  >
                    <Button
                      size="sm"
                      color="warning"
                      onClick={(e) =>
                        this.initCollections({ collective: row, batch: batch })
                      }
                    >
                      {moment(batch.createdAt).format(
                        "dddd, DD MMMM YYYY HH:mm:ss"
                      )}
                    </Button>
                  </GridItem>
                ))}
                <GridItem
                  xs={12}
                  sm={12}
                  md={8}
                  className={classes.grid}
                  style={{ marginTop: 30 }}
                >
                  <Button
                    round
                    color="danger"
                    onClick={(e) => {
                      this.setState({
                        selectedCollective: row,
                        modalTitle: "Hapus",
                        modalBody: <h4>Hapus {row.name} ? </h4>,
                      });
                    }}
                  >
                    <DeleteForeverIcon />
                    hapus
                  </Button>
                </GridItem>
              </GridContainer>
            </div>
          ),
        }))}
      />
    );
  }
  changeTotal(batchDt, checked) {
    let totalTagihan = this.state.totalTagihan;
    let totalQty = this.state.totalQty;
    let totalAdmin = this.state.totalAdmin;
    let totalBayar = this.state.totalBayar;

    const sale = batchDt.TrSale;
    const admin = batchDt.adminNominal;
    const amount = parseInt(sale.amount);
    const qty = sale.qty;
    if (checked) {
      totalQty += qty;
      totalTagihan += amount;
      totalAdmin += admin * qty;
      totalBayar += amount + admin * qty;
    } else {
      totalQty -= qty;
      totalTagihan -= amount;
      totalAdmin -= admin * qty;
      totalBayar -= amount + admin * qty;
    }
    this.setState({ totalQty, totalTagihan, totalAdmin, totalBayar });
  }
  setTotal(collections, isNew) {
    let totalTagihan = 0;
    let totalQty = 0;
    let totalAdmin = 0;
    let totalBayar = 0;
    // console.log(collections)
    const check = isNew ? {} : this.state.check;
    collections.map((item, index) => {
      //console.log(item)
      if (item.BatchSalesDts && item.BatchSalesDts[0].TrSale) {
        if (item.BatchSalesDts[0].isIncluded) {
          const sale = item.BatchSalesDts[0].TrSale;
          const receiptBody =
            sale && [1, 2, 4].includes(sale.statusId)
              ? typeof sale.receiptBody === "object"
                ? sale.receiptBody
                : JSON.parse(sale.receiptBody)
              : null;

          const admin =
            sale && sale.statusId !== 2
              ? item.BatchSalesDts[0].adminNominal
              : 0;
          const amount =
            sale && sale.statusId !== 2 && receiptBody && receiptBody.amount
              ? receiptBody.amount.value
              : 0;
          // const amount =
          // 	sale && sale.statusId !== 2 ? parseInt(sale.amount) : 0;
          const qty = sale && sale.statusId !== 2 ? sale.qty : 0;
          totalQty += qty;
          totalTagihan += amount;
          totalAdmin += admin * qty;
          totalBayar += amount + admin * qty;
        }
        check[index] = item.BatchSalesDts[0].isIncluded;
      } else if (item.BatchSalesDts) {
        check[index] = item.BatchSalesDts[0].isIncluded;
      } else {
        if (check[index] === undefined) check[index] = true;
      }
      return item;
    });

    // console.log({ totalTagihan, totalQty, totalAdmin, totalBayar, check })
    return { totalTagihan, totalQty, totalAdmin, totalBayar, check };
  }

  getStatus = (batchDt) => {
    if (!batchDt) return <Badge color="info">READY</Badge>;
    else {
      if (batchDt[0].TrSale) {
        if (batchDt[0].TrSale.statusId === 2) {
          return <Badge color="danger">gagal</Badge>;
        } else if (batchDt[0].TrSale.statusId === 1) {
          return <Badge color="warning">pending</Badge>;
        } else {
          if (batchDt[0].TrSale.requestTypeId === 1) {
            return <Badge color="instagram">inquiry</Badge>;
          } else {
            return <Badge color="success">sukses</Badge>;
          }
        }
      } else {
        return <Badge color="danger">SKIP</Badge>;
      }
    }
  };
  errorMessage(err) {
    let title;
    let message;
    if (err.response) {
      title = err.response.statusText;
      message = err.response.data;
    } else {
      title = "Error";
      message = "Koneksi terputus";
    }
    this.setState({
      modalShow: true,
      modalTitle: title,
      modalBody: message,
    });
  }
  render() {
    const { classes } = this.props;
    return (
      <div className={classes.section}>
        <GridContainer justify="center">
          <GridItem xs={8} sm={10} md={10} className={classes.grid}>
            <Input
              labelText="Nama Group"
              id="collectiveName"
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "Masukkan Nama Grup Kolektif",
                value: this.state.collectiveName,
                onChange: (e) => {
                  this.setState({
                    collectiveName: e.target.value,
                    btnSaveNameDisabled:
                      this.state.selectedCollective &&
                      e.target.value !== this.state.selectedCollective.name
                        ? false
                        : true,
                  });
                },
                //type:'tel'
              }}
            />
          </GridItem>
          <GridItem
            xs={1}
            sm={1}
            md={1}
            className={classes.grid}
            style={{ marginTop: 25 }}
          >
            <Button
              justIcon
              size="sm"
              color="instagram"
              disabled={this.state.btnSaveNameDisabled}
              onClick={this.handleChangeName}
            >
              <SaveIcon />
            </Button>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className={classes.grid}
          >
            <GridContainer justify="center">
              <GridItem xs={10} sm={10} md={6} lg={6} xl={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="admin-select"
                    className={classes.selectLabel}
                  >
                    Admin
                  </InputLabel>
                  <Select
                    MenuProps={{
                      className: classes.selectMenu,
                    }}
                    classes={{
                      select: classes.select,
                    }}
                    value={this.state.adminCollective}
                    onChange={(e) => {
                      this.setState({
                        adminCollective: e.target.value,
                        btnInquiryDisabled:
                          this.state.collections.length > 0 ? false : true,
                      });
                    }}
                    inputProps={{
                      name: "adminCollective",
                      id: "adminCollective",
                    }}
                  >
                    <MenuItem
                      disabled
                      classes={{
                        root: classes.selectMenuItem,
                      }}
                      value={""}
                    >
                      Admin
                    </MenuItem>
                    {this.state.adminNominals.map((admin, index) => (
                      <MenuItem
                        key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={admin.nominal}
                      >
                        {fnumber(admin.nominal)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem xs={12} sm={12} md={8} className={classes.grid}>
            <Button
              size="sm"
              round
              color="instagram"
              disabled={this.state.btnSavedGroupDisabled}
              onClick={this.initSavedGroup}
            >
              <ListAltIcon />
              group tersimpan
            </Button>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={12}
            className={classes.grid}
            style={{ marginTop: 20 }}
          >
            <Table
              tableHead={[
                "#",
                "IDPEL",
                "NAMA",
                "PROSES",
                "STATUS",
                "TAGIHAN",
                "QTY",
                "ADMIN",
                "BAYAR",
                "DETAIL",
                "HAPUS",
              ]}
              tableData={this.state.collections.map((row, index) => {
                const sale = row.BatchSalesDts && row.BatchSalesDts[0].TrSale;
                // sale && console.log({ sale });
                const receiptBody =
                  sale && [1, 2, 4].includes(sale.statusId)
                    ? typeof sale.receiptBody === "object"
                      ? sale.receiptBody
                      : JSON.parse(sale.receiptBody)
                    : false;
                const name = row.customerName
                  ? row.customerName
                  : receiptBody && [1, 4].includes(sale.statusId)
                  ? Array.isArray(receiptBody)
                    ? receiptBody[1][1]
                    : receiptBody.name.value
                  : "-";
                // console.log(row.name)
                let remark = "-";
                if (receiptBody) {
                  if ([2].includes(sale.statusId))
                    remark = Array.isArray(receiptBody)
                      ? receiptBody[0][0]
                      : receiptBody.message;
                  else {
                    remark = (
                      <Button
                        size="sm"
                        justIcon
                        round
                        color="facebook"
                        disabled={!row.BatchSalesDts ? true : false}
                        onClick={(e) => {
                          this.handleViewDetail(sale ? sale.id : null);
                        }}
                      >
                        <PageviewRoundedIcon />
                      </Button>
                    );
                  }
                }
                // && [2].includes(sale.statusId)?Array.isArray(receiptBody)?receiptBody[0][0]:receiptBody.message:'-'
                //sale && sale.statusId===2 && sale.receiptBody && JSON.parse(sale.receiptBody).message
                // console.log({remark,id:row.BatchSalesDts && row.BatchSalesDts[0].TrSale.id})
                // const checked = row.BatchSalesDts && row.BatchSalesDts[0].isIncluded
                let checkedDisabled = false;
                if (sale && sale.statusId === 2) checkedDisabled = true;
                if (
                  row.BatchSalesDts &&
                  !row.BatchSalesDts[0].isIncluded &&
                  !sale
                )
                  checkedDisabled = true;
                // console.log(row.BatchSalesDts && !row.BatchSalesDts[0].isIncluded)
                return [
                  index + 1,
                  row.customerCode,
                  name,
                  <Checkbox
                    disabled={checkedDisabled}
                    checked={this.state.check[index]}
                    id={"check" + index}
                    onClick={(e, { checked }) => {
                      // console.log({checked})
                      const check = this.state.check;
                      check[index] = checked;
                      // console.log({check})
                      this.setState({ check });
                      if (
                        row.BatchSalesDts &&
                        sale &&
                        sale.requestTypeId === 1 &&
                        sale.statusId === 4
                      ) {
                        this.changeTotal(row.BatchSalesDts[0], checked);
                        axios
                          .post(Config.backendUrl + "collective/included", {
                            batchDtId: row.BatchSalesDts[0].id,
                            isIncluded: checked,
                          })
                          .then((res) => {})
                          .catch((err) => {});
                      }
                    }}
                  />,
                  this.state["loading" + index] ? (
                    <CircularProgress color="secondary" size={15} />
                  ) : (
                    this.getStatus(row.BatchSalesDts)
                  ),
                  sale && sale.statusId !== 2 ? fnumber(sale.amount) : "0",
                  sale && sale.statusId !== 2 && sale.qty
                    ? fnumber(sale.qty)
                    : "0",
                  sale && sale.statusId !== 2
                    ? fnumber(row.BatchSalesDts[0].adminNominal * sale.qty)
                    : "0",
                  sale && sale.statusId !== 2
                    ? fnumber(
                        parseInt(sale.amount) +
                          row.BatchSalesDts[0].adminNominal * sale.qty
                      )
                    : "0",
                  remark,
                  <Button
                    size="sm"
                    justIcon
                    round
                    color="danger"
                    onClick={(e) => this.handleRemoveCollection(row, index)}
                  >
                    <DeleteForeverIcon />
                  </Button>,
                ];
              })}
              tableFoot={[
                "-",
                "-",
                "-",
                "-",
                "Total",
                fnumber(this.state.totalTagihan),
                fnumber(this.state.totalQty),
                fnumber(this.state.totalAdmin),
                fnumber(this.state.totalBayar),
                "-",
                "-",
              ]}
              customCellClasses={[
                classes.textCenter,
                classes.textRight,
                classes.textRight,
              ]}
              customClassesForCells={[0, 3, 4]}
              customHeadCellClasses={[
                classes.textCenter,
                classes.textRight,
                classes.textRight,
              ]}
              customHeadClassesForCells={[0, 3, 4]}
              customFootCellClasses={[
                classes.textCenter,
                classes.textRight,
                classes.textRight,
              ]}
              customFootClassesForCells={[0, 3, 4]}
            />
          </GridItem>
          <GridItem
            xs={4}
            sm={4}
            md={4}
            className={classes.grid}
            style={{ marginTop: 20 }}
          >
            <Button
              round
              color="instagram"
              disabled={this.state.btnInquiryDisabled}
              onClick={(e) => {
                if (
                  this.state.collections.length > 0 &&
                  this.state.adminCollective
                )
                  this.handleInquiry();
              }}
            >
              cek tagihan
            </Button>
          </GridItem>
          <GridItem
            xs={4}
            sm={4}
            md={4}
            className={classes.grid}
            style={{ marginTop: 20 }}
          >
            <Button
              round
              color="instagram" //disabled={this.state.btnInquiryDisabled}
              onClick={(e) => {
                if (this.state.collections.length > 0) {
                  const data = this.state.collections.map((item, index) => {
                    const sale =
                      item.BatchSalesDts && item.BatchSalesDts[0].TrSale;
                    // const receiptBody =
                    // 	sale &&
                    // 	[1, 2, 4].includes(sale.statusId) &&
                    // 	JSON.parse(sale.receiptBody);
                    const receiptBody =
                      sale && [1, 2, 4].includes(sale.statusId)
                        ? typeof sale.receiptBody === "object"
                          ? sale.receiptBody
                          : JSON.parse(sale.receiptBody)
                        : null;
                    const name = item.customerName
                      ? item.customerName
                      : receiptBody && [1, 4].includes(sale.statusId)
                      ? Array.isArray(receiptBody)
                        ? receiptBody[1][1]
                        : receiptBody.name.value
                      : "-";
                    let remark = "-";
                    if (receiptBody) {
                      if ([2].includes(sale.statusId))
                        remark = Array.isArray(receiptBody)
                          ? receiptBody[0][0]
                          : receiptBody.message;
                    }
                    return {
                      no: fnumber(index + 1),
                      customerCode: item.customerCode,
                      name,
                      check: this.state.check[index] ? "YA" : "TIDAK",
                      amount:
                        sale && sale.statusId !== 2
                          ? fnumber(sale.amount)
                          : "0",
                      qty:
                        sale && sale.statusId !== 2 && sale.qty
                          ? fnumber(sale.qty)
                          : "0",
                      admin:
                        sale && sale.statusId !== 2
                          ? fnumber(
                              item.BatchSalesDts[0].adminNominal * sale.qty
                            )
                          : "0",
                      bayar:
                        sale && sale.statusId !== 2
                          ? fnumber(
                              parseInt(sale.amount) +
                                item.BatchSalesDts[0].adminNominal * sale.qty
                            )
                          : "0",
                      remark,
                    };
                  });
                  const header = [
                    "#",
                    "IDPEL",
                    "NAMA",
                    "PROSES",
                    "TAGIHAN",
                    "QTY",
                    "ADMIN",
                    "BAYAR",
                    "KETERANGAN",
                  ];
                  const footer = [
                    "-",
                    "-",
                    "-",
                    "TOTAL",
                    fnumber(this.state.totalTagihan),
                    fnumber(this.state.totalQty),
                    fnumber(this.state.totalAdmin),
                    fnumber(this.state.totalBayar),
                    "-",
                  ];
                  const align = {
                    index: [3, 4, 5, 6, 7],
                    align: ["center", "right", "right", "right", "right"],
                  };
                  // const totalTagihan = fnumber(this.state.totalTagihan)
                  // const totalQty = fnumber(this.state.totalQty)
                  // const totalAdmin = fnumber(this.state.totalAdmin)
                  // const totalBayar = fnumber(this.state.totalBayar)
                  const title =
                    "DAFTAR TAGIHAN LISTRIK " + this.state.collectiveName;
                  const filename = `${title.replace(
                    /\s/g,
                    "_"
                  )}_${moment().format("YYMMDDHHmm")}`;
                  const printListPayload = {
                    title,
                    data,
                    header,
                    filename,
                    footer,
                    align,
                  };
                  console.log({ printListPayload });
                  // this.printListRef.current.setData(printListPayload)
                  this.setState({ printListModal: true, printListPayload });
                }
              }}
            >
              <Print />
              print list
            </Button>
            <Modal
              size={"mini"}
              open={this.state.printListModal}
              onClose={() => this.setState({ printListModal: false })}
            >
              <Modal.Header>
                <Print />
                PRINT LIST
              </Modal.Header>
              <Modal.Content>
                <p>Pilih printer</p>
                {this.state.printers.map((item, index) => (
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                    key={index}
                  >
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      label={item.label}
                      style={{ color: "black" }}
                      control={
                        <Radio
                          checked={this.state.selectedPrinter === item.name}
                          value={item.name}
                          name="printer"
                          aria-label="A"
                          // disabled={item.name==='dotmatrix' ? this.state.dotmatrix==="online"?false:true:false}
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                          onChange={(e) =>
                            this.setState({ selectedPrinter: e.target.value })
                          }
                        />
                      }
                    />
                  </div>
                ))}
              </Modal.Content>
              <Modal.Actions>
                <Button
                  round
                  color="google"
                  onClick={() => this.setState({ printListModal: false })}
                >
                  <DeleteForeverIcon />
                  Batal
                </Button>
                <Button
                  round
                  color="instagram"
                  disabled={!this.state.printListPayload ? true : false}
                  onClick={async () => {
                    if (
                      this.state.collections.length > 0 &&
                      this.state.printListPayload
                    ) {
                      const selectedPrinter = this.state.selectedPrinter;
                      // console.log({ selectedPrinter , printList: this.state.printListPayload});
                      switch (selectedPrinter) {
                        case "pdf":
                          // this.toPdf()
                          const { savePdf } = require("libs/pdf");
                          savePdf(this.state.printListPayload, "PdfPrintList");
                          break;
                        case "deskjet":
                          const { getUrl } = require("libs/pdf");
                          print.list({
                            url: await getUrl(
                              this.state.printListPayload,
                              "PdfPrintList"
                            ),
                            ...this.state.printListPayload,
                            printer: selectedPrinter,
                          });
                          break;
                        default:
                          const {
                            data,
                            header,
                            footer,
                          } = this.state.printListPayload;
                          header.pop();
                          footer.pop();
                          print.list({
                            ...this.state.printListPayload,
                            data: data.map(
                              (item) => delete item.remark && item
                            ),
                            header,
                            footer,
                            printer: selectedPrinter,
                          });
                      }
                      this.setState({
                        printListPayload: "",
                        printListModal: false,
                      });
                    }
                  }}
                >
                  <Print />
                  print
                </Button>
              </Modal.Actions>
            </Modal>
          </GridItem>
          <GridItem
            xs={4}
            sm={4}
            md={4}
            className={classes.grid}
            style={{ marginTop: 20 }}
          >
            <Button
              round
              color="instagram"
              disabled={this.state.btnPurchaseDisabled}
              onClick={() => this.setState({ purchaseModal: true })}
            >
              bayar
            </Button>
            <Modal
              size={"mini"}
              open={this.state.purchaseModal}
              onClose={() => this.setState({ purchaseModal: false })}
            >
              <Modal.Header>
                <PaymentIcon />
                Bayar
              </Modal.Header>
              <Modal.Content>
                <p>Apakah anda yakin ingin melakukan proses pembayaran ?</p>
              </Modal.Content>
              <Modal.Actions>
                <Button
                  round
                  color="google"
                  onClick={() => this.setState({ purchaseModal: false })}
                >
                  <DeleteForeverIcon />
                  Batal
                </Button>
                <Button round color="instagram" onClick={this.handlePurchase}>
                  <PaymentIcon />
                  Bayar
                </Button>
              </Modal.Actions>
            </Modal>
          </GridItem>
          <GridItem
            xs={6}
            sm={6}
            md={6}
            className={classes.grid}
            style={{ marginTop: 20 }}
          >
            <Button
              round
              color="instagram"
              onClick={() => this.setState({ printSettings: true })}
            >
              <SettingsIcon />
              print settings
            </Button>
            <Modal
              size={"mini"}
              open={this.state.printSettings}
              onClose={() => this.setState({ printSettings: false })}
            >
              <Modal.Header>
                <SettingsIcon />
                Print Settings
              </Modal.Header>
              <Modal.Content>
                <Input
                  labelText="Lembar #"
                  id="sheetNum"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    placeholder: "Jumlah Lembar",
                    value: this.state.sheetNum,
                    type: "number",
                    onChange: (e) => {
                      this.setState({ sheetNum: e.target.value });
                    },
                  }}
                />
                <Input
                  labelText="Feed #"
                  id="feedNum"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    placeholder: "Jumlah Feed",
                    value: this.state.feedNum,
                    type: "number",
                    onChange: (e) => {
                      this.setState({ feedNum: e.target.value });
                    },
                  }}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  round
                  color="google"
                  onClick={() => this.setState({ printSettings: false })}
                >
                  Cancel
                </Button>
                <Button
                  round
                  color="instagram"
                  onClick={() => {
                    this.setState({ printSettings: false });
                    secureStorage.setItem("printSettings", {
                      feedNum: this.state.feedNum,
                      sheetNum: this.state.sheetNum,
                    });
                  }}
                >
                  <SaveIcon />
                  Save
                </Button>
              </Modal.Actions>
            </Modal>
          </GridItem>
          <GridItem
            xs={6}
            sm={6}
            md={6}
            className={classes.grid}
            style={{ marginTop: 20 }}
          >
            <Button
              round
              color="instagram"
              disabled={this.state.btnPrintDisabled}
              onClick={this.handlePrintDialog}
            >
              <Print />
              print struk
            </Button>
            <Modal
              size={"mini"}
              open={this.state.printOpen}
              onClose={() => this.setState({ printOpen: false })}
            >
              <Modal.Header>
                <Print />
                Print
              </Modal.Header>
              <Modal.Content>
                <Input
                  labelText="Dari #"
                  id="startNum"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    placeholder: "Dari Nomor",
                    value: this.state.startNum,
                    type: "number",
                    onChange: (e) => {
                      let val = parseInt(e.target.value);
                      if (val < 1) val = 1;
                      else if (val > this.state.endNum) val = this.state.endNum;
                      this.setState({ startNum: val });
                    },
                  }}
                />
                <Input
                  labelText="Sampai #"
                  id="endNum"
                  formControlProps={{ fullWidth: true }}
                  inputProps={{
                    placeholder: "Sampai Nomor",
                    value: this.state.endNum,
                    type: "number",
                    onChange: (e) => {
                      let val = parseInt(e.target.value);
                      if (val > this.state.collections.length)
                        val = this.state.collections.length;
                      else if (val < this.state.startNum)
                        val = this.state.startNum;
                      this.setState({ endNum: val });
                    },
                  }}
                />
              </Modal.Content>
              <Modal.Actions>
                <Button
                  round
                  color="google"
                  onClick={() => this.setState({ printOpen: false })}
                >
                  Cancel
                </Button>
                <Button round color="instagram" onClick={this.handlePrint}>
                  <Print />
                  Print
                </Button>
              </Modal.Actions>
            </Modal>
          </GridItem>
          <GridItem xs={12} sm={12} md={8} className={classes.grid}>
            <TagsInput
              value={this.state.tags}
              onChange={this.handleTags}
              tagProps={{ className: "react-tagsinput-tag danger" }}
              inputProps={{
                placeholder: "IDPEL Tekan Enter atau Copy/Paste",
              }}
              renderInput={({ addTag, ...props }) => {
                let { onChange, value, ...other } = props;
                return (
                  <Input
                    formControlProps={{
                      fullWidth: true,
                    }}
                    inputProps={{
                      //placeholder : 'Masukkan ide',
                      value: value,
                      //value : this.state.idPelPre,
                      onChange: onChange,
                      //onChange : this.handleChange,
                      type: "tel",
                      ...other,
                    }}
                  />
                );
              }}
              pasteSplit={(data) => {
                const separators = [
                  ",",
                  ";",
                  "\\(",
                  "\\)",
                  "\\*",
                  "/",
                  ":",
                  "\\?",
                  "\n",
                  "\r",
                ];
                return [
                  ...new Set(
                    data
                      .split(new RegExp(separators.join("|")))
                      .map((d) => d.trim())
                      .filter(
                        (d) =>
                          d !== null &&
                          !isNaN(d) &&
                          d.toString().length >= 11 &&
                          d.toString().length <= 12
                      )
                  ),
                ];
              }}
              addOnPaste
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={8} className={classes.grid}>
            <Button
              size="sm"
              round
              color="instagram"
              disabled={this.state.btnSaveDisabled}
              onClick={this.handleSaveTags}
            >
              <SaveIcon />
              simpan
            </Button>
          </GridItem>
          <GridItem xs={12} sm={12} md={8} className={classes.grid}>
            <FileInput
              fileSaved={(ref) => (this.filSaved = ref)}
              onChange={(inputFiles, inputFileNames) => {
                const ext = inputFileNames.split(".")[1];
                if (
                  this.state.collections.length === 0 ||
                  (this.state.collections.length > 0 &&
                    !this.state.collections[0].BatchSalesDts)
                ) {
                  if (["xlsx", "xls"].indexOf(ext) + 1) {
                    //console.log(ext)
                    readXlsx(inputFiles[0]).then((rows) => {
                      const arr = [
                        ...new Set(
                          [].concat
                            .apply([], rows)
                            .filter(
                              (d) =>
                                d !== null &&
                                !isNaN(d) &&
                                d.toString().length >= 11 &&
                                d.toString().length <= 12
                            )
                        ),
                      ];
                      this.setState({
                        btnSaveFileDisabled: false,
                        fileCodes: arr,
                      });
                    });
                  } else if (ext === "txt") {
                    const fileReader = new FileReader();
                    fileReader.onloadend = (f) => {
                      const content = fileReader.result;
                      const arr = [
                        ...new Set(
                          content
                            .split(
                              new RegExp(
                                [
                                  ",",
                                  ";",
                                  "\\(",
                                  "\\)",
                                  "\\*",
                                  "/",
                                  ":",
                                  "\\?",
                                  "\n",
                                  "\r",
                                ].join("|")
                              )
                            )
                            .map((d) => d.trim())
                            .filter(
                              (d) =>
                                d !== "" &&
                                !isNaN(d) &&
                                d.toString().length >= 11 &&
                                d.toString().length <= 12
                            )
                        ),
                      ];
                      this.setState({
                        btnSaveFileDisabled: false,
                        fileCodes: arr,
                      });
                    };
                    fileReader.readAsText(inputFiles[0]);
                  }
                }
              }}
              formControlProps={{
                fullWidth: true,
              }}
              inputProps={{
                placeholder: "Salin dari File...",
              }}
              endButton={{
                buttonProps: {
                  round: true,
                  color: "instagram",
                  justIcon: true,
                  fileButton: true,
                },
                icon: <AttachFile />,
              }}
            />
          </GridItem>
          <GridItem xs={12} sm={12} md={8} className={classes.grid}>
            <Button
              size="sm"
              round
              color="instagram"
              disabled={this.state.btnSaveFileDisabled}
              onClick={this.handleSaveTags}
            >
              <SaveIcon />
              simpan
            </Button>
          </GridItem>
        </GridContainer>
        <Dialog
          classes={{
            root: classes.modalRoot,
            paper: classes.modal,
          }}
          open={this.state.modalShow}
          TransitionComponent={Transition}
          keepMounted
          //onClose={() => this.setState({successModal: false})}
          aria-labelledby="classic-modal-slide-title"
          aria-describedby="classic-modal-slide-description"
        >
          <DialogTitle
            id="classic-modal-slide-title"
            disableTypography
            className={classes.modalHeader}
          >
            <h4 className={classes.modalTitle}>{this.state.modalTitle}</h4>
          </DialogTitle>
          <DialogContent
            id="classic-modal-slide-description"
            className={classes.modalBody}
          >
            {this.state.modalBody}
          </DialogContent>

          <DialogActions className={classes.modalFooter}>
            {this.state.modalTitle === "Hapus" ? (
              <Button
                round
                onClick={() => this.handleModal("deleteCollective")}
                color="success"
              >
                <DeleteForeverIcon />
                Hapus
              </Button>
            ) : null}
            <Button
              round
              onClick={() => {
                this.setState({
                  modalShow: false,
                  modalIsreceipt: false,
                  btnSavedGroupDisabled: false,
                });
              }}
              color="danger"
            >
              Close
            </Button>
            {this.state.modalIsreceipt ? (
              <Button
                round
                onClick={() => this.handleModal("print")}
                color="instagram"
              >
                <Print />
                Print
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}
Collective.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Collective);
