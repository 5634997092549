import moment from "moment";
import _ from "lodash";

function align(item, index, l) {
  switch (index) {
    case 0:
      return item.padStart(3, l ? item : " ");
    case 1:
      return item.padEnd(12, l ? item : " ");
    case 2:
      if (item.length > 17) item = item.substr(0, 17);
      return item.padEnd(17, l ? item : " ");
    case 3:
      return item.padEnd(6, l ? item : " ");
    case 4:
      return item.padStart(11, l ? item : " ");
    case 5:
      return item.padStart(4, l ? item : " ");
    case 6:
      return item.padStart(7, l ? item : " ");
    case 7:
      return item.padStart(11, l ? item : " ");
    // case 8:
    //   if(item.length>8)item = item.substr(0,8)
    //   return item.padEnd(8,l?item:' ')
    default:
  }
}

const printList = (payload) => {
  const { header, footer, data, title } = payload;
  const date =
    "Tanggal cetak : " +
    moment()
      .format("dddd, DD MMMM YYYY HH:mm")
      .toUpperCase();
  const t = "_" + _.times(8, (n) => align("_", n, true)).join("_") + "_";
  const h =
    "|" + header.map((item, index) => align(item, index)).join("|") + "|";
  const l = "|" + _.times(8, (n) => align("_", n, true)).join("|") + "|";
  const r = data.map(
    (item) =>
      "|" +
      Object.keys(item)
        .map((key, index) => align(item[key], index))
        .join("|") +
      "|"
  );
  const f =
    "|" + footer.map((item, index) => align(item, index)).join("|") + "|";
  const rows = [
    String.fromCharCode(27) +
      "2" +
      String.fromCharCode(27) +
      "E" +
      title.toUpperCase() +
      String.fromCharCode(27) +
      "F",
    date,
    t,
    h,
    l,
  ]
    .concat(r)
    .concat([l])
    .concat([f])
    .concat([
      l +
        String.fromCharCode(10) +
        String.fromCharCode(10) +
        String.fromCharCode(10) +
        String.fromCharCode(10) +
        String.fromCharCode(10) +
        String.fromCharCode(13),
    ]);
  const combined = rows.join("\n");
  console.log(combined);
  return rows.map((item) => item + "\r\n").join("");
};

export default printList;
