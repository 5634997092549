import React from 'react'
import { createBrowserHistory } from 'history'
import {
  Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import Login from 'views/Login'
import Confirmation from 'views/Login/Confirmation'
import Verification from 'views/Login/Verification'
import ResetPassword from 'views/Login/ResetPassword'
import Main from 'views/Main'
import Register from 'views/Register'

import axios from 'axios'
// import Config from 'config'

import * as serviceWorker from 'serviceWorker'
import { withSnackbar } from 'notistack'
import { Button } from '@material-ui/core'
import secureStorage from 'libs/secureStorage'
import { wsClient } from 'helper/dotmatrix'

class App extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      history: '',
      newVersionAvailable: false,
      waitingWorker: {},
    }
  }

  componentDidMount() {
    if (secureStorage.getItem('token'))
      this.setState({ token: secureStorage.getItem('token') })

    if (navigator.userAgent.toLocaleLowerCase().indexOf('electron/') !== -1) {
      wsClient.on('update', this.onAppUpdate)
      // wsClient.send('restart',{data:null})
    }
  }
  UNSAFE_componentWillMount() {
    this.setState({ history: createBrowserHistory() })
    this.setRequestHeader()
    this.sessionCheck()
    this.loadSettings()

    serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate })
    // serviceWorker.register()
  }
  setRequestHeader() {
    //console.log(secureStorage.getItem('token'))
    const token = secureStorage.getItem('token')
    if (token) {
      axios.defaults.headers.Authorization = 'Bearer ' + token
      axios.defaults.headers.post['Content-Type'] =
        'application/json;charset=utf-8'
      //axios.defaults.withCredentials = true
    }
  }
  sessionCheck() {
    axios.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        if (error.response && error.response.data === 'Forbidden Access') {
          this.logout()
        }
        return Promise.reject(error)
      },
    )
  }
  loadSettings() {
    if (!secureStorage.getItem('printer'))
      secureStorage.setItem('printer', 'thermal')
    this.initDotmatrix()
  }
  initDotmatrix = () => {
    // console.log('test')
    // if (navigator.userAgent.toLocaleLowerCase().indexOf("electron/") === -1) {
    //   secureStorage.setItem("dotmatrix", "offline");
    // } else {
    axios
      .post('http://localhost:4321/check')
      .then((res) => {
        console.log(res.data)
        secureStorage.setItem('dotmatrix', res.data)
      })
      .catch((err) => {
        console.log(err)
        secureStorage.setItem('dotmatrix', 'offline')
      })
    // }
  }
  logout() {
    secureStorage.removeItem('token')
    secureStorage.removeItem("account")
    window.location.reload()
  }

  onAppUpdate = () => {
    const { enqueueSnackbar } = this.props
    enqueueSnackbar('Aplikasi dekstop versi terbaru telah tersedia', {
      persist: true,
      variant: 'success',
      action: this.restartAction(),
    })
    // wsClient.send('restart',{data:null})
  }

  onServiceWorkerUpdate = (waitingWorker) => {
    // console.log("update available")
    const { enqueueSnackbar } = this.props
    // const { newVersionAvailable } = this.state;

    // const waitingWorker = waiting
    this.setState({
      waitingWorker,
      newVersionAvailable: true,
    })
    // console.log({waitingWorker,registration})
    //if (newVersionAvailable){ //show snackbar with refresh button
    enqueueSnackbar('Versi terbaru telah tersedia', {
      persist: true,
      variant: 'success',
      action: this.refreshAction(),
    })
    //}
  }

  updateServiceWorker = () => {
    const { waitingWorker } = this.state
    // console.log({waitingWorker})
    // // waitingWorker && waitingWorker.postMessage("Install new worker");
    waitingWorker && waitingWorker.postMessage('forceUpdate')
    // this.setState({ newVersionAvailable: false });
    // window.location.reload()
  }

  refreshAction = (key) => {
    //render the snackbar button
    return (
      <React.Fragment>
        <Button
          className='snackbar-button'
          size='small'
          onClick={this.updateServiceWorker}>
          {'update'}
        </Button>
      </React.Fragment>
    )
  }
  updateApp = () => {
    wsClient.send('restart', { data: null })
  }
  restartAction = (key) => {
    //render the snackbar button
    return (
      <React.Fragment>
        <Button
          className='snackbar-button'
          size='small'
          onClick={this.updateApp}>
          {'restart'}
        </Button>
      </React.Fragment>
    )
  }
  //reload = () => window.location.reload()
  render() {
    return (
      <Router history={this.state.history}>
        <Switch>
          {/*<Route path="/.well-known/pki-validation/1B96329962003AB77158469D845557EC.txt" onEnter={this.reload} />*/}
          <Route path='/login' component={Login} />
          <Route path='/confirm/:confirmCode' component={Confirmation} />
          <Route path='/verification' component={Verification} />
          <Route path='/reg/:invitationId' component={Register} />
          <Route path='/reset/:confirmCode' component={ResetPassword} />
          <PrivateRoute path='/' component={Main} />
        </Switch>
      </Router>
    )
  }
}
function PrivateRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) =>
        secureStorage.getItem('token') ? (
          <Component {...props} />
        ) : (
          <Redirect to='/login' />
        )
      }
    />
  )
}
export default withSnackbar(App)
