import React from "react";
// nodejs library that concatenates classes
// import classNames from "classnames";
// @material-ui/core components
import { withStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Button from "components/CustomButtons/Button.js";
// import Badge from "components/Badge/Badge.js";

// @material-ui/icons
import PrintIcon from "@material-ui/icons/Print";

import checkboxStyles from "assets/jss/material-kit-pro-react/gCustomCheckboxRadioSwitchStyle.js";
import settingsStyles from "assets/jss/material-kit-pro-react/views/gSettingsStyle.js";

// core components
import axios from "axios";
import Config from "config";

import Radio from "@material-ui/core/Radio";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FiberManualRecord from "@material-ui/icons/FiberManualRecord";
// Custom select
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import CircularProgress from "@material-ui/core/CircularProgress";

import print from "helper/printer";
import ComponentToPrint from "helper/ComponentToPrint";

import modalReceipt from "helper/modalReceipt";
import secureStorage from "libs/secureStorage";

import { wsClient } from "helper/dotmatrix";
import _ from "lodash";
import Badge from "components/Badge/Badge";
import { Refresh } from "@material-ui/icons";

import moment from "moment";

const styles = {
  ...settingsStyles,
  ...checkboxStyles,
};

class Printer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedEnabled: "thermal",
      printers: [],
      selectedPrinter: "",
      dotmatrix: "offline",
      bluetooth: false,
      selectedPrintFormat: "",
    };
    this.printRef = React.createRef();
  }

  componentDidMount() {
    this.loadPrinter();
    this.setPrinters();
    // const selectedPrintFormat = secureStorage.getItem('printFormat')
    // console.log({selectedPrintFormat})
    // this.setState({selectedPrintFormat})
  }
  setPrinters = () => {
    axios
      .post("http://localhost:4321/get-printers")
      .then((res) => {
        // console.log(res.data);
        const selectedPrinter = secureStorage.getItem("selectedPrinter");
        this.setState({
          printers: res.data.printers,
          selectedPrinter,
        });
      })
      .catch((err) => {
        console.log(err);
      });
    // wsClient.send("get-printer", {}, (data) => {
    //   this.setState({ printers: data }, () => {
    //     const selectedPrinter = secureStorage.getItem("selectedPrinter");

    //     selectedPrinter &&
    //       this.setState({
    //         selectedPrinter: _.find(
    //           this.state.printers,
    //           ({ name }) => name === selectedPrinter.name
    //         ),
    //       });
    //   });
    // });
  };
  loadPrinter = async () => {
    // if(navigator.userAgent.toLocaleLowerCase().indexOf('electron/')!==-1){
    //   if(!wsClient.connected)wsClient.on('connected',()=>this.setPrinters())
    //    else this.setPrinters()
    // }
    const bluetooth =
      navigator.bluetooth && (await navigator.bluetooth.getAvailability());
    const selectedPrintFormat = secureStorage.getItem("printFormat");
    let selectedEnabled = secureStorage.getItem("printer");
    const dotmatrix = secureStorage.getItem("dotmatrix");
    if (!selectedEnabled) {
      if (dotmatrix === "online") selectedEnabled = "dotmatrix";
      else if (bluetooth) selectedEnabled = "thermal";
      else {
        selectedEnabled = "deskjet";
      }
      secureStorage.setItem("printer", selectedEnabled);
    }

    // console.log({bluetooth,selectedEnabled,dotmatrix})
    this.setState({
      bluetooth,
      selectedEnabled,
      dotmatrix,
      selectedPrintFormat,
    });

    // console.log({selectedEnabled,dotmatrix})
  };
  handleChangeRadio = (e) => {
    secureStorage.setItem("printer", e.target.value);
    this.setState({ selectedEnabled: e.target.value });
  };

  loadDownloadLink = (e) => {
    axios
      .post(Config.backendUrl + "user/download")
      .then((res) => {
        window.open(res.data);
      })
      .catch((error) => {});
  };

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.section}>
        <GridContainer>
          {this.state.bluetooth && (
            <GridItem
              xs={12}
              sm={12}
              md={8}
              className={classes.grid}
              style={{ textAlign: "left" }}
            >
              <div
                className={
                  classes.checkboxAndRadio +
                  " " +
                  classes.checkboxAndRadioHorizontal
                }
              >
                <FormControlLabel
                  control={
                    <Radio
                      checked={this.state.selectedEnabled === "thermal"}
                      onChange={this.handleChangeRadio}
                      value="thermal"
                      name="printer"
                      aria-label="A"
                      icon={
                        <FiberManualRecord className={classes.radioUnchecked} />
                      }
                      checkedIcon={
                        <FiberManualRecord className={classes.radioChecked} />
                      }
                      classes={{
                        checked: classes.radio,
                        root: classes.radioRoot,
                      }}
                    />
                  }
                  classes={{
                    label: classes.label,
                  }}
                  label="THERMAL"
                />
              </div>
            </GridItem>
          )}
          {this.state.bluetooth && (
            <GridItem
              xs={12}
              sm={12}
              md={8}
              className={classes.grid}
              style={{ textAlign: "left" }}
            >
              <Button
                round
                color="facebook"
                size="sm"
                onClick={(e) => {
                  print({ title: "TEST PRINT", body: TestPrintBody });
                }}
              >
                <PrintIcon />
                test print
              </Button>
            </GridItem>
          )}

          <GridItem
            xs={12}
            sm={12}
            md={8}
            className={classes.grid}
            style={{ textAlign: "left" }}
          >
            <GridContainer>
              <GridItem xs={12} sm={6} md={6}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={
                          this.state.dotmatrix === "online" ? false : true
                        }
                        checked={this.state.selectedEnabled === "dotmatrix"}
                        onChange={this.handleChangeRadio}
                        value="dotmatrix"
                        name="printer"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    style={{ color: "#000" }}
                    label="DOT MATRIX"
                  />
                </div>
                <span>
                  <Badge
                    color={
                      this.state.dotmatrix === "online" ? "success" : "danger"
                    }
                  >
                    {this.state.dotmatrix === "online" ? "online" : "offline"}
                  </Badge>
                  <Button
                    color="instagram"
                    justIcon
                    round
                    size="sm"
                    onClick={() => {
                      axios
                        .post("http://localhost:4321/check")
                        .then((res) => {
                          const dotmatrix = res.data;
                          secureStorage.setItem("dotmatrix", dotmatrix);
                          this.setState({ dotmatrix });
                        })
                        .catch((err) => {
                          console.log(err);
                          secureStorage.setItem("dotmatrix", "offline");
                          this.setState({ dotmatrix: "offline" });
                        });
                    }}
                  >
                    <Refresh />
                  </Button>
                </span>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <div
                  className={
                    classes.checkboxAndRadio +
                    " " +
                    classes.checkboxAndRadioHorizontal
                  }
                >
                  <FormControlLabel
                    control={
                      <Radio
                        disabled={
                          this.state.dotmatrix === "online" ? false : true
                        }
                        checked={this.state.selectedEnabled === "pos"}
                        onChange={this.handleChangeRadio}
                        value="pos"
                        name="printer"
                        aria-label="B"
                        icon={
                          <FiberManualRecord
                            className={classes.radioUnchecked}
                          />
                        }
                        checkedIcon={
                          <FiberManualRecord className={classes.radioChecked} />
                        }
                        classes={{
                          checked: classes.radio,
                          root: classes.radioRoot,
                        }}
                      />
                    }
                    classes={{
                      label: classes.label,
                    }}
                    style={{ color: "#000" }}
                    label="POS"
                  />
                </div>
              </GridItem>
              <GridItem xs={12} sm={6} md={6}>
                <FormControl fullWidth className={classes.selectFormControl}>
                  <InputLabel
                    htmlFor="printers-select"
                    className={classes.selectLabel}
                  >
                    Pilih Printer
                  </InputLabel>
                  <Select
                    MenuProps={{ className: classes.selectMenu }}
                    classes={{ select: classes.select }}
                    inputProps={{ name: "printers", id: "printers" }}
                    value={
                      this.state.printers.length === 0
                        ? ""
                        : this.state.selectedPrinter
                    }
                    onChange={(e) => {
                      secureStorage.setItem("selectedPrinter", e.target.value);
                      // wsClient.send("set-printer", {
                      //   printer: e.target.value,
                      // });
                      this.setState({ selectedPrinter: e.target.value });
                    }}
                    // onMouseDown={()=>{
                    //   // console.log('test')

                    // }}
                    // onClick={}
                  >
                    <MenuItem
                      disabled
                      classes={{ root: classes.selectMenuItem }}
                      value={""}
                    >
                      {this.state.printers.length > 0 ? (
                        "Printers"
                      ) : (
                        <CircularProgress size={30} />
                      )}
                    </MenuItem>
                    {this.state.printers.map((printer, index) => (
                      <MenuItem
                        key={index}
                        classes={{
                          root: classes.selectMenuItem,
                          selected: classes.selectMenuItemSelected,
                        }}
                        value={printer}
                      >
                        {printer}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </GridItem>
            </GridContainer>
          </GridItem>
          <GridItem
            xs={12}
            sm={12}
            md={8}
            className={classes.grid}
            style={{ textAlign: "left" }}
          >
            <Button
              round
              color="facebook"
              size="sm"
              disabled={this.state.dotmatrix === "online" ? false : true}
              onClick={(e) => {
                print({
                  title: "TEST PRINT",
                  body: TestPrintBody,
                  productId: 14,
                });
                // console.log({title:"TEST PRINT",body:TestPrintBodyDot})
              }}
            >
              <PrintIcon />
              test print
            </Button>
          </GridItem>
          {navigator.userAgent.toLocaleLowerCase().indexOf("electron/") ===
            -1 && (
            <GridItem
              xs={12}
              sm={12}
              md={8}
              className={classes.grid}
              style={{ textAlign: "left" }}
            >
              <GridContainer>
                <GridItem xs={12} sm={6} md={6}>
                  <div
                    className={
                      classes.checkboxAndRadio +
                      " " +
                      classes.checkboxAndRadioHorizontal
                    }
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={this.state.selectedEnabled === "deskjet"}
                          onChange={this.handleChangeRadio}
                          value="deskjet"
                          name="printer"
                          aria-label="A"
                          icon={
                            <FiberManualRecord
                              className={classes.radioUnchecked}
                            />
                          }
                          checkedIcon={
                            <FiberManualRecord
                              className={classes.radioChecked}
                            />
                          }
                          classes={{
                            checked: classes.radio,
                            root: classes.radioRoot,
                          }}
                        />
                      }
                      classes={{
                        label: classes.label,
                      }}
                      label="DESKJET/INKJET/LASERJET"
                    />
                  </div>
                </GridItem>
                <GridItem xs={12} sm={6} md={6}>
                  <FormControl fullWidth className={classes.selectFormControl}>
                    <InputLabel
                      htmlFor="format-select"
                      className={classes.selectLabel}
                    >
                      Format
                    </InputLabel>
                    <Select
                      MenuProps={{ className: classes.selectMenu }}
                      classes={{ select: classes.select }}
                      inputProps={{ name: "format", id: "format" }}
                      value={this.state.selectedPrintFormat}
                      onChange={(e) => {
                        // console.log(e.target.value)
                        secureStorage.setItem("printFormat", e.target.value);
                        this.setState({ selectedPrintFormat: e.target.value });
                      }}
                    >
                      <MenuItem
                        disabled
                        classes={{ root: classes.selectMenuItem }}
                        value={""}
                      >
                        {"Format"}
                      </MenuItem>
                      {PrintFormats.map((format, index) => (
                        <MenuItem
                          key={index}
                          classes={{
                            root: classes.selectMenuItem,
                            selected: classes.selectMenuItemSelected,
                          }}
                          value={format.value}
                        >
                          {format.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </GridItem>
              </GridContainer>
            </GridItem>
          )}
          {navigator.userAgent.toLocaleLowerCase().indexOf("electron/") ===
            -1 && (
            <GridItem
              xs={12}
              sm={12}
              md={8}
              className={classes.grid}
              style={{ textAlign: "left" }}
            >
              <Button
                round
                color="facebook"
                size="sm"
                onClick={(e) => {
                  // console.log("[TEST DESKJET PRINTER]");
                  print({
                    title: "TEST PRINT",
                    body: TestPrintBody,
                    ref: this.printRef,
                    productId: 14,
                  });
                }}
              >
                <PrintIcon />
                test print
              </Button>
            </GridItem>
          )}
        </GridContainer>
        {secureStorage.getItem("printer") === "deskjet" && (
          <ComponentToPrint
            title={"TEST PRINT"}
            body={modalReceipt(TestPrintBody)}
            ref={this.printRef}
          />
        )}
      </div>
    );
  }
}
Printer.propTypes = {
  classes: PropTypes.object.isRequired,
};
export default withStyles(styles)(Printer);
const TestPrintBody = {
  date: {
    caption: "TANGGAL",
    value: moment().format("DD/MM/YYY HH:mm:ss"),
  },
  subscriberNo: {
    caption: "IDPEL",
    value: "588400000047",
  },
  name: {
    caption: "NAMA",
    value: "JUNG EUNBI",
  },
  segmentPower: {
    caption: "TARIF/DAYA",
    value: "R1/450",
  },
  referenceNo: {
    caption: "NO REF",
    value: "121R387D70E6F7042B1A01D08B9A3",
  },
  qty: {
    caption: "TOTAL TAG",
    value: "1",
  },
  period: {
    caption: "PERIODE",
    value: "OKT21",
  },
  standMeter: {
    caption: "STAND METER",
    value: "00003243 - 00003521",
  },
  amount: {
    caption: "RP TAG",
    value: 469889,
  },
  admin: {
    caption: "ADMIN BANK",
    value: 3000,
  },
  total: {
    caption: "TOTAL BAYAR",
    value: 472889,
  },
  info: {
    caption: "INFO",
    value: "Informasi Hubungi Call Center: 123 Atau Hub. PLN Terdekat",
  },
};
const PrintFormats = [
  { name: "1/1", value: "OnePage" },
  { name: "1/4 - 2 Columns", value: "TwoColumns" },
  { name: "1/4 - 3 Columns", value: "ThreeColumns" },
];
// const TestPrintBodyDot = [
// 	["IDPEL","123456789012"],
// 	["NAMA","PELANGGAN PLN"],
// 	["TRF/DAYA","R2/5500"],
// 	["BLN/TH","NOV20"],
// 	["METER","3243/3521"],
// 	["RP TAG",466139],
// 	["ADMIN",3000],
// 	["RP BAYAR",469139],
// 	["NO REF"],
// 	["047421CADB8885DE4F1F"],
// 	["3222D3563A3F"],
// 	[""],

// 	[""],
// 	[""],
// 	[""],
// 	//["~Informasi Hubungi Call Center 123 ~Atau Hub PLN Terdekat 123"],
// ]
